import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'

const useStyle = makeStyles((theme) => ({
  cell: {
    padding: '15px 0',
  },
  disabledMouseEvent: {
    pointerEvents: 'none',
  },
}))

function CorpTableHead({
  headRow,
  onSelectAllClick,
  order,
  orderBy,
  onRequestSort,
  checkBox,
  checkboxStatus,
}) {
  const createSortHeader = (property) => (event) => {
    if (!property) return
    onRequestSort(event, property)
  }
  const classes = useStyle()

  return (
    <TableHead>
      <TableRow className={classes.headRow}>
        {checkBox ? (
          <TableCell style={{ padding: '6px 40px 6px 0px' }}>
            <Checkbox
              style={{ zIndex: 0, padding: 0 }}
              checked={checkboxStatus}
              onChange={onSelectAllClick}
              color="default"
            />
          </TableCell>
        ) : null}
        {headRow.map((row, index) => (
          <TableCell
            key={index}
            align={row.numeric}
            sortDirection={orderBy === row.id ? order : false}
            className={classes.cell}
            padding="none"
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              className={!row.id && classes.disabledMouseEvent}
              onClick={createSortHeader(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

CorpTableHead.propTypes = {
  onRequestSort: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  headRow: PropTypes.array.isRequired,
  checkBox: PropTypes.bool.isRequired,
}

export default React.memo(CorpTableHead)
