import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import '../css/register.css'

const useStyles = makeStyles(theme => ({
    back: {},
}))

function RegisterSend() {
    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <React.Fragment>
            <div className="register">
                <div className="main-wrap">
                    <section className="wrap text-centered">
                        <div className="center-block">
                            <h2>已完成申請！</h2>
                            <h3>請至 <span className="highlight">ming@linetaxi.com.tw</span> 確認信件</h3>
                            <p>如未收到信件，可以聯絡我們 <a href="mailto:business@linego.me">business@linego.me</a>。</p>
                        </div>
                    </section>
                    <div className="bg-gradient"></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegisterSend
