import React, { useState, useContext, useEffect, useRef } from 'react'
import { CorpContext } from '../reducers/Main'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'
import EditNoteModal from '../view/Modal/EditNoteModal'
import {
  getCustomField,
  patchCustomField,
  deleteCustomField,
  putSortCustomField,
} from '../api'
import FlipMove from 'react-flip-move'

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  tripNoteSettinContainer: {
    padding: '0 80px 80px 80px',
  },
  pageHeader: {
    padding: '40px 0',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
    textAlign: 'start',
  },
  des: {
    color: '#4b4d4c',
    fontSize: '14px',
    marginBottom: '8px',
    letterSpacing: '1.2px',
  },
  settingSection: {
    // paddingBottom: '30px'
  },
  sectionTitle: {
    padding: '10px 0',
    lineHeight: '1.57',
    color: '#1e201f',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '16px',
  },
  noteWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    '&:hover': {
      '& > $noteItem': {
        border: 'solid 2px #797979',
      },
      '& > $noteItem > $controlButtonWrap > $controlButton': {
        visibility: 'visible',
      },
    },
  },
  noteItem: {
    padding: '12px 24px',
    backgroundColor: '#e8e8e8',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // cursor: 'pointer'
  },
  noteTitle: {
    fontSize: '14px',
    lineHeight: '1.57',
    color: '#1e201f',
    marginBottom: '2px',
  },
  noteType: {
    fontSize: '12px',
    lineHeight: '1.83',
    color: '#797979',
  },
  edit: {
    fontSize: '14px',
    lineHeight: '1.57',
    color: '#4b4d4c',
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
  },
  noteControl: {
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
    width: '140px',
    visibility: 'hidden',
  },
  controlButton: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    visibility: 'hidden',
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    color: '#4b4d4c',
    fontSize: '14px',
    lineHeight: '1.57',
    backgroundColor: '#ffffff',
    width: '65px',
    borderRadius: '15px',
    marginLeft: '16px',
  },
  controlButtonWrap: {
    display: 'flex',
  },
  controlIcon: {
    width: '16px',
    height: '16px',
    marginRight: '2px',
  },
  addControl: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    cursor: 'pointer',
  },
  addIcon: {
    marginRight: '6px',
    width: '16px',
    height: '16px',
  },
  add: {
    fontSize: '12px',
    lineHeight: '1.5',
    color: '#00cba0',
  },
  divider: {
    margin: '40px 0',
  },
  controlButtonDisabled: {
    pointerEvents: 'none',
    '& > $buttonWrap > img': {
      opacity: 0.2,
    },
    '& > $buttonWrap > span': {
      opacity: 0.2,
    },
  },
  '@media (max-width:1200px)': {
    tripNoteSettinContainer: {
      width: '100%',
      padding: '0 32px 40px 32px',
    },
  },
}))

const SettingSection = ({
  active,
  title,
  handleOpenModal,
  custom_field,
  handleActiveNote,
  handleSortCustomField,
}) => {
  const classes = useStyle()

  const handleActiveControl = (item, index) => {
    const isFirst = index === 0
    const isLast = custom_field.length === index + 1
    if (active) {
      return (
        <>
          <div
            className={classes.controlButton}
            onClick={() => handleActiveNote(0, item.id)}
          >
            <div className={classes.buttonWrap}>
              <img
                className={classes.controlIcon}
                src="https://static.taxigo-cdn.com/icons/business/ic-s-blocked.svg"
              />
              <div>停用</div>
            </div>
          </div>
          <div
            className={`${classes.controlButton} ${
              isFirst && classes.controlButtonDisabled
            }`}
            onClick={() => handleSortCustomField(item.id, 'up')}
          >
            <div className={classes.buttonWrap}>
              <img
                className={classes.controlIcon}
                src="https://static.taxigo-cdn.com/icons/business/arrow-up.svg"
              />
              <span className={isFirst && classes.controlButtonDisabled}>
                上移
              </span>
            </div>
          </div>
          <div
            className={`${classes.controlButton} ${
              isLast && classes.controlButtonDisabled
            }`}
            onClick={() => handleSortCustomField(item.id, 'down')}
          >
            <div className={classes.buttonWrap}>
              <img
                className={classes.controlIcon}
                src="https://static.taxigo-cdn.com/icons/business/arrow-down.svg"
              />
              <span>下移</span>
            </div>
          </div>
        </>
      )
    }
    return (
      <div
        className={classes.controlButton}
        onClick={() => handleActiveNote(1, item.id)}
      >
        <div className={classes.buttonWrap}>
          <img
            className={classes.controlIcon}
            src="https://static.taxigo-cdn.com/icons/business/ic-m-check.svg"
          />
          <div>啟用</div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.settingSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <FlipMove>
        {custom_field.map((item, index) => {
          return (
            <div key={item.id} className={classes.noteWrap}>
              <div className={classes.noteItem}>
                <div>
                  <div className={classes.noteTitle}>{item.title}</div>
                  <div className={classes.noteType}>
                    ・{item.type === 'text' ? '自行輸入文字' : '下拉式選單'}
                  </div>
                </div>
                <div className={classes.controlButtonWrap}>
                  <div
                    className={classes.controlButton}
                    onClick={() => handleOpenModal('view', item)}
                  >
                    <div className={classes.buttonWrap}>
                      <img
                        className={classes.controlIcon}
                        src="https://static.taxigo-cdn.com/icons/business/ic-m-edit.svg"
                      />
                      <div>編輯</div>
                    </div>
                  </div>
                  <div
                    className={classes.controlButton}
                    onClick={() => handleOpenModal('copy', item)}
                  >
                    <div className={classes.buttonWrap}>
                      <img
                        className={classes.controlIcon}
                        src="https://static.taxigo-cdn.com/icons/business/ic-m-copy.svg"
                      />
                      <div>複製</div>
                    </div>
                  </div>
                  {handleActiveControl(item, index)}
                </div>
              </div>
            </div>
          )
        })}
      </FlipMove>
    </div>
  )
}

function TripNoteSetting() {
  const classes = useStyle()
  const { state, dispatch } = useContext(CorpContext)
  const [open, setOpen] = useState(false)
  const [customField, setCustomField] = useState([])
  const [blockCustomField, setBlockCustomField] = useState([])
  const [editRow, setEditRow] = useState({})
  const [modalType, setModalType] = useState(undefined)
  const company = state.company[0]

  useEffect(() => {
    handleFilterField(state.custom_field)
    return () => {}
  }, [state.custom_field])

  function handleOpenModal(type, item = {}) {
    setModalType(type)
    if (type === 'new') {
      return setOpen((prevOpen) => !prevOpen)
    }
    setOpen((prevOpen) => !prevOpen)
    setEditRow(item)
  }

  function handleEditRow(item) {
    setEditRow(item)
  }

  async function handleGetCustomField() {
    const data = await getCustomField(company.id)
    if (data.status === 200) {
      dispatch({ type: 'UPDATE_CUSTOM_FIELD', custom_field: data.data })
    }
  }

  async function handleSortCustomField(id, move) {
    const data = await putSortCustomField(id, move, { company: company.id })
    if (data.status !== 200) return handleAlertBar('更新失敗！請稍後再試', 'error')
    handleGetCustomField()
  }

  async function handleDeleteField(id, callback) {
    const body = {
      company: company.id,
      fieldId: id,
    }
    const data = await deleteCustomField(body)
    if (data.status !== 200)
      return handleAlertBar('刪除失敗！請稍後再試', 'error')
    handleFilterField(data.data)
    callback && callback()
  }

  async function handleActiveNote(active, id) {
    const body = {
      company: company.id,
      updatingFields: [
        {
          active,
          id,
        },
      ],
    }
    handlePatchField(body)
  }

  async function handlePatchField(body, callback) {
    const data = await patchCustomField(body)
    if (data.status !== 200)
      return handleAlertBar('更新失敗！請稍後再試', 'error')
    handleAlertBar('更新成功')
    handleGetCustomField()
    callback && callback()
  }

  function handleFilterField(data = []) {
    const activeField = data.filter((item) => item.active === 1)
    const blockField = data.filter(
      (item) => item.active === 0 && item.deleted === 0
    )
    setCustomField(activeField)
    setBlockCustomField(blockField)
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  return (
    <>
      <div className={classes.tripNoteSettinContainer}>
        <div className={classes.toolbar} />
        <div className={classes.pageHeader}>
          <Typography variant="h5" className={classes.heading}>
            行程備註設定
          </Typography>
          <div className={classes.des}>
            ・管理員可在此頁自訂備註的形式/標題/描述/數量，並可隨時停用或刪除
          </div>
        </div>
        <SettingSection
          active={1}
          title={`使用中的備註 (${customField.length})`}
          handleOpenModal={handleOpenModal}
          custom_field={customField}
          handleEditRow={handleEditRow}
          handleActiveNote={handleActiveNote}
          handleSortCustomField={handleSortCustomField}
        />
        <div
          className={classes.addControl}
          onClick={() => handleOpenModal('new')}
        >
          <img
            className={classes.addIcon}
            src="https://static.taxigo-cdn.com/icons/business/add-green.svg"
          />
          <div className={classes.add}>新增一個備註</div>
        </div>
        <Divider className={classes.divider} />
        <SettingSection
          active={0}
          title={`停用的備註 (${blockCustomField.length})`}
          handleOpenModal={handleOpenModal}
          custom_field={blockCustomField}
          handleEditRow={handleEditRow}
          handleActiveNote={handleActiveNote}
        />
      </div>
      <EditNoteModal
        open={open}
        editRow={editRow}
        modalType={modalType}
        company={company}
        handleOpenModal={handleOpenModal}
        handlePatchField={handlePatchField}
        handleDeleteField={handleDeleteField}
      />
    </>
  )
}

export default TripNoteSetting
