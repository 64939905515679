import React from 'react'
import { CorpProvider } from './reducers/Main'
import Router from './router'
import { GlobalStyle } from './css/Reset'


function App() {

	return (
		<CorpProvider>
			<GlobalStyle />
			<Router />
		</CorpProvider>
	)
}

export default App
