export default {
  upload: {
    img: 'upload-step.svg',
    description: '資料上傳中...<br/>請勿關閉或重新整理此頁面',
    sec_description: '資料已上傳',
  },
  upload_disabled: {
    img: 'upload-step-disable.svg',
    description: '資料上傳',
  },
  checking: {
    img: 'check-step.svg',
    description: '資料檢查中...<br/>請勿關閉或重新整理此頁面',
    sec_description: '資料檢查完成',
  },
  checking_disabled: {
    img: 'check-step-disable.svg',
    description: '檢查資料',
  },
  finished: {
    type: 'finished',
    img: 'finish-step.svg',
    sec_description: '匯入成功<br/>您可以匯出資料作為最新檔案保存',
    error_msg:
      '匯入失敗，<span>下載失敗資料檔案.xlsx</span><br/>修改後重新上傳',
    auth_error_msg: '匯入失敗，請重新登入後，再重新上傳',
  },
  finished_disabled: {
    img: 'finish-step-disable.svg',
    description: '匯入結果',
  },
}
