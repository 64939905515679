import ExcelJS from 'exceljs'

function getColumnText(column) {
  if (column.richText) {
    const obj = JSON.parse(JSON.stringify(column.richText))
    let text = ''
    if (obj.length > 0) {
      obj.forEach((element) => {
        text += element.text
      })
    }
    return text
  }
  if (column.text) {
    return column.text
  }
  return column.toString()
}

async function ImportNoteOptions(event, handleAddOptions) {
  const file = event.target.files[0]
  const fileReader = new FileReader()
  fileReader.onload = async (event) => {
    try {
      const { result } = event.target
      const workbook = new ExcelJS.Workbook()
      const data = []
      workbook.xlsx
        .load(result)
        .then(function (workbook) {
          workbook.worksheets.forEach(function (sheet) {
            sheet.eachRow(function (row, rowNumber) {
              console.log(row.values, 'i rowwwwww')
              if ((row.values.length = 1 && rowNumber && rowNumber > 1)) {
                const option = row.values[1] && row.values[1]
                const columnText = getColumnText(option)
                data.push({
                  option: columnText.substring(0, 60),
                })
              }
            })
          })
          handleAddOptions && handleAddOptions(data)
        })
        .catch((err) => alert('載入檔案失敗'))
    } catch (error) {
      alert('載入檔案失敗')
      console.log('error' + error)
    }
  }
  fileReader.readAsArrayBuffer(file)
}

export { ImportNoteOptions }
