import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styledBy = (property, mapping) => props => mapping[props[property]]

const useStyles = makeStyles({
  root: {
    borderRadius: props => `${handleButtonType(props.type)}`,
    height: '40px',
    margin: '8px 5px',
    boxShadow: 'none',
    background: '#797979',
    color: 'white',
    padding: '6px 14px 6px 16px',
    '&:hover': {
      background: '#797979'
    }
  },
  icon: {
    fontSize: '25px',
    height: '25px',
    width: '25px'
  },
  uploadText: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 500
  }
})

function handleButtonType(type) {
  switch (type) {
    case 'right':
      return '5px 20px 20px 5px'
    case 'left':
      return '20px 5px 5px 20px'
    default:
      return '20px'
  }
}

function ExportButtonModel(props) {
  const { color, onImportExcel, fileUploaderRef, ...other } = props
  const classes = useStyles(props)
  return (
    <Button
      className={classes.root}
      {...other}
      variant="contained"
      color="default"
      onClick={props.onClick}
    >
      <Typography className={classes.uploadText}>{props.title}</Typography>
      <img
        className={classes.icon}
        src={`https://static.taxigo-cdn.com/icons/business/${props.icon}`}
      />
      <input
        type="file"
        id="file"
        ref={fileUploaderRef}
        onChange={e => onImportExcel(e)}
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
      />
    </Button>
  )
}

ExportButtonModel.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ExportButtonModel
