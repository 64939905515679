import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyle = makeStyles(theme => ({
  menu_list: {
    padding: '16px 40px 16px 24px',
    maxHeight: '50vh'
  },
  menu_item: {
    padding: '8px 40px 8px 24px',
    '& span': {
      fontWeight: 600
    }
  }
}))

function MenuList(props) {
  const {
    default_title,
    options,
    field,
    addSearch,
    setOptionTitle,
    open,
    setOpen,
    anchorRef,
    handleGroup,
    handleRole,
    list_header,
    ...other
  } = props

  const classes = useStyle()

  function handleClose(event, option) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (event.target.id !== '') {
      setOptionTitle(option.name)
      addSearch(field, option)
      const group = {
        group_name: option.name,
        id: event.target.id
      }
      handleGroup && handleGroup(group)
      handleRole(option.name)
    }
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Menu
        id="options-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { maxHeight: '50vh' }
        }}
      >
        {list_header && (
          <MenuItem className={classes.menu_item} onClick={handleClose}>
            <span>{list_header}</span>
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem
            id={option.id}
            className={classes.menu_item}
            onClick={event => handleClose(event, option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

MenuList.propTypes = {
  default_title: PropTypes.string.isRequired,
  options: PropTypes.string.isRequired
}

export default MenuList
