import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Fab, Icon, Popper } from '@material-ui/core'
// import Icon from '@material-ui/core/Icon'
import MenuList from '../Menu/MenuList'
import FilterAutoComplete from '../Input/FilterAutoComplete'

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    borderRadius: 15,
    height: 30,
    padding: '20px',
    margin: 8
  },
  fab: {
    margin: theme.spacing(1),
    background: 'white',
    '&:hover': {
      background: 'white'
    },
    color: 'black',
    boxShadow: 'none',
    height: '1.5rem',
    alignItems: 'center'
  },
  span_hint: {
    fontWeight: 400,
    fontSize: '12.5px',
    color: 'rgb(92, 92, 92)'
  },
  span: {
    fontWeight: 400,
    fontSize: '12.5px',
    color: 'black'
  },
  icon_visible: {
    paddingLeft: theme.spacing(0.5),
    color: '#4b4d4c',
    visibility: 'visible'
  },
  icon_invisible: {
    marginLeft: theme.spacing(0.5),
    color: '#4b4d4c',
    visibility: 'hidden',
    display: 'none'
  }
}))

function FilterButtonWithAuto(props) {
  const {
    title,
    default_title,
    options,
    field,
    addSearch,
    list_header,
    auto,
    ...other
  } = props
  const classes = useStyles(props)

  const [optionTitle, setOptionTitle] = useState(default_title)
  const [open, setOpen] = useState(false)
  const [autoInput, setAutoInput] = useState(false)
  const [isDefaultValue, setIsDefaultValue] = useState(false)
  const anchorRef = React.useRef(null)

  useEffect(() => {
    setIsDefaultValue(optionTitle === default_title)
    return () => {}
  }, [optionTitle])

  function openList() {
    if (auto) {
      setAutoInput(prevAuto => !prevAuto)
    } else {
      setOpen(prevOpen => !prevOpen)
    }
  }

  function clearDefaultValue() {
    // console.log('clearDefaultValue')
    // if (title === default_title) return
    setOptionTitle(default_title)
    addSearch(field, default_title, 0)
  }
  function handleAutoValue(v) {
    console.log('innnnnn auto', v)
  }

  // useEffect(() => {
  //   // console.log(title)
  //   // console.log(default_title)
  //   if (title === default_title) {
  //     clearDefaultValue()
  //   }
  //   return () => {}
  // }, [title])

  return (
    <React.Fragment>
      {/* <Fab
        variant="extended"
        className={classes.fab}
        {...other}
        aria-label="More"
        aria-owns={open ? 'options-menu' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        disableRipple="true"
      >
        <span
          className={isDefaultValue ? classes.span_hint : classes.span}
          onClick={openList}
        >
          {' '}
          {optionTitle}{' '}
        </span>
        <Icon
          className={
            isDefaultValue ? classes.icon_visible : classes.icon_invisible
          }
          fontSize="small"
          onClick={openList}
        >
          keyboard_arrow_down
        </Icon>
        <Icon
          className={
            isDefaultValue ? classes.icon_invisible : classes.icon_visible
          }
          fontSize="small"
          onClick={clearDefaultValue}
        >
          clear
        </Icon>
      </Fab> */}
      <Popper id="filter-Popper" open={open} anchorEl={anchorRef} className="">
        <FilterAutoComplete
          id="filter-autocomplete"
          options={options}
          getOptionLabel={option => option.name}
          // onInputChange={(e, v) => handleGroup(v)}
          onChange={(e, v) => handleAutoValue(v)}
          renderInput={params => (
            <div ref={params.InputProps.ref}>
              <input
                type="text"
                id="filter-auto-input"
                placeholder={list_header}
                value={optionTitle}
                {...params.inputProps}
              />
            </div>
          )}
        />
      </Popper>
      )
    </React.Fragment>
  )
}

FilterButtonWithAuto.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.string.isRequired
}

export default FilterButtonWithAuto
