import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles({
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: '#ffffff'
  },
  checkedIcon: {
    backgroundColor: '#ffffff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#00cba0,#00cba0 28%,transparent 32%)',
      content: '""'
    }
  }
})

function BasicRadioButton(props) {
  const classes = useStyles()
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default BasicRadioButton
