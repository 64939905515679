import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

async function onExportGroupExcel(groups) {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'LINE TAXI Business'
  workbook.lastModifiedBy = 'LINE TAXI Business'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet_p1 = workbook.addWorksheet('member_sheets')

  const fileName = '所有群組資訊.xlsx'

  const rowTitle = ['群組名稱', '群組預算/月', '成員預算/月']

  sheet_p1.getColumn(1).width = 25
  sheet_p1.getColumn(2).width = 20
  sheet_p1.getColumn(3).width = 20

  const rowContent = (item) => [
    item.name,
    item.quota,
    item.member_default_quota,
  ]

  sheet_p1.addRow(rowTitle)
  groups.forEach((item) => {
    sheet_p1.addRow(rowContent(item))
  })

  const buf = await workbook.xlsx.writeBuffer()
  FileSaver.saveAs(new Blob([buf]), fileName)
}

export default onExportGroupExcel
