import { makeStyles } from '@material-ui/core/styles'

const generalStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflow: 'auto',
    width: '100%',
    minHeight: '65vh',
    padding: theme.spacing(8, 10),
  },
  '@media (max-width:960px)': {
    paper: {
      width: '100%',
      minHeight: '40',
    },
  },
  '@media (max-width:1200px)': {
    paper: {
      width: '100%',
      padding: theme.spacing(4, 5),
    },
  },
  cell: {
    borderBottom: 'none',
    textDecoration: 'none',
    minWidth: '100px',
  },
  row: {
    textDecoration: 'none',
    '& td:first-child': {
      paddingLeft: '16px',
    },
  },
  headRow: {
    '& th:first-child': {
      paddingLeft: '16px',
    },
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: '26px',
    color: '#1e201f',
    textAlign: 'start',
  },
  filter: {
    background: 'lightgray',
    width: '100%',
    borderRadius: '24px',
  },
  cardPaper: {
    overflow: 'auto',
    borderRadius: 10,
    padding: theme.spacing(4),
    boxShadow: '0 3px 6px 0 rgba(45, 47, 46, 0.15)',
  },
  '@media (min-width:1200px)': {
    cardPaper: {
      width: '70vw',
      padding: theme.spacing(5, 8),
    },
  },
  right_button: {
    borderRadius: '5px 20px 20px 5px',
    margin: '8px 5px',
    boxShadow: 'none',
    background: '#797979',
    color: 'white',
  },
}))

export default generalStyle
