import React from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'

function ErrorDialog(props) {
  const { open, handleClose, message, confirmText } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent id="alert-dialog-title">{message}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary" autoFocus>
          {confirmText ? confirmText : '確認'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
