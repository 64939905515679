import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/styles'

const FilterAutocomplete = withStyles({
  input: {
    width: '100%',
    height: '56px',
    padding: '12px 15px',
    margin: '8px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    outline: 'none',
    fontSize: '15px'
  }
})(props => <Autocomplete {...props} />)

export default FilterAutocomplete
