import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'

const AnimationScaleUp = keyframes`
 0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
const AnimationScaleDown = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
`
const Root = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
const Background = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #1e201f;
  left: 0;
  top: 0;
  z-index: 10000;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.$isOpen ? 0.6 : 0)};
`
const Wrapper = styled.div`
  min-width: 312px;
  border-radius: 7px;
  background-color: #fff;
  transition: transform 0.1s;
  overflow: hidden;
  z-index: 100000;
  animation: ${(props) => (props.$isOpen ? AnimationScaleUp : AnimationScaleDown)} 0.2s both;
`
const Content = styled.div`
  padding: 40px 32px;
`
const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #1e201f;
  ${(props) => props.$textCenter && 'text-align: center;'}
`
const ButtonWrapper = styled.div`
  display: flex;
  border: 1px solid rgba(30, 32, 31, 0.12);
`
const Button = styled.button`
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #1e201f;
  flex: 1;
  transition: 0.3s;
  &:hover,
  &:active {
    background-color: #eeefee;
  }
`

const PopUp = React.memo(({ warning, cancelText, confirmText, closeModal, cancel, confirm, textCenter }) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleCancelOnClick = () => {
    setIsOpen(false)
    cancel && cancel()
    closeModal()
  }
  const handleConfirmOnClick = () => {
    setIsOpen(false)
    confirm && confirm()
    closeModal()
  }
  return (
    <Root>
      <Wrapper $isOpen={isOpen}>
        <Content>
          <Text $textCenter={textCenter}>{warning}</Text>
        </Content>
        <ButtonWrapper>
          <Button onClick={handleCancelOnClick}>{cancelText || '取消'}</Button>
          <Button onClick={handleConfirmOnClick}>{confirmText || '確定'}</Button>
        </ButtonWrapper>
      </Wrapper>
      <Background $isOpen={isOpen} />
    </Root>
  )
})

export default PopUp
