import React from 'react'
import { Typography } from '@material-ui/core'

const ReceiptDescription = () => {
  return (
    <Typography variant="caption" style={{ marginTop: 12 }}>
      <span>開立收據與發票說明：</span>
      <ul style={{ listStyleType: 'disc', marginLeft: 20, marginTop: 4 }}>
        <li>
          使用計程車服務（小黃計程車/多元計程車/星選科技車），開立收據
        </li>
        <li>
          使用租賃車服務（經濟租賃車/商務租賃車/經濟租賃車 XL），開立發票
        </li>
      </ul>
    </Typography>
  )
}

export default ReceiptDescription
