import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

async function ExportNoteOptions(options) {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'LINE TAXI Business'
  workbook.lastModifiedBy = 'LINE TAXI Business'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet_p1 = workbook.addWorksheet('note_options_sheets')
  const rowContent = (item) => [item.option]

  sheet_p1.addRow(['選項名稱（限60個字元，超出部分不顯示'])
  console.log(options)
  options.forEach((item) => {
    sheet_p1.addRow(rowContent(item))
  })

  const buf = await workbook.xlsx.writeBuffer()
  FileSaver.saveAs(new Blob([buf]), '企業簽單備註下拉選項.xlsx')
}

export { ExportNoteOptions }
