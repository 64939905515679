import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

const styledBy = (property, mapping) => props => mapping[props[property]]

const useStyles = makeStyles(theme => ({
  root: {
    background: '#2d2f2e',
    border: 0,
    borderRadius: 15,
    height: 30,
    padding: '0 10px',
    margin: 8
  },
  fab: {
    margin: '8px 0',
    background: '#2d2f2e',
    '&:hover': {
      background: '#2d2f2e'
    },
    color: 'white',
    boxShadow: 'none',
    height: '40px',
    fontSize: '14px',
    fontWeight: 500
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1.5),
    color: styledBy('color', {
      green: '#00c299',
      yellow: 'rgb(226, 187, 59)',
      pink: 'rgb(239, 139, 139)'
    })
  }
}))

function IconButton(props) {
  const { color, ...other } = props
  const classes = useStyles(props)
  return (
    <Fab variant="extended" color="pink" className={classes.fab} {...other}>
      <Icon className={classes.icon} fontSize="large">
        {props.icon}
      </Icon>
      {props.title}
    </Fab>
  )
}

IconButton.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

export default IconButton
