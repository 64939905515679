import React from 'react'
import ExcelJS from 'exceljs'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  selectFile: {
    padding: '0 16px',
    lineHeight: '38px',
    textAlign: 'center',
    borderRadius: '19px',
    backgroundColor: '#4b4d4c',
    color: 'white',
    borderWidth: 0,
    cursor: 'pointer',
  },
}))

function ModalImportButton(props) {
  const classes = useStyles()
  const {
    type,
    columnKeys,
    fileUploaderRef,
    buttonDisabled,
    setFileName,
    setFile,
    handleClickUpload,
  } = props

  function getColumnText(column = '') {
    if (column.richText) {
      const obj = JSON.parse(JSON.stringify(column.richText))
      let text = ''
      if (obj.length > 0) {
        obj.forEach((element) => {
          text += element.text
        })
      }
      return text
    }
    if (column.text) {
      return column.text
    }
    return column.toString()
  }

  function handleTripQuota(trip_quota) {
    if (
      ['', '無上限', '0', 0].includes(trip_quota) ||
      parseInt(trip_quota) >= 1000000
    ) {
      return -1
    }
    return parseInt(trip_quota)
  }

  function handleMemberQuota(quota) {
    if (quota === '依群組設定') return 'default'
    return quota
  }

  const handleValues = (value) => {
    return {
      id: getColumnText(value),
      name: getColumnText(value),
      cc: getColumnText(value || '+886'),
      phone_number: getColumnText(value),
      email: getColumnText(value),
      group_name: getColumnText(value),
      role: getColumnText(value).roleTWLocalized(),
      quota:
        type === 'group'
          ? getColumnText(value)
          : handleMemberQuota(getColumnText(value)),
      review_notes: getColumnText(value).reviewNotesStatus(),
      trip_quota: handleTripQuota(getColumnText(value)) || -1,
      member_default_quota: getColumnText(value),
      member_default_trip_quota: handleTripQuota(getColumnText(value)) || -1,
      member_default_review_notes: getColumnText(value).reviewNotesStatus(),
      action: getColumnText(value).actionLocalized(),
    }
  }

  function onImportExcel(event) {
    const file = event.target.files && event.target.files[0]
    const { name } = file
    setFileName(name)
    const fileReader = new FileReader()
    let withId = true
    fileReader.onload = async (event) => {
      try {
        const { result } = event.target
        const workbook = new ExcelJS.Workbook()
        const data = []
        workbook.xlsx
          .load(result)
          .then(function (workbook) {
            workbook.worksheets[0].eachRow(function (row, rowNumber) {
              if (rowNumber && rowNumber === 2) {
                withId = row.values[1] === '@ID'
                !withId && columnKeys.shift()
              }
              if (row.values.length > 5 && rowNumber && rowNumber > 2) {
                if (
                  !getColumnText(
                    row.values[columnKeys.length]
                  ).actionLocalized()
                )
                  return
                const rowObj = {}
                columnKeys.forEach((item, index) => {
                  rowObj[item] = handleValues(row.values[index + 1])[item]
                })
                data.push(rowObj)
              }
            })
            console.log(data, 'import-----')
            setFile({ data })
          })
          .catch((err) => {
            console.log(err)
            setFileName('')
            alert('載入檔案失敗')
          })
      } catch (error) {
        alert('載入檔案失敗')
        setFileName('')
        console.log('error' + error)
      }
    }
    fileReader.readAsArrayBuffer(file)
  }

  return (
    <button
      className={classes.selectFile}
      onClick={handleClickUpload}
      disabled={buttonDisabled}
    >
      <input
        type="file"
        id="file"
        ref={fileUploaderRef}
        onChange={(e) => onImportExcel(e)}
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
      />
      選擇檔案
    </button>
  )
}

export default ModalImportButton
