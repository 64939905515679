import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import {
  Grid,
  Typography,
  TableCell,
  TableRow,
  CircularProgress,
} from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import FilterButtonWithClear from '../view/Buttons/FilterButtonWithClear'
import { CorpContext } from '../reducers/Main'
import QutaProcessBar from '../view/Bars/QutaProcessBar'
import MSortTable from '../view/Table/MSortTable'
import Auth from '../model/Auth'
import { generalStyle } from '../styles'
import { getStatisticsFilter } from '../api'
import { handleSort } from '../utils'

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  table: {
    minWidth: 650,
  },
  paper: {
    overflow: 'auto',
    padding: theme.spacing(5, 10),
    borderRadius: 10,
    width: '70vw',
    minHeight: '65vh',
  },
  cell: {
    borderBottom: 'none',
    padding: '10px 0',
    minWidth: '170px',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
    textAlign: 'start',
  },
  filter: {
    background: 'lightgray',
    width: '100%',
    borderRadius: '24px',
    marginBottom: '20px',
  },
  progress_bar: {
    background: 'lightgray',
    height: '2px',
  },
  progress: {
    background: 'orange',
    height: '2px',
  },
  amount_content: {
    marginBottom: '35px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  loading: {
    color: '#797979',
  },
  '@media (max-width:1200px)': {
    paper: {
      padding: theme.spacing(2),
      width: '100%',
      minHeight: '40',
    },
  },
}))

const headRow = [
  // { id: 'id', numeric: 'left', label: '員工編號', hide: false },
  { id: 'name', numeric: 'left', label: '姓名', hide: false },
  { id: 'role', numeric: 'left', label: '角色', hide: false },
  { id: 'group_name', numeric: 'left', label: '群組', hide: false },
  { id: 'amount', numeric: 'left', label: '總車資', hide: false },
]

function Statistics() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const [group_f, setGroup] = useState('所有群組')

  const [member_f, setMember] = useState('所有成員')
  const [member_id, setMemberID] = useState(0)
  const [memberFilterList, setMemberFilterList] = useState([])

  const [summary, setSummary] = useState([])
  const [memberList, setMemberList] = useState([])
  const [quota, setQuota] = useState(0)

  const [loading, setLoading] = useState(true)

  const { state } = useContext(CorpContext)

  useEffect(() => {
    setMemberFilterList(handleSort(state.all_member, 'asc', 'name'))
  }, [state.all_member, state.summary, state.company])

  useEffect(() => {
    realtimeSearch()
  }, [group_f, member_f])

  function currentQuta() {
    setQuota(
      state.company.map((el) => {
        return el.quota
      })
    )
    if (group_f !== '所有群組') {
      const result = state.groups.filter((item, index, array) => {
        return item.id === group_f.id
      })
      setQuota(result[0].quota)
    }
    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      const result = state.member.filter((item, index, array) => {
        return item.id === member_id
      })

      console.log(result)
      result[0] && setQuota(result[0].quota)
    }
  }

  function addSearch(field, option) {
    switch (field) {
      case 'group':
        setGroup(option)
        break
      case 'member':
        setMember(option.name)
        setMemberID(option.id)
        break
      default:
        return
    }
  }

  function triggerClearSearch() {
    setGroup('所有部門')
    setMember('所有成員')
    setMemberID(0)
  }

  async function realtimeSearch() {
    const usersOject = {}

    if (group_f !== '所有群組') {
      usersOject.group = group_f.id
    } else {
      delete usersOject.group
    }

    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      usersOject.id = member_id
    } else {
      delete usersOject.id
    }

    const queryString = []
    for (const key in usersOject) {
      queryString.push(`${key}:"${encodeURIComponent(usersOject[key])}"`)
    }

    const group_id = usersOject.group ? `(group_id:${group_f.id})` : ''
    const data = await getStatisticsFilter(query(queryString), group_id)
    console.log(data, 'statics------')
    if (data.status !== 200) return alert('搜尋失敗，請稍後再試。')
    const list = []
    let summary = 0
    data.data.data.users.forEach((element, index) => {
      !isEmpty(element.history) &&
        element.history.forEach((item) => {
          const new_row = Object.assign({}, element)
          const current_month =
            (item.deductible && item.deductible[0].deductibleAmount) || 0
          if (current_month !== 0) {
            new_row.group = item
            new_row.amount = current_month
            list.push(new_row)
          }
          if (usersOject.id) {
            const current_month =
              element.history[0].deductible[0].deductibleAmount || 0
            if (index === 0) summary = current_month
          } else {
            summary += current_month
          }
        })
    })
    setSummary(summary)
    setMemberList(list)
    setLoading(false)
    currentQuta()
  }

  function query(arr) {
    if (!arr.length) return ''
    return `(${arr.join(',')})`
  }

  function printRow(row, index) {
    return (
      <TableRow
        hover
        role="checkbox"
        key={row.trip_id}
        className={generalClasses.row}
      >
        <TableCell className={classes.cell}>{row.name}</TableCell>
        {row.group && (
          <>
            <TableCell className={classes.cell}>
              {row.group.role.roleLocalized()}
            </TableCell>

            <TableCell className={classes.cell}>{row.group.name}</TableCell>
          </>
        )}
        <TableCell className={classes.cell}>
          {row.amount.toLocaleString('en')} 元
        </TableCell>
      </TableRow>
    )
  }

  //Sort end ------------
  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={generalClasses.paper}>
        <Typography variant="h5" className={classes.heading}>
          統計表
        </Typography>
        <div className={classes.amount_content}>
          <Typography variant="body2" color="textSecondary">
            主管層級可於此頁搜尋查看所管理群組本月累積乘車金額
          </Typography>
          <Typography variant="h1" style={{ color: 'orange' }}>
            {summary.toLocaleString('en')}
            <span style={{ fontSize: '20px', color: 'gray' }}>
              {' '}
              / {quota.toLocaleString('en')} 元
            </span>{' '}
          </Typography>
          <QutaProcessBar summary={summary} quta={quota}></QutaProcessBar>
        </div>
        <div className={classes.filter}>
          <Grid container justify="space-between">
            <Grid>
              {!Auth.getPermission(state, 'DEFAULT') && (
                <FilterButtonWithClear
                  title={group_f}
                  default_title="所有群組"
                  list_header="選擇群組"
                  options={state.groups}
                  field={'group'}
                  addSearch={addSearch}
                />
              )}
              <FilterButtonWithClear
                title={member_f}
                default_title="所有成員"
                list_header="選擇成員"
                options={memberFilterList}
                field={'member'}
                addSearch={addSearch}
              />
            </Grid>
            <Grid>
              <BasicButton
                color="#ffffff"
                background="#4b4d4c"
                hoverColor="black"
                title="清除設定"
                onClick={triggerClearSearch}
              />
            </Grid>
          </Grid>
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} />
          </div>
        ) : (
          <MSortTable
            list={memberList}
            headRow={headRow}
            printRow={printRow}
          ></MSortTable>
        )}
      </div>
    </React.Fragment>
  )
}

export default Statistics
