import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, Popover, MenuItem } from '@material-ui/core'
import { postBatchUser } from '../../api'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#e8e8e8',
    padding: '40px 48px 48px 48px',
    borderRadius: '8px',
    width: '880px',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 1.23,
    color: '#1e201f',
    marginBottom: '40px',
  },
  contentSection: {
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    padding: '16px 40px',
    marginBottom: '8px',
  },
  contentList: {
    display: 'flex',
    minHeight: '40px',
    width: '100%',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#4b4d4c',
  },
  title: {
    fontWeight: 500,
    width: '90px',
    marginRight: '32px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  des: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    height: '32px',
    width: '32px',
    cursor: 'pointer',
  },
  closeControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '40px',
  },
  buttonWrap: {
    display: 'flex',
    '& button:disabled': {
      backgroundColor: '#f5f5f8',
      color: '#d2d2d2',
      cursor: 'unset',
    },
  },
  arrowIcon: {
    height: '16px',
    width: '16px',
    marginLeft: '8px',
  },
  whiteButton: {
    border: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    marginRight: '24px',
    lineHeight: 1.5,
    borderRadius: '2px',
    fontWeight: 500,
    fontSize: '16px',
    color: '#4b4d4c',
    backgroundColor: '#f5f5f8',
    cursor: 'pointer',
  },
  blackButton: {
    padding: '8px 16px',
    lineHeight: 1.5,
    borderRadius: '2px',
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: '#4b4d4c',
    color: 'white',
    cursor: 'pointer',
  },
}))

export default function ReviewDetailModal({
  openModal,
  handleOpenModal,
  reviewDetail,
  handleUpdateReviewStatus,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [reviewStatus, setReviewStatus] = useState(null)
  const detailAnchorRef = React.useRef(null)

  useEffect(() => {
    setReviewStatus(reviewDetail.status)
  }, [reviewDetail])

  function handleClose(event, option) {
    if (
      detailAnchorRef.current &&
      detailAnchorRef.current.contains(event.target)
    ) {
      return
    }
    if (event.target.id !== '') {
      setReviewStatus(option.reviewStatusTWLocalized())
    }
    setOpen(false)
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen)
  }

  function handleStatus() {
    const options = []
    let actions = []

    switch (reviewStatus) {
      case 'PENDING':
        actions = ['核准', '拒絕']
        break
      case 'APPROVED':
        actions = ['拒絕']
        break
      case 'REJECTED':
        actions = ['核准']
        break
      default:
        break
    }

    options.push(
      actions.map((option) => (
        <MenuItem id={option} onClick={(e) => handleClose(e, option)}>
          {option}
        </MenuItem>
      ))
    )
    return options
  }

  const handleStatusButton = () => {
    let status

    switch (reviewStatus) {
      case 'PENDING':
        status = '待審核'
        break
      case 'APPROVED':
        status = '核准'
        break
      case 'REJECTED':
        status = '拒絕'
        break
      case 'EXPIRED':
        status = '拒絕(已過期)'
        break
      case 'COMPLETED':
        status = '已搭乘'
        break
      default:
        status = '---'
        break
    }

    if (reviewDetail.isInTrip && reviewStatus !== 'COMPLETED') {
      status = '行程中'
    }

    return status
  }

  const handleDisabled = () => {
    return (
      reviewDetail.isInTrip ||
      ['EXPIRED', 'COMPLETED'].includes(reviewDetail.status)
    )
  }

  const handleSaveChange = () => {
    handleUpdateReviewStatus(reviewDetail.id, reviewStatus, true)
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <div className={classes.titleWrap}>
              <div className={classes.modalTitle}>差旅審核單 詳細資訊</div>
              <img
                className={classes.closeIcon}
                src="https://static.taxigo-cdn.com/icons/business/close.svg"
                onClick={handleOpenModal}
                alt=""
              />
            </div>
            <div className={classes.contentSection}>
              <ContentList title="表單編號" des={reviewDetail.number_code} />
              <ContentList
                title="申請時間"
                des={moment(reviewDetail.create_unix_timestamp * 1000).format(
                  'YYYY/MM/DD HH:mm'
                )}
              />
              <ContentList title="群組" des={reviewDetail.group_name} />
              <ContentList title="成員" des={reviewDetail.applicant} />
              <ContentList
                title="乘車時間"
                des={moment(
                  reviewDetail.trip_reserve_unix_timestamp * 1000
                ).format('YYYY/MM/DD HH:mm')}
              />
              <ContentList title="上車" des={reviewDetail.start_address} />
              {!isEmpty(reviewDetail.stops) &&
                reviewDetail.stops.map((item) => (
                  <ContentList
                    key={item.id}
                    title="停靠點"
                    des={item.address}
                  />
                ))}
              <ContentList title="下車" des={reviewDetail.end_address} />
            </div>
            {!isEmpty(reviewDetail.custom_fields) && (
              <div className={classes.contentSection}>
                {reviewDetail.custom_fields.map((item, index) => (
                  <ContentList
                    key={index}
                    title={item.field}
                    des={item.content}
                  />
                ))}
              </div>
            )}
            <div className={classes.closeControl}>
              <div className={classes.whiteButton} onClick={handleOpenModal}>
                取消
              </div>
              <div className={classes.buttonWrap}>
                <button
                  className={classes.whiteButton}
                  onClick={handleToggle}
                  ref={detailAnchorRef}
                  disabled={handleDisabled()}
                >
                  {handleStatusButton()}
                  <img
                    className={classes.arrowIcon}
                    src="https://static.taxigo-cdn.com/icons/general/arrow-down.svg"
                    alt=""
                  />
                </button>
                {!handleDisabled() && (
                  <div
                    className={classes.blackButton}
                    onClick={handleSaveChange}
                  >
                    儲存變更
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Popover
        id="options-menu"
        anchorEl={detailAnchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
      >
        {handleStatus()}
      </Popover>
    </>
  )
}

const ContentList = React.memo(({ title, des }) => {
  const classes = useStyles()
  return (
    <div className={classes.contentList}>
      <div className={classes.title}>{title}</div>
      <div className={classes.des}>{des}</div>
    </div>
  )
})
