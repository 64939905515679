import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

async function onExportAddMemberSampleExcel(state) {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'LINE TAXI Business'
  workbook.lastModifiedBy = 'LINE TAXI Business'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet_p1 = workbook.addWorksheet('member_sheets')
  const lookUpSheet = workbook.addWorksheet('member_groups')
  lookUpSheet.state = 'hidden'

  sheet_p1.getColumn(1).width = 20
  sheet_p1.getColumn(2).width = 10
  sheet_p1.getColumn(3).width = 20
  sheet_p1.getColumn(4).width = 45
  sheet_p1.getColumn(5).width = 20
  sheet_p1.getColumn(6).width = 20
  sheet_p1.getColumn(7).width = 45
  sheet_p1.getColumn(8).width = 20
  sheet_p1.getColumn(9).width = 40
  sheet_p1.getColumn(10).width = 20

  sheet_p1.mergeCells('A1:J1')
  sheet_p1.getCell('A1').value = `
	使用說明：\n
1. 有 * 的欄位，為必填資訊。\n
2. 有 # 的欄位，為下拉選項，請參考範例填寫。\n
3.「#動作*」欄位說明：\n
	A. 新增：將此成員資料新增至企業後台。\n
	`
  sheet_p1.getCell('A1').alignment = { wrapText: true, vertical: 'top' }
  sheet_p1.getCell('A1').border = {
    bottom: { style: 'thin', color: { argb: 'FF2b2b2b' } },
  }
  sheet_p1.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE8E8E8' },
  }
  sheet_p1.getRow(1).height = 120

  const rowTitle = [
    '姓名*',
    '國碼*',
    '手機號碼*',
    '信箱*',
    '群組名稱*',
    '角色*',
    '成員預算/月*（數字/依群組設定）',
    '#填寫備註*',
    '單趟上限*（數字/無上限）',
    '#動作*',
  ]
  sheet_p1.addRow(rowTitle)

  sheet_p1.getRow(2).alignment = {
    vertical: 'middle',
    horizontal: 'center',
  }

  sheet_p1.addRow([
    '範例：Tony.Lin',
    '+886',
    '0999000001',
    'tony@linetaxi.com.tw',
    'Product Team',
    '主管',
    '200',
    '是',
    '200',
    '新增',
  ])
  sheet_p1.addRow([
    '範例：賴鐵錫',
    '+886',
    '0999000002',
    'lai@linetaxi.com.tw',
    '行銷部',
    '成員',
    '依群組設定',
    '是',
    '300',
    '新增',
  ])
  sheet_p1.addRow([
    '範例：C12332',
    '+886',
    '0999000003',
    'c12332@linetaxi.com.tw',
    'CS 客服部',
    '管理員',
    '依群組設定',
    '否',
    '無上限',
    '新增',
  ])

  // const groupsArray = []
  // state.groups.forEach((item) => {
  //   lookUpSheet.addRow([item.name])
  //   groupsArray.push(item.name)
  // })

  // sheet_p1.getColumn(11).values = groupsArray

  sheet_p1.getColumn(5).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['=member_groups!$A$1:$A$999'],
      // formulae: [`'$K$1:$K$999'`],
    }
  })
  sheet_p1.getColumn(6).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"成員, 主管, 管理員"'],
    }
  })
  sheet_p1.getColumn(8).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"是,否"'],
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  const fileTitle = '成員空白格式.xlsx'

  FileSaver.saveAs(new Blob([buf]), fileTitle)
}

export default onExportAddMemberSampleExcel
