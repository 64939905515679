import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ProfileCard from './ProfileCard'
import TripsCard from './TripsCard'

import { CorpContext } from '../reducers/Main'

const userStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  profileContainer: {
    padding: theme.spacing(5, 10)
  }
}))

function Profile() {
  const classes = userStyle()

  const { state } = useContext(CorpContext)

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={classes.profileContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProfileCard me={state.me} />
          </Grid>
          <Grid item xs={12}>
            <TripsCard />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Profile
