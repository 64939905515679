import React, { useState } from 'react'
import { TableCell } from '@material-ui/core'
import { generalStyle } from '../../styles'
import MaxWidthForTableCell from '../Table/MaxWidthForTableCell'
import SwitchButton from '../Buttons/SwitchButton'
import ActionsButton from '../Buttons/ActionsButton'
import parsePhoneNumber from 'libphonenumber-js'

function MemberTableCell(props) {
  const { row, implementActions, updateReviewNotes, company } = props
  const generalClasses = generalStyle()
  const [checked, setChecked] = useState(Boolean(row.review_notes))

  function handleUpdateSwitch(switchChecked) {
    setChecked(switchChecked)
  }

  function handleTripQuota() {
    return row.trip_quota !== -1 ? row.trip_quota : '無上限'
  }

  function handlePermission(row) {
    if (row.permission.includes('ADMIN')) {
      return '管理員'
    }
    return ''
  }

  function handlePhoneNumber(phone_number) {
    if (!phone_number) return '未輸入'
    const phoneNumber = parsePhoneNumber(phone_number)
    return phoneNumber.formatNational()
  }

  return (
    <>
      <MaxWidthForTableCell
        context={row.name}
        minWidth="150px"
        maxWidth="450px"
        textCut
      />
      <MaxWidthForTableCell
        context={handlePhoneNumber(row.phone_number)}
        maxWidth="150px"
      />
      <MaxWidthForTableCell
        context={row.email}
        minWidth="250px"
        maxWidth="350px"
        textCut
      />
      <MaxWidthForTableCell context={handlePermission(row)} minWidth="80px" />
      <MaxWidthForTableCell
        context={row.status.userStatusLocalized()}
        minWidth="100px"
        color={row.status.userStatusColor()}
      />
      <TableCell className={generalClasses.cell} align="center" padding="none">
        <ActionsButton
          status={row.status}
          implementActions={implementActions}
          row={row}
        />
      </TableCell>
    </>
  )
}

export default MemberTableCell
