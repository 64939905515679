import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyle = makeStyles(theme => ({
  menu_list: {
    padding: '16px 40px 16px 24px',
    maxHeight: '50vh'
  },
  menu_item: {
    padding: '8px 40px 8px 24px',
    '& span': {
      fontWeight: 600
    }
  }
}))

const list = [
  {
    id: 'download',
    name: '下載格式'
  },
  {
    id: 'import',
    name: '匯入（批次新增）'
  },
  {
    id: 'export',
    name: '匯出'
  },
  {
    id: 'delete',
    name: '刪除全部選項'
  }
]

function NoteMenuList(props) {
  const {
    open,
    setOpen,
    anchorRef,
    handleMenuListAction,
    onImportExcel,
    fileUploaderRef,
    handleAddOptions,
    ...other
  } = props

  const classes = useStyle()

  function handleClose(event, option) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (event.target.id !== '') {
      handleMenuListAction(event.target.id)
    }
    setOpen()
  }

  return (
    <React.Fragment>
      <Menu
        id="options-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { maxHeight: '50vh' }
        }}
      >
        {list.map(item => (
          <MenuItem
            id={item.id}
            className={classes.menu_item}
            onClick={event => handleClose(event, item)}
          >
            {item.id === 'import' && (
              <input
                type="file"
                id="file"
                ref={fileUploaderRef}
                onChange={e => onImportExcel(e, handleAddOptions)}
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
              />
            )}
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

export default NoteMenuList
