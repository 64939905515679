import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TableRow } from '@material-ui/core'
import MSortTable from '../view/Table/MSortTable'
import MaxWidthForTableCell from '../view/Table/MaxWidthForTableCell'

const useStyle = makeStyles((theme) => ({
  paper: {
    overflow: 'auto',
    padding: theme.spacing(8, 10),
    borderRadius: 10,
    width: '70vw',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
    textAlign: 'start',
  },
  cell: {
    borderBottom: 'none',
    padding: '10px 5px 10px 0',
  },
  row: {
    textDecoration: 'none',
    '& td:first-child': {
      paddingLeft: '16px',
    },
  },
  '@media (max-width:1200px)': {
    paper: {
      padding: theme.spacing(4, 5),
      width: '100%',
    },
  },
}))

function createList(id, role_name, role_content, role_active) {
  return { id, role_name, role_content, role_active }
}

const rows = [
  createList(
    3,
    '管理者',
    '查看所有頁面',
    '查看所包含主管權限。可以更新公司資料、管理群組總預算、設定成員乘車事由、下載金流明細有頁面'
  ),
  createList(
    2,
    '主管',
    '查看部分頁面',
    '包含成員權限。可以管理所屬群組、設定群組預算、單程上限、乘車事由、審核行程'
  ),
  createList(
    1,
    '成員',
    '查看個人頁面',
    '無操作權限。成員在同一公司能隸屬於不同群組'
  ),
]

const headRow = [
  { id: 'role_name', numeric: 'left', label: '職稱', hide: false },
  { id: 'role_content', numeric: 'left', label: '基本權限', hide: false },
  { id: 'role_active', numeric: 'left', label: '操作權限', hide: false },
]

function RoleCard(props) {
  const classes = useStyle()
  const [selected, setSelected] = useState([])
  const isSelected = (name) => selected.indexOf(name) !== -1

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  function printRow(row, index) {
    const isItemSelected = isSelected(row.id)
    return (
      <TableRow
        hover
        role="checkbox"
        className={classes.row}
        aria-checked={isItemSelected}
        tableIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <MaxWidthForTableCell context={row.role_name} minWidth="80px" />
        <MaxWidthForTableCell context={row.role_content} minWidth="80px" />
        <MaxWidthForTableCell context={row.role_active} minWidth="80px" />
      </TableRow>
    )
  }

  return (
    <React.Fragment>
      <div>
        <div className={classes.paper}>
          <Typography variant="h5" className={classes.heading}>
            角色列表
          </Typography>
          <MSortTable
            list={rows}
            headRow={headRow}
            printRow={printRow}
          ></MSortTable>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RoleCard
