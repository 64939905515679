const getCompanyPayment = (transactions) => {
  let company = 0
  let self = 0
  transactions.forEach(({ payment_method, status, amount }) => {
    switch (payment_method) {
      case 'company':
        // 公司付款項目 + 非退款的交易
        ['REFUND', 'CANCELLED', 'PENDING_REFUND'].includes(status)
          ? company -= amount
          : company += amount
        break;
      default:
        ['REFUND', 'CANCELLED', 'PENDING_REFUND'].includes(status)
          ? self -= amount
          : self += amount
        break;
    }
  })

  return {
    company,
    self,
  }
}

export default getCompanyPayment
