import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '25vh',
    marginBottom: 32,
    padding: 24,
  },
  container: {
    overflow: 'scroll',
    height: '100%',
  },
  title: {
    width: 500,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.22,
    color: '#2d2f2e',
    marginBottom: 15,
  },
})

const columns = (data) => [
  { id: 'id', minWidth: 80, value: data.id },
  { id: 'name', minWidth: 120, value: data.name },
  { id: 'quota', minWidth: 100, value: data.quota },
  {
    id: 'member_default_quota',
    minWidth: 100,
    value: data.member_default_quota,
  },
  {
    id: 'member_default_trip_quota',
    minWidth: 100,
    value:
      data.member_default_trip_quota === -1
        ? '無上限'
        : data.member_default_trip_quota,
  },
  {
    id: 'member_default_review_notes',
    minWidth: 100,
    value: Boolean(data.member_default_review_notes) ? '是' : '否',
  },
  // { id: 'error_msg', minWidth: 600, color: '#7a92ad' },
]

export default function UploadErrorTable(props) {
  const classes = useStyles()
  const { msgFile } = props

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.title}>匯入失敗的資料如下，請檢查：</div>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableBody>
            {typeof msgFile !== 'string' ? (
              msgFile.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns(row.data).map((column) => {
                      // const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            color: column.color,
                          }}
                        >
                          {column.value}
                        </TableCell>
                      )
                    })}
                    <TableCell
                      key="error_msg"
                      style={{
                        minWidth: 600,
                        color: '#7a92ad',
                      }}
                    >
                      {row.message}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <div>{msgFile}</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
