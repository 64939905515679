import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  TextField,
  CssBaseline,
  Fab,
  Button,
  Box,
} from '@material-ui/core'
import {
  postResetPassword,
  postResetOtp,
  postVerifyOtp,
  postSignin,
} from '../api'
import ErrorDialog from '../view/Dialog/ErrorDialog'

const useStyles = makeStyles((theme) => ({
  back: {
    backgroundSize: 'cover',
    background: 'rgb(35, 35, 35)',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
  },
  card: {
    width: '350px',
    height: '450px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgb(237, 237, 237)',
  },
  title: {
    margin: '10px',
  },
  pos: {
    marginBottom: 12,
  },
  fab: {
    margin: theme.spacing(1),
    background: 'rgb(45, 45, 45)',
    color: 'white',
    boxShadow: 'none',
    width: '200px',
    '&:hover': {
      background: '#363335',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& label.Mui-focused': {
      color: 'orange',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'orange',
      },
    },
  },
  card_container: {
    textAlign: 'center',
    height: '70%',
  },
  card_action: {
    textAlign: 'center',
    display: 'block',
    // marginTop: '10px'
  },
}))

function Login() {
  const classes = useStyles()
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [entry, setEntry] = useState(false)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [secondPassword, setSecondPassword] = useState('')
  const [stage, setStage] = useState('ENTER_PHONE')
  const [hint, setHint] = useState('none')
  const [emptyHint, setEmptyHint] = useState('none')
  const [openDialog, setOpenDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const uuid = require('uuid')
    const id = uuid.v4()
    if (!localStorage.getItem('uuid')) {
      localStorage.setItem('uuid', id)
    }
    return () => {}
  }, [])

  useEffect(() => {
    document
      .getElementById('login-form')
      .addEventListener('keyup', function (event) {
        event.preventDefault()
        if (event.key === 'Enter') {
          console.log(11)
          document.getElementById('sign-in').click()
        }
      })
    return () => {}
  }, [])

  async function requestOtp() {
    console.log('000')
    const body = {
      phone: phone,
      uuid: localStorage.getItem('uuid'),
    }
    const data = await postResetOtp(body)
    if (data.status !== 200) return alert('發生錯誤，請稍後再試。') // error msg 會回沒有司機端的相關資訊，故不套用
    setStage('ENTER_OTP')
  }

  async function verifyOtp() {
    const body = {
      phone: phone,
      uuid: localStorage.getItem('uuid'),
      otp: otp,
    }
    const data = await postVerifyOtp(body)
    if (data.status !== 200) return alert(data.data.error.msg)
    setStage('ENTER_PASSWORD')
  }

  async function resetPassword() {
    if (secondPassword !== password) {
      setHint('')
      return
    } else {
      setHint('none')
    }
    if (secondPassword === '' || password === '') {
      setEmptyHint('')
      return
    } else {
      setEmptyHint('none')
    }

    const body = {
      phone: phone,
      uuid: localStorage.getItem('uuid'),
      password: password,
    }
    const data = await postResetPassword(body)
    if (data.status !== 200) return handleDialog(data.data.error.msg)
    setEntry(false)
    setStage('ENTER_PHONE')
    setPassword('')
    setSecondPassword('')
  }

  async function signin() {
    const body = {
      account: company,
      email: email,
      password: password,
    }
    const data = await postSignin(body)
    switch (data.status) {
      case 200:
        window.location.reload()
        break
      case 401:
        alert('登入失敗，請檢查帳號密碼是正確')
        break
      case 488:
        alert('封鎖或未驗證，請洽公司管理員')
        break
      default:
        console.log(JSON.stringify(data))
        alert('登入失敗，請稍後再試。')
        break
    }
  }

  function handleDialog(msg) {
    setOpenDialog(!openDialog)
    setErrorMsg(msg)
  }

  function backToEntry() {
    setEntry(false)
    setStage('ENTER_PHONE')
  }

  function renderResetFlow() {
    const form = []
    switch (stage) {
      case 'ENTER_PHONE':
        form.push(
          <>
            <TextField
              id="outlined-phone-input"
              label="輸入手機號碼"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <div>請輸入註冊LINE TAXI會員時使用的手機號碼</div>
          </>
        )
        return form
      case 'ENTER_OTP':
        form.push(
          <>
            <TextField
              id="outlined-otp-input"
              label="輸入驗證碼"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <div>
              您將收到LINE
              TAXI寄發的手機簡訊，請將內含的驗證碼輸入。若您無法收到簡訊，請檢查輸入的註冊手機號碼是否正確，或與客服單位聯繫。
            </div>
          </>
        )
        return form
      case 'ENTER_PASSWORD':
        form.push(
          <>
            <TextField
              id="outlined-password-input"
              label="設定新密碼"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              id="outlined-password-input"
              label="再次輸入新密碼"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={secondPassword}
              type="password"
              onChange={(e) => setSecondPassword(e.target.value)}
            />
            <p style={{ display: `${hint}`, color: '#e32e28' }}>
              密碼輸入不一致，請再次確認。
            </p>
            <p style={{ display: `${emptyHint}`, color: '#e32e28' }}>
              密碼欄請勿空白。
            </p>
          </>
        )
        return form
      default:
        return
    }
  }

  function handleConfirm() {
    switch (stage) {
      case 'ENTER_PHONE':
        return requestOtp()
      case 'ENTER_OTP':
        return verifyOtp()
      case 'ENTER_PASSWORD':
        return resetPassword()
      default:
        return
    }
  }

  return (
    <div id="login">
      <CssBaseline />
      <div className={classes.back}>
        <Card className={classes.card}>
          {entry ? (
            <React.Fragment>
              <CardContent className={classes.card_container}>
                <Typography className={classes.title} variant="h5">
                  LINE TAXI 企業簽單
                </Typography>
                <form id="reset-password">{renderResetFlow()}</form>
              </CardContent>
              <CardActions className={classes.card_action}>
                <div>
                  <Fab
                    id="reset-confirm"
                    variant="extended"
                    aria-label="confirm"
                    className={classes.fab}
                    onClick={() => handleConfirm()}
                  >
                    確認
                  </Fab>
                </div>
                <div>
                  <Button onClick={() => backToEntry()}>已有帳戶登入</Button>
                </div>
              </CardActions>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CardContent className={classes.card_container}>
                <Typography className={classes.title} variant="h5">
                  LINE GO for Business
                </Typography>
                <form
                  id="login-form"
                  className={classes.container}
                  autoComplete="on"
                  noValidate
                >
                  <TextField
                    id="outlined-corp-input"
                    label="公司帳號"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <TextField
                    id="outlined-email-input"
                    label="信箱"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    id="outlined-password-input"
                    label="密碼"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </form>
              </CardContent>
              <CardActions className={classes.card_action}>
                <div>
                  <Fab
                    id="sign-in"
                    variant="extended"
                    aria-label="signin"
                    className={classes.fab}
                    onClick={(e) => signin(e)}
                  >
                    登入
                  </Fab>
                </div>
                <div>
                  <Button onClick={() => setEntry(true)}>忘記密碼</Button>
                </div>
              </CardActions>
            </React.Fragment>
          )}
        </Card>
      </div>
      <ErrorDialog
        open={openDialog}
        handleClose={handleDialog}
        message={errorMsg}
      />
    </div>
  )
}

export default Login
