import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const styledBy = (property, mapping) => props => mapping[props[property]]

const useStyles = makeStyles({
  progress_bar: {
    background: 'lightgray',
    height: '3px',
    marginBottom: '12px'
  },
  progress: {
    background: '#ffbe5a',
    height: '3px'
  }
})

function QutaProcessBar(props) {
  const { summary, quta } = props
  const classes = useStyles()

  function renderProgress() {
    if (summary >= quta) {
      return (
        <div className={classes.progress_bar}>
          <div className={classes.progress} style={{ width: `100%` }}></div>
        </div>
      )
    } else {
      return (
        <div className={classes.progress_bar}>
          <div
            className={classes.progress}
            style={{ width: `${((summary || 0) / quta) * 100}%` }}
          ></div>
        </div>
      )
    }
  }

  return renderProgress()
}

QutaProcessBar.propTypes = {
  summary: PropTypes.number.isRequired,
  quta: PropTypes.number.isRequired
}

export default QutaProcessBar
