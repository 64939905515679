/* eslint-disable no-new-object */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { cloneDeep } from 'lodash'
import parsePhoneNumber from 'libphonenumber-js'
import { getData } from '../router/index'
import { CorpContext } from '../reducers/Main'
import {
  getAllMember,
  getMemberFilter,
  putUsersUnit,
  putReviewNotes,
  postEmailVerify,
  postCreateUsers,
  postUserPermission,
  deleteUserPermission,
  deleteUsersUnit,
} from '../api'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TableRow, CircularProgress, TablePagination } from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import IconButton from '../view/Buttons/IconButton'
import FilterButtonWithClear from '../view/Buttons/FilterButtonWithClear'
import EditTableCell from '../view/Table/EditTableCell'
import DownloadAddUserFormatTable from '../view/Buttons/DownloadAddUserSample'
import ExportUserDetail from '../view/Buttons/ExportUserDetail'
import ExportButtonModel from '../view/Buttons/ExportButtonModel'
import MSortTable from '../view/Table/MSortTable'
import Toast from '../view/Pop/Toast'
import CreateMemberForm from '../view/Form/CreateMemberForm'
import MemberTableCell from '../view/Table/MemberTableCell'
import FilterButtonWithAuto from '../view/Buttons/FilterButtonWithAuto'
import UploadModal from '../view/Modal/UploadModal'
import { generalStyle } from '../styles'
import { handleSort } from '../utils'

const useStyle = makeStyles((theme) => ({
  memberContainer: {
    overflow: 'auto',
    height: '100vh',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  loading: {
    color: '#797979',
  },
}))

const headRow = [
  { id: 'name', numeric: 'left', label: '姓名', hide: false },
  { id: 'phone_number', numeric: 'left', label: '手機號碼', hide: false },
  { id: 'email', numeric: 'left', label: '信箱', hide: false },
  { id: 'permission', numeric: 'left', label: '權限', hide: false },
  { id: 'status', numeric: 'left', label: '狀態', hide: false },
  { id: 'action', numeric: 'center', label: '個人設定', hide: false },
]

const statusOptions = [
  { id: 'PENDING', name: 'PENDING'.userStatusLocalized() },
  { id: 'ACCEPTED', name: 'ACCEPTED'.userStatusLocalized() },
  { id: 'BLOCKED', name: 'BLOCKED'.userStatusLocalized() },
]

function Member() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const { state, dispatch } = useContext(CorpContext)

  const [memberList, setMemberList] = useState([])

  const [member_f, setMember] = useState('所有成員')
  const [member_id, setMemberID] = useState(0)
  const [memberFilterList, setMemberFilterList] = useState([])

  const [status_f, setStatus] = useState('帳號狀態')

  const [selected, setSelected] = useState([])
  const isSelected = (name) => selected.indexOf(name) !== -1
  const createRef = useRef('none')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [editRow, setEditRow] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [timeoutID, setTimeoutID] = useState(undefined)
  const [company, setCompany] = useState(null)

  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    setMemberFilterList(handleSort(state.all_member, 'asc', 'name'))
    setCompany(state.company[0].id)
  }, [state.all_member, state.company])

  useEffect(() => {
    realtimeSearch(true)
    return () => {}
  }, [member_f, status_f])

  async function fetchAllMember(query_url = '', resetPage = false) {
    const data = await getAllMember(query_url)
    if (data.status !== 200) return handleAlertBar('搜尋成員失敗，請稍後再試。')
    const allUsers = data.data.data.all_users

    setLoading(false)
    setMemberList(allUsers)
    resetPage && setPage(0)
  }

  async function createUsers(users) {
    const data = await postCreateUsers(users)
    if (data.status !== 200) {
      alert(data.data.msg)
    } else {
      if (data.data.msg && data.data.msg[0]) {
        return handleAlertBar(data.data.msg[0].error_msg, 'error')
      }
      updateAllMember()
      handleAlertBar('新增成員成功')
      realtimeSearch()
    }
  }

  async function updateAllMember() {
    const data = await getAllMember('')
    if (data.status !== 200) return handleAlertBar('搜尋成員失敗，請稍後再試。')
    const allUsers = data.data.data.all_users
    dispatch({ type: 'UPDATE_ALL_MEMBER', all_member: allUsers })
  }

  function realtimeSearch(resetPage) {
    const usersOject = new Object()
    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      usersOject.id = member_id
    } else {
      delete usersOject.id
    }
    if (status_f !== '帳號狀態') {
      usersOject.status = status_f.userStatusPreType()
    } else {
      delete usersOject.status
    }

    const queryString = []
    for (const key in usersOject) {
      queryString.push(`${key}:"${encodeURIComponent(usersOject[key])}"`)
    }
    console.log('usersOject' + query(queryString))

    fetchAllMember(query(queryString), resetPage)
  }

  function query(arr) {
    if (!arr.length) return ''
    return `(${arr.join(',')})`
  }

  async function updateMember(setEditRow, id, body, realtimeSearch) {
    const data = await putUsersUnit(id, body)
    const { message } = data.data
    if (data.status !== 200) return handleAlertBar(message, 'error')
    setEditRow('')
    handleAlertBar('更新成員成功')
    updateAllMember()
    realtimeSearch()
  }

  async function sendVerifyMail(id) {
    const body = {
      id,
    }
    const data = await postEmailVerify(body)
    if (data.status !== 200) return handleAlertBar('認證信送出失敗', 'error')
    handleAlertBar('認證信已送出')
  }

  async function handleDeleteMember(row, realtimeSearch) {
    const body = { status: 'BLOCKED' }
    const data = await deleteUsersUnit(row.id, body)
    if (data.status !== 200) return handleAlertBar('封鎖成員失敗，請稍後再試。', 'error')
    handleAlertBar('封鎖成員成功。')
    updateAllMember()
    realtimeSearch()
  }

  function handleOpenModal() {
    setOpen((prevOpen) => !prevOpen)
  }

  function implementActions(action, row) {
    switch (action) {
      case '發送驗證信':
        sendVerifyMail(row.id)
        break
      case '編輯':
        setEditRow(row)
        break
      case '封鎖':
        handleDeleteMember(row, realtimeSearch)
        break
      case '解除封鎖':
        const body = { status: 'PENDING' }
        updateMember(setEditRow, row.id, body, realtimeSearch)
        break
      case '取消管理員身份':
        handleUpdatePermission(deleteUserPermission, row)
        break
      case '設為管理員':
        handleUpdatePermission(postUserPermission, row)
        break
      default:
        break
    }
  }

  async function handleUpdatePermission(update, row) {
    const body = { permission: 'ADMIN' }
    const data = await update(row.id, body)
    if (data.status !== 200) return handleAlertBar('更新成員失敗，請稍後再試。', 'error')
    handleAlertBar('更新成員權限成功')
    realtimeSearch()
  }

  function addSearch(field, option) {
    switch (field) {
      case 'member':
        setMember(option.name)
        setMemberID(option.id)
        break
      case 'status':
        setStatus(option.name ? option.name : option)
        break
      default:
        return
    }
  }

  function triggerClearSearch() {
    setMember('所有成員')
    setMemberID(0)
    setStatus('帳號狀態')
  }

  async function updateReviewNotes(e, user, updateSwitch) {
    const checked = e.target.checked
    const updateMember = cloneDeep(memberList)
    updateSwitch(checked)
    updateMember.map((row) => {
      if (user.user_id === row.user_id) {
        row.review_notes = checked
      }
      return row
    })
    const body = { review_notes: checked }
    const data = await putReviewNotes(user.company, user.id, body)
    if (data.status !== 200) {
      clearTimeout(timeoutID)
      setShowToast(true)
      updateSwitch(!checked)
      const currentimeoutID = setTimeout(() => {
        setShowToast(false)
      }, 3000)
      setTimeoutID(currentimeoutID)
      return
    }
    dispatch({ type: 'UPDATE_MEMBER', member: updateMember })
  }

  function editOnClick(values) {
    const { name, email, phone_number } = values
    if (name === '') return handleAlertBar('欄位請勿空白或未選擇。', 'error')
    if (!Boolean(email) && !Boolean(phone_number)) {
      handleAlertBar('欄位請勿空白或未選擇，手機與信箱須擇一填寫。', 'error')
      return
    }
    const body = {
      name: name,
    }

    if (email === '' || !Boolean(email)) {
      body.email = ''
    } else {
      body.email = email
    }

    if (phone_number === '' || !Boolean(phone_number)) {
      body.cc = ''
      body.phone_number = ''
    } else if (Boolean(phone_number)) {
      const phoneNumber = parsePhoneNumber(phone_number, 'TW') // 要 handle 0 的狀態
      if (phoneNumber.isValid()) {
        body.cc = `+${phoneNumber.countryCallingCode}`
        body.phone_number = phoneNumber.formatNational().replace(/\s/g, '')
      } else {
        return handleAlertBar('請確認手機號碼符合格式', 'error')
      }
    }
    updateMember(setEditRow, editRow.id, body, realtimeSearch)
  }

  function editCancel() {
    setEditRow('')
  }

  function renderEditRow(row, index) {
    if (row.name === editRow.name && row.id === editRow.id) {
      return (
        <EditTableCell row={row} departments={state.groups} editOnClick={editOnClick} editCancel={editCancel} updateReviewNotes={updateReviewNotes} />
      )
    } else {
      return (
        <MemberTableCell
          key={`${index}-${row.name}`}
          row={row}
          implementActions={implementActions}
          updateReviewNotes={updateReviewNotes}
          company={company}
        />
      )
    }
  }

  function handleCreateUser(values) {
    const { name, email, phone_number, group } = values
    if (name === '' || (email === '' && phone_number === '')) {
      handleAlertBar('請確認欄位皆已填妥。', 'error')
      return
    }
    const user = {
      name,
      email,
    }
    const phoneNumber = parsePhoneNumber(phone_number, 'TW')
    if (phoneNumber) {
      if (phoneNumber.isValid()) {
        user.cc = `+${phoneNumber.countryCallingCode}`
        user.phone_number = phoneNumber.formatNational().replace(/\s/g, '')
      } else {
        return handleAlertBar('請確認手機號碼符合格式', 'error')
      }
    } else if (phone_number === '') {
      user.cc = ''
      user.phone_number = phone_number
    } else {
      return handleAlertBar('請確認手機號碼符合格式', 'error')
    }
    if (group.id) {
      user.group = group.id
    }
    createUsers(user)
  }

  function clickCreateButton() {
    if (createRef.current.style.display === 'none') {
      createRef.current.style.display = ''
    } else if (createRef.current.style.display === '') {
      createRef.current.style.display = 'none'
    }
  }

  function printRow(row, index) {
    const isItemSelected = isSelected(row.id)
    return (
      <TableRow
        hover
        role="checkbox"
        className={generalClasses.row}
        aria-checked={isItemSelected}
        tableIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        {renderEditRow(row, index)}
      </TableRow>
    )
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  return (
    <React.Fragment>
      <div id="member_container" className={classes.memberContainer}>
        <Toast context="發生錯誤，請稍後再試" status={showToast} />
        <div className={generalClasses.toolbar} />
        <div className={generalClasses.paper}>
          <Typography variant="h5" className={generalClasses.heading}>
            所有成員管理 (成員數 {memberFilterList.length} 人)
          </Typography>
          <Grid container justify="space-between" alignItems="baseline">
            <Grid item>
              <IconButton color="green" title="新增" icon="add_circle" onClick={clickCreateButton} />
            </Grid>
            <Grid item style={{ paddingTop: '3.5px' }}>
              {/* <DownloadAddUserFormatTable /> */}
              <ExportButtonModel icon="icon-06_匯入.svg" title="匯入" type="left" onClick={handleOpenModal} />
              <ExportUserDetail memberList={memberList} />
            </Grid>
          </Grid>
          <CreateMemberForm
            state={state}
            memberList={memberList}
            createRef={createRef}
            handleCreateUser={handleCreateUser}
            clickCreateButton={clickCreateButton}
          />
          <div className={generalClasses.filter}>
            <Grid container justify="space-between">
              <Grid item>
                <FilterButtonWithClear
                  title={member_f}
                  default_title="所有成員"
                  list_header="選擇成員"
                  options={memberFilterList}
                  field="member"
                  addSearch={addSearch}
                />
                <FilterButtonWithClear
                  isAuto={false}
                  title={status_f}
                  default_title="帳號狀態"
                  list_header="選擇狀態"
                  options={statusOptions}
                  field="status"
                  addSearch={addSearch}
                />
              </Grid>
              <Grid item>
                <BasicButton color="#ffffff" background="#4b4d4c" hoverColor="black" title="清除搜尋條件" onClick={triggerClearSearch} />
              </Grid>
            </Grid>
          </div>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          ) : (
            <MSortTable loadmore list={memberList} headRow={headRow} printRow={printRow} page={page} rowsPerPage={rowsPerPage}></MSortTable>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={memberList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <UploadModal
        handleOpenModal={handleOpenModal}
        open={open}
        // createUsers={createUsers}
        state={state}
        updateAllMember={updateAllMember}
        realtimeSearch={realtimeSearch}
      />
    </React.Fragment>
  )
}

export default Member
