import React, { useState, useEffect, useRef } from 'react'
import ExcelJS from 'exceljs'
import { remove, pullAt, cloneDeep, isEmpty } from 'lodash'
import { patchCustomField } from '../../api'
import { makeStyles } from '@material-ui/core/styles'
import {
  Modal,
  Backdrop,
  Fade,
  RadioGroup,
  FormControlLabel,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { ExportNoteOptions } from '../../view/Buttons/ExportNoteOptions'
import { DownloadOptionSample } from '../../view/Buttons/DownloadOptionSample'
import { ImportNoteOptions } from '../../view/Buttons/ImportNoteOptions'
import BasicRadioButton from '../Buttons/BasicRadioButton'
import NoteMenuList from '../Menu/NoteMenuList'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#e8e8e8',
    padding: '48px 48px 80px 48px',
    borderRadius: '8px',
    width: '880px',
    height: '85vh',
    overflow: 'scroll',
    position: 'relative',
  },
  container: {
    height: '100%',
    overflow: 'scroll',
    paddingBottom: '70px',
  },
  footerControl: {
    position: 'absolute',
    bottom: '30px',
    left: 0,
    padding: '0 35px 0 48px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divider: {
    marginBottom: '23px',
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  redBtn: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.38,
    color: '#ffffff',
    borderRadius: '8px',
    backgroundColor: '#ec4d4d',
    padding: '6px 16px',
    cursor: 'pointer',
    opacity: 0,
  },
  blackBtn: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.38,
    color: '#ffffff',
    borderRadius: '8px',
    backgroundColor: '#4b4d4c',
    padding: '6px 16px',
    cursor: 'pointer',
  },
  basicBtn: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.38,
    color: '#4b4d4c',
    borderRadius: '8px',
    marginLeft: '5px',
    padding: '6px 16px',
    cursor: 'pointer',
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: 1.23,
    color: '#1e201f',
    marginBottom: '24px',
  },
  inputWrap: {
    width: '60%',
    position: 'relative',
    marginBottom: '40px',
    '& input:disabled': {
      backgroundColor: '#d2d2d2',
    },
  },
  optionInputWrap: {
    width: '60%',
    position: 'relative',
    marginBottom: '8px',
  },
  input: {
    width: '100%',
    height: '43px',
    padding: '12px 20px',
    margin: '8px 0',
    border: 'unset',
    borderRadius: '24px',
    outline: 'none',
    paddingRight: '70px',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555',
    },
    color: '#1e201f',
    fontSize: '14px',
    '&:placeholder': {
      color: '#bbbcbb',
    },
  },
  limit: {
    position: 'absolute',
    bottom: '24px',
    right: '20px',
    color: '#a5a6a5',
    fontSize: '12px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '1.22',
    color: '#1c1c1e',
    marginBottom: '16px',
  },
  radioLabel: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#4b4d4c',
  },
  radioGroup: {
    flexDirection: 'row',
    marginBottom: '40px',
    marginLeft: '2px',
  },
  radio: {
    marginRight: '32px',
  },
  removeIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    top: '18px',
    right: '-35px',
    cursor: 'pointer',
  },
  addControl: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    cursor: 'pointer',
  },
  addIcon: {
    marginRight: '6px',
    width: '16px',
    height: '16px',
  },
  add: {
    fontSize: '12px',
    lineHeight: '1.5',
    color: '#00cba0',
  },
  show: {
    opacity: 1,
  },
  dialogPaper: {
    minWidth: '305px',
  },
  grayBtn: {
    color: '#797979',
  },
  titleWrap: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionControl: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '1.43',
    color: '#4b4d4c',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    cursor: 'pointer',
  },
  blockEvent: {
    pointerEvents: 'none',
  },
}))

const NoteForm = ({
  type,
  title,
  content = '',
  placeholder,
  modalType,
  handleInputChange,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.noteForm}>
      <div className={classes.title}>{title}</div>
      <div className={classes.inputWrap}>
        <input
          className={classes.input}
          type="text"
          id={title}
          placeholder={placeholder}
          value={content}
          onChange={(e) => handleInputChange(e, type)}
          disabled={modalType === 'view' && type === 'title'}
        />
        {type !== 'title' && (
          <div className={classes.limit}>{content.length}/15</div>
        )}
      </div>
    </div>
  )
}

const OptionInput = ({
  option,
  index,
  modalType,
  noteOptions,
  handleRemoveOptions,
  handleOptionInputChange,
}) => {
  const classes = useStyles()
  const autoGrow = (element) => {
    element.target.style.height = '43px'
    element.target.style.height = `${element.target.scrollHeight}px`
  }
  return (
    <div className={`${classes.optionInputWrap} `}>
      <textarea
        className={classes.input}
        type="text"
        id={index}
        placeholder="選項名稱"
        value={option.option}
        style={{ resize: 'none' }}
        onChange={(e) => {
          autoGrow(e)
          handleOptionInputChange(e, index)
        }}
        onFocus={autoGrow}
      />
      <div className={classes.limit}>{option.option.length}/60</div>
      {noteOptions.length > 1 && (
        <img
          className={classes.removeIcon}
          onClick={() => handleRemoveOptions(index)}
          src="https://static.taxigo-cdn.com/icons/business/remove-option.svg"
        />
      )}
    </div>
  )
}

const ErrorDialog = ({
  open,
  title,
  content,
  color = 'primary',
  confirmText,
  cancelText,
  handleConfirm,
  handleClose,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent id="alert-dialog-title">{content}</DialogContent>
      <DialogActions>
        {confirmText && (
          <Button onClick={() => handleConfirm()} color={color}>
            {confirmText}
          </Button>
        )}
        {cancelText && (
          <Button onClick={() => handleClose()} className={classes.grayBtn}>
            {cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

function EditNoteModal(props) {
  const {
    open,
    editRow,
    modalType,
    company,
    handleOpenModal,
    handlePatchField,
    handleDeleteField,
  } = props
  const classes = useStyles()
  const [noteForm, setNoteForm] = useState({ title: '備註', description: '' })
  const [noteType, setNoteType] = useState('text')
  const [noteOptions, setNoteOptions] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openMenuList, setOpenMenuList] = useState(false)
  const [errorMsg, setErrorMsg] = useState({})
  const anchorRef = React.useRef(null)
  const fileUploaderRef = useRef()

  useEffect(() => {
    const title =
      modalType === 'copy' ? `(複製) ${editRow.title}` : editRow.title || '備註'
    const description =
      modalType === 'copy'
        ? `(複製) ${editRow.description || ''}`
        : editRow.description || ''
    setNoteForm({
      title: title.substring(0, 15),
      description: description.substring(0, 15),
    })
    setNoteType(editRow.type || 'text')
    setNoteOptions(editRow.options || [])

    return () => {}
  }, [editRow])

  useEffect(() => {
    if (!openDialog) {
      setErrorMsg({})
    }
    return () => {}
  }, [openDialog])

  function handleToggleMenuList() {
    setOpenMenuList((prevOpen) => !prevOpen)
  }

  function handleMenuListAction(item) {
    switch (item) {
      case 'download':
        DownloadOptionSample()
        break
      case 'import':
        fileUploaderRef.current.click()
        break
      case 'export':
        ExportNoteOptions(noteOptions)
        break
      case 'delete':
        setNoteOptions([])
        break
      default:
        break
    }
  }

  function handleInputChange(e, name) {
    const { value } = e.target
    if (value.length > 15) return
    setNoteForm({ ...noteForm, [name]: value })
  }

  function handleOptionInputChange(e, index) {
    const { value } = e.target
    if (value.length > 60) return
    const _noteOptions = cloneDeep(noteOptions)
    _noteOptions[index].option = e.target.value
    setNoteOptions(_noteOptions)
  }

  function handleRadioChange(e) {
    setNoteType(e.target.value)
  }

  function handleAddOptions(options = { option: '' }) {
    setNoteOptions((prev) => prev.concat(options))
  }

  function handleRemoveOptions(index) {
    const _noteOptions = cloneDeep(noteOptions)
    _noteOptions.splice(index, 1)
    setNoteOptions(_noteOptions)
  }

  function handleButton() {
    let confirm
    let cancel
    switch (modalType) {
      case 'new':
      case 'copy':
        confirm = '新增'
        cancel = '取消'
        break
      case 'view':
        confirm = '儲存'
        cancel = '關閉'
        break
      default:
        confirm = '---'
        cancel = '---'
        break
    }
    return { confirm, cancel }
  }

  function handleUpdateNote() {
    if (!noteForm.title) {
      setOpenDialog(true)
      setErrorMsg({
        title: '無法新增！',
        content: '請填入標題名稱',
        confirmText: '我知道了',
        handleConfirm: () => setOpenDialog(false),
      })
      return
    }
    const body = {
      company: company.id,
      updatingFields: [
        {
          type: noteType,
          title: noteForm.title,
          description: noteForm.description,
        },
      ],
    }
    if (modalType === 'view') {
      body['updatingFields'][0]['id'] = editRow.id
      body['updatingFields'][0]['active'] = editRow.active
    }
    if (noteType === 'dropdown') {
      const _noteOptions = noteOptions
        .filter((item) => item.option !== '')
        .map((item, index) => {
          return { option_index: index, option: item.option }
        })
      if (isEmpty(_noteOptions)) {
        setOpenDialog(true)
        setErrorMsg({
          title: '無法新增！',
          content: '請填入選項名稱',
          confirmText: '我知道了',
          handleConfirm: () => setOpenDialog(false),
        })
        return
      }
      body['updatingFields'][0]['options'] = _noteOptions
    }
    handlePatchField(body, handleOpenModal)
  }

  function handleDeleteNote(id) {
    setOpenDialog(true)
    setErrorMsg({
      title: '確認要刪除此備註嗎？',
      content: '提醒您，刪除後將無法回復。',
      confirmText: '確認刪除',
      cancelText: '取消',
      color: 'secondary',
      handleConfirm: () =>
        handleDeleteField(id, () => {
          setOpenDialog(false)
          handleOpenModal()
        }),
    })
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.container}>
              <div className={classes.modalTitle}>行程備註</div>
              <NoteForm
                type="title"
                title="自訂標題"
                modalType={modalType}
                content={noteForm.title}
                placeholder="請填入備註標題"
                handleInputChange={handleInputChange}
              />
              <NoteForm
                type="description"
                title="描述（選填）"
                modalType={modalType}
                content={noteForm.description}
                placeholder="引導同仁填入正確備註"
                handleInputChange={handleInputChange}
              />
              <div className={classes.title}>備註形式</div>
              <RadioGroup
                className={classes.radioGroup}
                aria-label="quiz"
                name="quiz"
                value={noteType}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="text"
                  control={<BasicRadioButton />}
                  label="自行輸入文字"
                  classes={{ label: classes.radioLabel }}
                  className={classes.radio}
                />
                <FormControlLabel
                  value="dropdown"
                  control={<BasicRadioButton />}
                  label="下拉式選單"
                  classes={{ label: classes.radioLabel }}
                />
              </RadioGroup>
              {noteType === 'dropdown' && (
                <div
                // className={`${modalType === 'view' && classes.blockEvent}`}
                >
                  <div className={classes.titleWrap}>
                    <div className={classes.title}>下拉選項編輯</div>
                    <div
                      className={classes.optionControl}
                      ref={anchorRef}
                      onClick={handleToggleMenuList}
                    >
                      批次操作
                      <img
                        className={classes.addIcon}
                        src="https://static.taxigo-cdn.com/icons/general/arrow-down.svg"
                      />
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                  {noteOptions.map((item, index) => {
                    return (
                      <OptionInput
                        option={item}
                        index={index}
                        modalType={modalType}
                        noteOptions={noteOptions}
                        handleRemoveOptions={handleRemoveOptions}
                        handleOptionInputChange={handleOptionInputChange}
                      />
                    )
                  })}
                  <div
                    className={classes.addControl}
                    onClick={() => handleAddOptions()}
                  >
                    <img
                      className={classes.addIcon}
                      src="https://static.taxigo-cdn.com/icons/business/add-green.svg"
                    />
                    <div className={classes.add}>新增選項</div>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.footerControl}>
              <div
                className={`${classes.redBtn} ${
                  editRow.active === 0 && modalType === 'view' && classes.show
                }`}
                onClick={() => handleDeleteNote(editRow.id)}
              >
                刪除備註
              </div>
              <div className={classes.btnWrap}>
                <div className={classes.blackBtn} onClick={handleUpdateNote}>
                  {handleButton().confirm}
                </div>
                <div className={classes.basicBtn} onClick={handleOpenModal}>
                  {handleButton().cancel}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <NoteMenuList
        open={openMenuList}
        anchorRef={anchorRef}
        setOpen={handleToggleMenuList}
        fileUploaderRef={fileUploaderRef}
        onImportExcel={ImportNoteOptions}
        handleAddOptions={handleAddOptions}
        handleMenuListAction={handleMenuListAction}
      />
      <ErrorDialog
        open={openDialog}
        title={errorMsg.title}
        color={errorMsg.color}
        content={errorMsg.content}
        confirmText={errorMsg.confirmText}
        cancelText={errorMsg.cancelText}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={errorMsg.handleConfirm}
      />
    </>
  )
}

export default EditNoteModal
