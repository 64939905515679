import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'
import { createModel } from '../../../utils'
import { getCompanyBillRecipients, putCompanyBillRecipients } from '../../../api'
import AddMemberPopover from './AddMemberPopover'
import PopUp from '../../../view/PopUp'

const Root = styled.div`
  padding: 56px 60px 24px 60px;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  height: 80px;
`
const Title = styled.div`
  color: #797979;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
`
const Button = styled.button`
  padding: 8px 16px;
  border-radius: 2px;
  background-color: #4b4d4c;
  color: #fff;
  margin-left: 10px;
  flex-shrink: 0;
  height: 40px;
`
const FromWrapper = styled.div`
  color: #1e201f;
  margin: 11px 0;
`
const FromTextWrapper = styled.div`
  display: flex;
  position: relative;
  height: 50px;
  align-items: center;
  user-select: none;
  ${(props) =>
    props.$checkbox &&
    !props.$isDefault &&
    `
    &::before {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      border: 1px solid #bbbcbb;
      margin-right: 31px;
      margin-left: 7px;
      cursor: pointer;
    }
  `}
  ${(props) =>
    props.$checkbox &&
    !props.$isDefault &&
    props.$isActive &&
    `
    &::before {
      background-color: #e6f2ff;
    }
    &::after {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      background: url(https://static.taxigo-cdn.com/icons/business/checkbox.svg) center/cover no-repeat;
      position: absolute;
      margin-left: 7px;
      cursor: pointer;
    }
  `}
  ${(props) =>
    props.$checkbox &&
    props.$isDefault &&
    `
    &::before {
      content: '';
      display: inline-block;
      margin-right: 38px;
      height: 16px;
      width: 16px;
    }
  `}
  ${(props) =>
    props.$isDefault &&
    `
      border-bottom: 1px solid #e8e8e8;
      `}
  ${(props) =>
    !props.$isDefault &&
    !props.$isTitle &&
    `
      cursor: pointer;
      &:hover, &:active {
        background-color: #f2f4f7;
      }`}
`
const FromText = styled.div`
  color: #1e201f;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  flex: 1;
  ${(props) =>
    props.$isDefault
      ? `
      color: #a5a6a5;
      position: absolute;
      right: 0;`
      : ''}
`
const From = styled.div`
  border-top: 1px solid #e8e8e8;
  ${(props) => props.$borderBottom && `border-bottom: 1px solid #e8e8e8;`}
`
const DeleteButton = styled.button`
  padding: 8px 16px;
  border-radius: 2px;
  background-color: #f5f5f8;
  color: #4b4d4c;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
`

const ReceiptSection = React.memo(({ company }) => {
  const [displayPopover, setDisplayPopover] = useState(false)
  const [billRecipients, setBillRecipients] = useState([])

  const handleOnClick = (id) => {
    const data = billRecipients.map((item) => ({ ...item, active: item.id === id ? !item.active : item.active }))
    setBillRecipients(data)
  }
  const selectedAllMembers = () => {
    const selected = billRecipients.every((item) => item.active)
    const data = billRecipients.map((item) => ({ ...item, active: !selected }))
    setBillRecipients(data)
  }
  const canDisplayDeleteButton = useMemo(() => {
    return billRecipients.some((item) => item.active)
  }, [billRecipients])
  const getBillRecipients = useCallback(async () => {
    const params = { company: company[0].id }
    const data = await getCompanyBillRecipients(params)
    if (data.status !== 200) return createModel(<PopUp warning="發生錯誤，請稍後再嘗試" textCenter />)
    const billRecipients = data.data.map((item) => ({ ...item, active: false }))
    setBillRecipients(billRecipients)
  }, [])
  const deleteBillRecipients = async () => {
    const filterBillRecipients = billRecipients.filter((item) => !item.active)
    const data = filterBillRecipients.map((item) => item.id)
    const body = { company: company[0].id, updatedList: data }
    const response = await putCompanyBillRecipients(body)
    if (response.status !== 200) return createModel(<PopUp textCenter warning="發生錯誤，請稍後嘗試" />)
    setBillRecipients(filterBillRecipients)
  }
  useEffect(() => {
    isEmpty(billRecipients) && getBillRecipients()
  }, [getBillRecipients])
  return (
    <>
      {displayPopover && (
        <AddMemberPopover
          setDisplayPopover={setDisplayPopover}
          company={company}
          billRecipients={billRecipients}
          getBillRecipients={getBillRecipients}
        />
      )}
      <Root>
        <TitleWrapper>
          {canDisplayDeleteButton ? (
            <DeleteButton onClick={() => createModel(<PopUp warning="確定要刪除嗎？" textCenter confirm={deleteBillRecipients} />)}>
              刪除收件人
            </DeleteButton>
          ) : (
            <>
              <Title>系統將於每月5號，寄送代收轉付收據電子檔與電子發票開立通知至下列收件人信箱，你可在此新增或刪除收件人名單</Title>
              <Button onClick={() => setDisplayPopover(true)}>新增收件人</Button>
            </>
          )}
        </TitleWrapper>
        <FromWrapper>
          <FromTextWrapper $isTitle $checkbox={!isEmpty(billRecipients)} $isActive={billRecipients.every((item) => item.active)} onClick={selectedAllMembers}>
            <FromText>收件人名稱</FromText>
            <FromText>信箱</FromText>
          </FromTextWrapper>
          <From $borderBottom={!isEmpty(billRecipients)}>
            <FromTextWrapper $isDefault $checkbox={!isEmpty(billRecipients)}>
              <FromText>{company[0].name}</FromText>
              <FromText>{company[0].email}</FromText>
              <FromText $isDefault>預設信箱</FromText>
            </FromTextWrapper>
            {billRecipients.map((item) => (
              <FromTextWrapper $checkbox $isActive={item.active} key={item.id} onClick={() => handleOnClick(item.id)}>
                <FromText>{item.name}</FromText>
                <FromText>{item.email}</FromText>
              </FromTextWrapper>
            ))}
          </From>
        </FromWrapper>
      </Root>
    </>
  )
})

export default ReceiptSection
