// function desc(a, b, orderBy) {
//   const a_value = parseInt(a[orderBy], 10) ? parseInt(a[orderBy]) : a[orderBy]
//   const b_value = parseInt(b[orderBy], 10) ? parseInt(b[orderBy]) : b[orderBy]

//   if (b_value < a_value) {
//     return -1
//   }
//   if (b_value > a_value) {
//     return 1
//   }
//   return 0
// }

function newSort(a, b, orderBy) {
  let reg = /[a-zA-Z0-9]/
  if (reg.test(a[orderBy]) || reg.test(b[orderBy])) {
    if (a[orderBy] > b[orderBy]) {
      return 1
    } else if (a[orderBy] < b[orderBy]) {
      return -1
    } else {
      return 0
    }
  } else {
    return a[orderBy].length !== 0 && a[orderBy].localeCompare(b[orderBy])
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'asc'
    ? (a, b) => newSort(a, b, orderBy)
    : (a, b) => -newSort(a, b, orderBy)
}

function handleSort(source, order, orderBy) {
  const orderSource = stableSort(source, getSorting(order, orderBy))
  // console.log(orderSource)
  return orderSource
}

export default handleSort
