import React, { useState, useRef, useEffect, useMemo } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { isEmpty } from 'lodash'
import { getCompanyMemberAutoComplete, putCompanyBillRecipients } from '../../../api'
import { createModel } from '../../../utils'
import SearchMember from './SearchMember'
import PopUp from '../../../view/PopUp'

const AnimationScaleUp = keyframes`
 0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
const Background = styled.div`
  background-color: #1e201f;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  transition: 0.3s;
`
const Root = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10001;
`
const Wrapper = styled.div`
  min-height: 300px;
  width: 880px;
  background-color: #e8e8e8;
  padding: 40px 48px;
  position: absolute;
  z-index: 10001;
  border-radius: 2px;
  box-shadow: 0 20px 35px 0 rgba(30, 32, 31, 0.1), 0 0 5px 0 rgba(30, 32, 31, 0.05);
  top: 90px;
  animation: ${AnimationScaleUp} 0.2s both;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #1e201f;
`
const InputWrapper = styled.div`
  padding: 10px 48px;
  margin-top: 40px;
  background-color: #fff;
  width: 100%;
  min-height: 104px;
  display: flex;
  align-items: center;
  border-radius: 2px;
`
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #4b4d4c;
  flex-shrink: 0;
`
const InputContent = styled.div`
  min-height: 40px;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  width: 100%;
  margin-left: 32px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  flex-wrap: wrap;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
`
const Input = styled.input`
  height: 100%;
  /* width: 100%; */
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #4b4d4c;
  font-weight: 500;
  margin-right: 32px;
  ::placeholder {
    color: #d2d2d2;
    font-weight: normal;
  }
`
const SearchIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-left: auto;
  position: absolute;
  right: 8px;
`
const CloseIcon = styled.img`
  height: 32px;
  width: 32px;
  margin-left: auto;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  margin-top: 48px;
  display: flex;
`
const Button = styled.button`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 16px;
  ${(props) =>
    props.$cancel
      ? `
    color: #4b4d4c;
    background-color: #f5f5f8;
    margin-right: 24px;
    margin-left: auto;
  `
      : `
    color: #ffffff;
    background-color: #4b4d4c;
  `}
`
const SelectedMember = styled.div`
  background-color: #7a92ad;
  padding: 2px 8px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    background: url(https://static.taxigo-cdn.com/icons/business/white-close.svg) center/cover no-repeat;
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
`

const AddMemberPopover = React.memo(({ setDisplayPopover, company, getBillRecipients, billRecipients }) => {
  const [text, setText] = useState('')
  const [inputOnFocus, setInputOnFocus] = useState(false)
  const [currentMembers, setCurrentMembers] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [autoCompleteMembers, setAutoCompleteMembers] = useState([])
  const inputContentRef = useRef(null)
  const inputRef = useRef(null)

  const handleOnChange = (e) => {
    const { value } = e.target
    setText(value.trim())
    getCompanyMember(value.trim())
    // 如果有 key 文字，也要顯示 SearchMember
    value.trim() && !inputOnFocus && setInputOnFocus(true)
  }
  const addMember = (member) => {
    setCurrentMembers([...currentMembers, member])
  }
  const deleteMember = (member) => {
    const members = currentMembers.filter((item) => item.id !== member.id)
    setCurrentMembers(members)
  }
  const handleOnBlur = (e) => {
    if (e.target.id === 'input-wrapper') return
    setInputOnFocus(false)
  }
  const handleInputOnFocus = () => {
    inputRef.current.focus()
    !inputOnFocus && setInputOnFocus(true)
  }
  const addBillRecipients = async () => {
    // 還要把原本設定的 billRecipients 加上去，不然打這支 API 會直接覆蓋掉
    const filterBillRecipients = billRecipients.map((item) => item.id)
    const data = currentMembers.map((item) => item.id)
    if (isEmpty(data)) return setDisplayPopover(false) // 如果沒有選擇用戶新增，直接關掉
    const body = { company: company[0].id, updatedList: [...filterBillRecipients, ...data] }
    const response = await putCompanyBillRecipients(body)
    if (response.status !== 200) return createModel(<PopUp textCenter warning="發生錯誤，請稍後嘗試" />)
    setDisplayPopover(false)
    getBillRecipients()
  }
  const getCompanyMember = async (value) => {
    const data = await getCompanyMemberAutoComplete(company[0].id, value)
    if (data.status !== 200) return createModel(<PopUp textCenter warning="發生錯誤，請稍後嘗試" />)
    const { auto_complete } = data.data?.data
    // 去除掉原本就有設定的 member
    const billRecipientsID = billRecipients.map((item) => item.id)
    const filterAutoComplete = []
    auto_complete.forEach((item) => {
      !billRecipientsID.includes(item.id) && filterAutoComplete.push(item)
    })
    value ? setAutoCompleteMembers(filterAutoComplete) : setAllMembers(filterAutoComplete)
  }
  const searchMembers = useMemo(() => {
    // auto complete 的搜尋結果要去掉目前已選取的 member
    const data = text ? autoCompleteMembers : allMembers
    const currentMembersID = currentMembers.map((item) => item.id)
    const filterAutoComplete = []
    data.forEach((item) => {
      !currentMembersID.includes(item.id) && filterAutoComplete.push(item)
    })
    return filterAutoComplete
  }, [autoCompleteMembers, allMembers, currentMembers])

  useEffect(() => {
    getCompanyMember()
  }, [])

  return (
    <Root onClick={handleOnBlur}>
      <Wrapper>
        <TitleWrapper>
          <Title>新增收件人</Title>
          <CloseIcon src="https://static.taxigo-cdn.com/icons/business/close.svg" alt="" onClick={() => setDisplayPopover(false)} />
        </TitleWrapper>
        <InputWrapper>
          <SubTitle>收件人</SubTitle>
          <InputContent ref={inputContentRef} id="input-wrapper" onClick={handleInputOnFocus}>
            {!isEmpty(currentMembers) &&
              currentMembers.map((item) => (
                <SelectedMember key={item.id} onClick={() => deleteMember(item)}>
                  {item.name || item.email}
                </SelectedMember>
              ))}
            <Input
              type="text"
              name=""
              id="input-wrapper"
              ref={inputRef}
              placeholder={isEmpty(currentMembers) ? '輸入收件人名稱' : ''}
              value={text}
              onChange={handleOnChange}
              onFocus={handleInputOnFocus}
            />
            <SearchIcon src="https://static.taxigo-cdn.com/icons/business/Ic_M_Search.svg" alt="" />
            {inputOnFocus && (
              <SearchMember
                setInputOnFocus={setInputOnFocus}
                addMember={addMember}
                setText={setText}
                inputContentRef={inputContentRef}
                members={searchMembers}
              />
            )}
          </InputContent>
        </InputWrapper>
        <ButtonWrapper>
          <Button $cancel onClick={() => setDisplayPopover(false)}>
            取消
          </Button>
          <Button onClick={addBillRecipients}>確認新增</Button>
        </ButtonWrapper>
      </Wrapper>
      <Background />
    </Root>
  )
})

export default AddMemberPopover
