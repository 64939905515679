import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const SwitchButton = withStyles(theme => ({
  root: {
    width: 32,
    height: 24,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    top: '1px',
    left: '1px',
    '&$checked': {
      transform: 'translateX(8px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#00cba0',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869'
    }
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'unset'
  },
  track: {
    borderRadius: 24 / 2,
    backgroundColor: '#e8e8e8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'], {
      duration: '100ms'
    })
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

export default SwitchButton
