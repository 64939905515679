import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Icon, Fab, IconButton } from '@material-ui/core'
import MenuList from '../Menu/MenuList'
import { generalStyle } from '../../styles'
import CustomAutocomplete from '../Input/CustomAutocomplete'

const useStyle = makeStyles(theme => ({
  input: {
    width: '100%',
    padding: '12px 20px',
    margin: '8px 0',
    border: '1px solid #ccc',
    borderRadius: '24px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    marginBottom: '20px'
  },
  fab: {
    color: '#f8f8f8',
    background: '#4b4d4c'
  },
  button_block: {
    paddingTop: '3.5px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function CreateMemberForm(props) {
  const {
    state,
    memberList,
    createRef,
    handleCreateUser,
    clickCreateButton
  } = props
  const classes = useStyle()
  const generalClasses = generalStyle()

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone_number: '',
    group: { id: '', name: '' }
  })

  useEffect(() => {
    setValues({
      name: '',
      email: '',
      phone_number: '',
      group: { id: '', name: '' }
    })
  }, [memberList])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  function handleGroup(group) {
    if (!group || group.id === '0') {
      setValues({ ...values, group: { id: '', name: '' } })
    } else {
      setValues({ ...values, group })
    }
  }

  return (
    <div className={generalClasses.filter}>
      <form
        className={classes.container}
        ref={createRef}
        style={{ display: 'none' }}
        noValidate
        autoComplete="off"
      >
        <Grid item container spacing={2} xs={9}>
          <Grid
            item
            container
            direction="column"
            justify="space-around"
            spacing={1}
            xs={6}
          >
            <Grid item>
              <input
                className={classes.input}
                type="text"
                id="name"
                placeholder="姓名"
                value={values.name}
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item>
              <input
                className={classes.input}
                type="text"
                id="phone_number"
                placeholder="手機 (非必填) +886911222333"
                value={values.phone_number}
                onChange={handleChange('phone_number')}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="space-around"
            spacing={1}
            xs={6}
          >
            <Grid item xs>
              <input
                className={classes.input}
                type="text"
                id="email"
                placeholder="Email信箱"
                value={values.email}
                onChange={handleChange('email')}
              />
            </Grid>
            <Grid item>
              <CustomAutocomplete
                id="group-autocomplete"
                options={state.groups}
                getOptionLabel={option => option.name}
                // onInputChange={(e, v) => handleGroup(v)}
                onChange={(e, v) => handleGroup(v)}
                renderInput={params => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="group"
                      placeholder="選擇加入群組 (非必填)"
                      value={values.group.name}
                      {...params.inputProps}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-around"
          spacing={1}
          xs={2}
        >
          <Grid item className={classes.button_block}>
            <Fab
              aria-label="Add"
              size="small"
              className={classes.fab}
              onClick={() => handleCreateUser(values)}
            >
              <Icon>done</Icon>
            </Fab>
            <IconButton
              aria-label="Cancel"
              color="#4b4d4c"
              className={classes.fabCancel}
              onClick={clickCreateButton}
            >
              <Icon>clear</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default CreateMemberForm
