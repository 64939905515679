import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import moment from 'moment'
import { forEach } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import MenuList from '../view/Menu/MenuList'
import DropdownMenuList from '../view/Menu/DropdownMenuList'
import { CorpContext } from '../reducers/Main'
import { getData, getRestData } from '../router/index'
import { generalStyle } from '../styles'
import DetailTableCell from '../view/Table/DetailTableCell'
import { putTripReview, getCustomField } from '../api'
import Auth from '../model/Auth'
import { getCompanyPayment } from '../utils'
import { rejectedStatus } from '../constants'
import RejectedSection from './RejectedSection'

const useStyle = makeStyles((theme) => ({
  heading: {
    marginBottom: '11.5px',
    padding: '8px 0 15px 0',
    fontWeight: 700,
    borderBottom: '1px solid gray',
    gridArea: 'heading',
  },
  icon: {
    margin: theme.spacing(1),
    color: 'gray',
    fontSize: '120px',
  },
  cell: {
    borderBottom: 'none',
    padding: '16px 0',
    width: '192px',
  },
  profile_item: {
    gridArea: 'icon',
    width: '150px',
    height: '100%',
  },
  next: {
    color: '#797979',
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '1.17px',
    '& img': {
      marginLeft: '4px',
    },
  },
  content_a: {
    gridArea: 'top_left',
  },
  content_b: {
    gridArea: 'top_right',
  },
  container: {
    paddingTop: '40px',
    display: 'grid',
    gridTemplateColumns: '175px 420px 420px',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
            'icon heading heading '
            'icon top_left top_right'
        `,
  },
  textField: {
    '& label.Mui-focused': {
      color: 'orange',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'orange',
      },
    },
  },
  '@media (max-width:960px)': {
    container: {
      paddingTop: '40px',
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
            'heading '
						'top_left'
						'top_right'
        `,
    },
    profile_item: {
      display: 'none',
    },
  },
  dropdown: {
    minHeight: '50px',
    width: '192px',
    borderRadius: '7px',
    border: 'solid 1px #d2d2d2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px',
    marginBottom: '12px',
    wordBreak: 'break-all',
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
  },
}))

function TripReview(props) {
  const { classes, trip, type, dispatch, state } = props
  const tripWrap = type === 'public' ? trip.company_trips : trip
  const review_status = trip.company_trips?.status ?? trip.status
  const trip_hash = trip.trip_hash || trip.trips.trip_hash
  const group_id = trip.company_users_unit?.groups.id ?? trip.group
  const [notes, setNotes] = useState(null)
  const [rowNotes, setRowNotes] = useState({})
  const [status, setStatus] = useState(review_status.tripStatusLocalized())
  const [statusOpen, setStatusOpen] = useState(false)
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [menuOptions, setMenuOptions] = useState(undefined)
  const statusAnchorRef = useRef(null)
  const [dropdownAnchorRef, setDropdownAnchorRef] = useState(null)

  const statusOptions = [
    { id: 2, name: 'REJECT'.tripStatusLocalized() },
    { id: 3, name: 'ACCEPTED'.tripStatusLocalized() },
  ]

  useEffect(() => {
    const business_notes = {}
    trip.custom_field &&
      trip.custom_field.forEach((item) => {
        business_notes[item.id] = item.content
      })
    setNotes(business_notes)
    setRowNotes(business_notes)
    setStatus(review_status.tripStatusLocalized())
  }, [trip.custom_field, review_status])

  async function reviewTrip(status) {
    const custom_field = []
    forEach(notes, (value, key) => {
      custom_field.push({ id: Number(key), content: value })
    })
    const body = { trip_hash: [trip_hash], custom_field }
    if (Auth.getPermissionWithGroup(state, group_id) && !state.config.enable_company_trips_rejection) {
      body.status = status
    }
    const data = await putTripReview(body)
    if (data.status !== 200) return alert('更新審核失敗，請稍後再試。')
    alert('更新審核成功')
    getData(dispatch)
    getRestData(dispatch)
  }

  const handleNotesChange = (note_id, value) => {
    setNotes({ ...notes, [note_id]: value })
  }

  const handleOpenOptions = (e, item) => {
    setDropdownAnchorRef(e.currentTarget)
    setOptionsOpen(item.id)
    setMenuOptions(item)
  }

  const handleCloseOptions = () => {
    setDropdownAnchorRef(null)
    setOptionsOpen(null)
  }

  const hasCustomFields = () => {
    return state.custom_field.some((v) => v.active)
  }

  return (
    <React.Fragment>
      {state.custom_field.map((item, index) => {
        if (item.active || rowNotes[item.id]) {
          return (
            <>
              <DetailTableCell narrowTitle={item.title} />
              {item.type === 'text' ? (
                <DetailTableCell
                  textField
                  key={item.id}
                  notes={notes && notes[item.id]}
                  setNotes={(e) => handleNotesChange(item.id, e.target.value)}
                />
              ) : (
                <>
                  <div
                    id={item.id}
                    className={classes.dropdown}
                    onClick={(e) => handleOpenOptions(e, item)}
                    ref={dropdownAnchorRef}
                  >
                    {(notes && notes[item.id]) || '選擇事由'}
                    <img
                      className={classes.arrowIcon}
                      src="https://static.taxigo-cdn.com/icons/general/arrow-down.svg"
                    />
                  </div>
                  <DropdownMenuList
                    id={item.id}
                    open={optionsOpen}
                    setOpen={handleCloseOptions}
                    anchorRef={dropdownAnchorRef}
                    options={menuOptions}
                    handleMenuListAction={handleNotesChange}
                  />
                </>
              )}
            </>
          )
        }
      })}
      {state.config.enable_company_trips_rejection
        ? <></>
        : (
          <TableRow>
            <TableCell className={classes.cell}>
              <TextField
                id="outlined-corp-input"
                label="審核狀態"
                fullWidth
                className={classes.textField}
                value={status}
                onClick={() => {
                  setStatusOpen((prevOpen) => !prevOpen)
                }}
                ref={statusAnchorRef}
              />
              {Auth.getPermissionWithGroup(state, group_id) && state.me && (
                <MenuList
                  list_header="請選擇狀態"
                  options={statusOptions}
                  field={'status'}
                  addSearch={() => {}}
                  setOptionTitle={() => {}}
                  open={statusOpen}
                  setOpen={setStatusOpen}
                  anchorRef={statusAnchorRef}
                  handleRole={setStatus}
                />
              )}
            </TableCell>
          </TableRow>
        )}
        {hasCustomFields() || !state.config.enable_company_trips_rejection
          ? (
            <TableRow>
              <TableCell className={classes.cell}>
                <BasicButton
                  style={{ float: 'right', marginRight: 0 }}
                  color="#ffffff"
                  background="#4b4d4c"
                  hoverColor="black"
                  title="確認送出"
                  onClick={() => reviewTrip(status.tripStatusPreType())}
                />
              </TableCell>
            </TableRow>
          )
          : <></>
        }
    </React.Fragment>
  )
}

function PublicTrip({ trip, type }) {
  const { state, dispatch } = useContext(CorpContext)
  const isPublic = useMemo(() => type === 'public', [type])

  // const tripWrap = type === 'public' ? trip.company_trips : trip

  const classes = useStyle()
  const generalClasses = generalStyle()
  // const [trip_link, setTripsLink] = useState(
  //   process.env.REACT_APP_SHARE_TRIP_URI + trip.trip_hash
  // )
  // const [next_trip_id, setNextTripsId] = useState('')
  // const [tripsList, setTripsList] = useState([])

  // useEffect(() => {
  //   setTripsList(
  //     state.trips_history
  //       .sort((a, b) => (a.trip_id > b.trip_id ? 1 : -1))
  //       .reverse()
  //   )
  // }, [state.trips_history])

  // useEffect(() => {
  //   nextTripId()
  //   setTripsLink(process.env.REACT_APP_SHARE_TRIP_URI + trip.trip_hash)
  // }, [tripsList, trip])

  // function nextTripId() {
  //   setNextTripsId(tripWrap.trip)
  //   for (let i = 0; i < tripsList.length; i++) {
  //     const item = tripsList[i]
  //     if (item.trip_id !== tripWrap.trip) {
  //       continue
  //     }
  //     if (tripsList.length > i + 1) {
  //       setNextTripsId(tripsList[i + 1].trip_id)
  //       return
  //     }
  //   }
  // }

  const personalPendingPaymeent = (trip) => {
    const rejected_status = rejectedStatus[trip.rejected_status] || '企業'
    const unpaidAmount = trip.amount - trip.payments.company - trip.payments.self
    if (unpaidAmount > 0 && rejected_status === '員工') {
      return `員工待支付 ${unpaidAmount}`
    }
    return ''
  }

  const handleTripWrap = () => {
    let amount = trip.amount || trip.trips?.amount
    const { company: companyPayment, self } = getCompanyPayment(trip.transactions || [])
    const tripWrap = {
      reserve_time: trip.reserve_time ?? trip.trips.reserve_time,
      name: isPublic ? trip.company_users_unit.name : state.me[0].name,
      start_time: trip.trip_start_time,
      finish_time: trip.trip_finish_time,
      start_address: trip.start_address ?? trip.trips?.start_address,
      end_address: trip.end_address ?? trip.trips?.end_address,
      plate_number: trip.driver_info?.plate_number ?? trip.trips?.driver_info?.plate_number,
      last_name: trip.driver_info?.last_name ?? trip.trips?.driver_info?.last_name,
      trip_hash: trip.trip_hash ?? trip.trips?.trip_hash,
      group: trip.company_users_unit?.groups.name ?? trip.group_name,
      amount,
      trip_link: process.env.REACT_APP_SHARE_TRIP_URI + `${trip.trip_hash ?? trip.trips?.trip_hash}`,
      company_amount: companyPayment,
      self_amount: self,
      rejected_status: trip.rejected_status,
      rejected_operator: trip.rejected_operator,
      rejected_at: trip.rejected_at,
      rejectable: trip.rejectable,
      pending_label: personalPendingPaymeent({ ...trip, amount, payments: { company: companyPayment, self } }),
      trip_id: trip.trip_id,
    }
    return tripWrap
  }
  console.log(trip, '-----')

  const tripData = useMemo(() => handleTripWrap(), [trip, isPublic])
  return (
    <div className={generalClasses.paper}>
      <Typography variant="h5" className={generalClasses.heading}>
        行程資訊
      </Typography>
      <div className={classes.container}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.heading}
        >
          <Grid item>
            <Typography variant="h6">
              用車時間{' '}
              {moment(tripData.reserve_time * 1000).format(
                'YYYY/MM/DD HH:mm'
              )}{' '}
              的行程
            </Typography>
          </Grid>
          {/* <Grid
            style={{
              alignSelf: 'flex-right'
            }}
          >
            <Typography
              className={classes.next}
              component={RouterLink}
              to={`/${type}/tripdetail/${next_trip_id}`}
            >
              下一筆
              <img src={process.env.REACT_APP_IMG_URL + 'next-icon.svg'} />
            </Typography>
          </Grid> */}
        </Grid>
        <div className={classes.profile_item}>
          <img
            src={`https://static.taxigo-cdn.com/icons/business/${
              isPublic ? 'business_trip_icon.svg' : 'business_personal_icon.svg'
            }`}
            alt=""
          />
        </div>
        <div className={classes.content_a}>
          <DetailTableCell title="乘客姓名" content={tripData.name} />
          <DetailTableCell
            title="上車時間"
            content={moment(tripData.start_time * 1000).format(
              'YYYY/MM/DD HH:mm'
            )}
          />
          <DetailTableCell
            title="下車時間"
            content={moment(tripData.finish_time * 1000).format(
              'YYYY/MM/DD HH:mm'
            )}
          />
          <DetailTableCell
            title="上車地點"
            content={tripData.start_address}
          />
          <DetailTableCell
            title="下車地點"
            content={tripData.end_address || '未輸入'}
          />
          <DetailTableCell
            title="車牌號碼"
            content={tripData.plate_number}
          />
          <DetailTableCell
            title="駕駛"
            content={tripData.last_name ? tripData.last_name + ' ＯＯ' : '租賃車'}
          />
          <DetailTableCell
            title="行程編號"
            content={tripData.trip_hash}
          />
          <DetailTableCell
            title="行程路線"
            link="行程連結"
            trip_link={tripData.trip_link}
          />
        </div>
        <div className={classes.content_b}>
          <DetailTableCell title="群組" content={tripData.group} />
          <DetailTableCell
            title="總車資"
            content={tripData.amount + ' 元'}
          />
          <DetailTableCell
            title="公司支付"
            content={tripData.company_amount + ' 元'}
          />
          <DetailTableCell
            title="員工自付"
            content={tripData.self_amount + ' 元'}
          />
          <TripReview
            classes={classes}
            trip={trip}
            state={state}
            dispatch={dispatch}
            type={type}
          />
          {state.config.enable_company_trips_rejection && (
            <RejectedSection content={{ status: tripData.rejected_status, operator: tripData.rejected_operator, rejectable: tripData.rejectable, rejected_at: tripData.rejected_at, pending_label: tripData.pending_label }} trip_id={tripData.trip_id} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PublicTrip
