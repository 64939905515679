import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240
const mainStyle = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      paddingTop: '64px'
    }
  },
  listWrap: {
    marginBottom: '32px'
  },
  drawerList: {
    paddingTop: '128px',
    paddingLeft: '27px'
  },
  drawerItem: {
    textDecoration: 'none',
    color: 'black',
    padding: '0 0 0 11px'
    // marginBottom: '32px'
  },
  subDrawerItem: {
    textDecoration: 'none',
    color: '#4b4d4c',
    padding: '16px 0 0 10px',
    marginLeft: '14px'
  },
  subDrawerItemText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#4b4d4c'
  },
  drawerHighLight: {
    '&::before': {
      content: '""',
      height: '12px',
      width: '3px',
      position: 'absolute',
      left: 0,
      borderRadius: '10px',
      backgroundColor: '#00c299'
    }
  },
  drawerItemText: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2d2f2e'
  },
  blockEvent: {
    pointerEvents: 'none'
  },
  appBar: {
    backgroundColor: 'rgb(35, 35, 35)',
    overflowX: 'auto'
  },
  appBarWidth: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  toolBar: {
    paddingLeft: '80px',
    paddingRight: '64px',
    fontSize: '20px',
    fontWeight: '600',
    color: 'white'
  },
  '@media (max-width:1200px)': {
    toolBar: {
      width: '100%',
      padding: '0 16px'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    backgroundColor: 'rgb(35, 35, 35)',
    color: 'white',
    fontSize: '18px',
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '0 10px',
    position: 'fixed',
    width: '240px',
    zIndex: 5,
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    backgroundColor: 'rgb(237, 237, 237)',
    width: drawerWidth,
    borderRight: 'unset'
  },
  content: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    backgroundColor: 'white'
  },
  link: {
    color: '#d2d3d2',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  businessIcon: {
    width: '28px',
    height: '28px',
    marginRight: '12px'
  },
  appBarContent: {
    // [theme.breakpoints.up('sm')]: {
    // margin: 'auto',
    // width: '70vw'
    // },
    marginLeft: 0
    // overflow: 'auto',
    // position: 'absolute',
    // height: '100vh'
  },
  headIcon: {
    height: '20px',
    width: '20px'
  },
  toolbarAction: {
    width: '120px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}))

export default mainStyle
