import React, { useState }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Icon } from '@material-ui/core'
import { getInvoiceEmailContent } from '../../api'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

const useStyles = makeStyles(theme => ({
  right_button: {
    borderRadius: '5px 20px 20px 5px',
    margin: '8px 5px',
    boxShadow: 'none',
  },
  text: {
    display: 'inline-block',
    marginRight: '10px',
    color: '#fff',
  },
  view_icon: {
    color: '#fff',
  }
}))

const FetchInvoiceButton = ({ summary, company }) => {
  const classes = useStyles()

  const openNewTab = async () => {
    const { data: content, status } = await getInvoiceEmailContent({ company_id: company?.id, month: summary.time, dryrun: true })
    if (status !== 200) {
      alert('查無發票資訊')
      return
    }

    const new_tab = window.open('about:blank', '')
    new_tab.document.write(content)
    new_tab.document.close()
  }

  const disabled = !summary?.invoice_info?.amount

  return (
    <Button disabled={disabled} className={classes.right_button} variant="contained" style={{ background: '#7A92AD' }} onClick={openNewTab}>
      <Typography className={classes.text}>
        查看 {summary.time.toMonth()} 月發票通知
      </Typography>
      <VisibilityOutlinedIcon className={classes.view_icon} fontSize="small" />
    </Button>
  )
}

export default FetchInvoiceButton
