import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Table } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ReactComponent as EditIcon } from '../icon/icon-05_edit.svg'
import QutaProcessBar from '../view/Bars/QutaProcessBar'
import { generalStyle } from '../styles'

const useStyle = makeStyles(theme => ({
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 10,
    width: '70vw'
  },
  heading: {
    height: '28px',
    fontWeight: 700,
    marginBottom: '20px'
  },
  icon: {
    color: 'gray'
  },
  cell: {
    borderBottom: 'none',
    width: '90px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#646464',
    lineHeight: '2',
    padding: '2px 30px 2px 0',
    textAlignLast: 'justify'
  },
  cellContent: {
    borderBottom: 'none',
    maxWidth: '100px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#646464',
    lineHeight: '2',
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  sp_cell: {
    borderBottom: 'none',
    padding: '1px 35px 20px 0px'
  },
  profile_item: {
    margin: '0 30px 0 -8px'
  },
  amount_item: {
    borderLeft: '2px solid lightgray',
    minHeight: '150px',
    padding: '0 50px 0 40px'
  },
  currentAmountTitle: {
    width: '100%',
    color: '#646464',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '1.1px',
    marginBottom: '4px'
  },
  previousAmount: {
    textAlign: 'right',
    width: '100%',
    color: '#646464',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '1.1px'
  },
  quota: {
    fontSize: '70px',
    color: '#ffbe5a',
    fontWeight: 300,
    '& span': {
      fontSize: '14px',
      color: '#646464',
      fontWeight: 500
    }
  },
  group_list: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#646464',
    fontSize: '12px',
    padding: '4px 0',
    lineHeight: 1.5
  },
  group: {
    fontWeight: 300
  },
  group_amount: {
    fontWeight: 500,
    '& span': {
      color: '#ffaf00',
      marginRight: '16px'
    }
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(1),
      width: '100%'
    },
    amount_item: {
      borderLeft: 'none',
      height: '150px',
      padding: '10px 0 0 0'
    },
    profile_item: {
      display: 'none'
    }
  }
}))

function ProfileCard(props) {
  const classes = useStyle()
  const generalClasses = generalStyle()

  const { me } = props
  if (!me[0]) return null
  const info = me || []

  return (
    <React.Fragment>
      <Paper className={generalClasses.cardPaper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              個人檔案
            </Typography>
          </Grid>
          <Grid item>
            <Icon
              component={Link}
              to={`/profile/editprofile`}
              className={classes.icon}
              style={{ fontSize: '25px' }}
            >
              <EditIcon style={{ height: '25px', width: '25px' }} />
            </Icon>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.profile_item}>
            <img src="https://static.taxigo-cdn.com/icons/business/business_personal_icon.svg" />
          </Grid>
          <Grid item style={{ width: '235px' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>姓名</TableCell>
                  <TableCell className={classes.cellContent}>
                    {info[0].name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>帳號狀態</TableCell>
                  <TableCell className={classes.cellContent}>
                    {info[0].status.userStatusLocalized()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs className={classes.amount_item}>
            <Typography className={classes.currentAmountTitle}>
              本月累積乘車金額
            </Typography>
            {info.map(
              item =>
                !isEmpty(item.history) &&
                item.history.map((item, index) => {
                  const current_month =
                    item.deductible && item.deductible[0].deductibleAmount
                  return (
                    <div key={index} className={classes.group_list}>
                      <div className={classes.group}>{item.name}</div>
                      <div className={classes.group_amount}>
                        <span>{current_month}</span> /
                        {item.quota.toLocaleString('en')} 元
                      </div>
                    </div>
                  )
                })
            )}
            {/* {info.history.map((item, index) => {
              const current_month =
                item.deductible && item.deductible[0].deductibleAmount
              return (
                <div key={index} className={classes.group_list}>
                  <div className={classes.group}>{item.name}</div>
                  <div className={classes.group_amount}>
                    <span>{current_month}</span> /
                    {item.quota.toLocaleString('en')} 元
                  </div>
                </div>
              )
            })} */}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default ProfileCard
