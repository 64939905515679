/* eslint-disable no-new-object */
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CorpContext } from '../reducers/Main'
import {
  Grid,
  Typography,
  CircularProgress,
  TablePagination,
} from '@material-ui/core'
import Auth from '../model/Auth'
import BasicButton from '../view/Buttons/BasicButton'
import FilterButtonWithClear from '../view/Buttons/FilterButtonWithClear'
import DateRangeButton from '../view/Buttons/DateRangeButton'
import ExportTripDetail from '../view/Buttons/ExportTripDetail'
import MSortTable from '../view/Table/MSortTable'
import CheckboxTableCell from '../view/Table/CheckboxTableCell'
import { generalStyle } from '../styles'
import { putTripReview, getTripFilter, getCustomField } from '../api'
import { handleSort } from '../utils'
import ReceiptDescription from './ReceiptDescription'
import { receiptType, tripCategory } from '../constants'

const useStyle = makeStyles((theme) => ({
  visible: {
    display: 'block',
    visibility: 'visible',
  },
  invisible: {
    display: 'none',
    visibility: 'hidden',
  },
  textCut: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'unset',
  },
  tripContainer: {
    overflow: 'auto',
    height: '100vh',
  },
  checkBox: {
    zIndex: 0,
    maxWidth: '0.5vw',
    wordWrap: 'break-word',
    '&:hover': {
      background: 'unset',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  loading: {
    color: '#797979',
  },
}))

const statusOptions = [
  { id: 'PENDING', name: 'PENDING'.tripStatusLocalized() },
  { id: 'ACCEPTED', name: 'ACCEPTED'.tripStatusLocalized() },
  { id: 'REJECT', name: 'REJECT'.tripStatusLocalized() },
]

const tripCategoryOptions = [
  { id: 'common', name: '一般行程' },
  { id: 'shuttle', name: '機場接送' },
]

const receiptTypeOptions = [
  { id: 'RECEIPT', name: '收據' },
  { id: 'INVOICE', name: '發票' },
]

const headRow = [
  // { id: 'check', numeric: 'left', label: '', hide: false },
  {
    id: 'reserve_time',
    numeric: 'left',
    label: '用車時間',
    hide: false,
    defaultOrder: 'desc',
  },
  { id: 'group', numeric: 'left', label: '群組', hide: false },
  { id: 'trip_category', numeric: 'left', label: '行程類型', hide: false },
  { id: 'name', numeric: 'left', label: '姓名', hide: false },
  { id: 'amount', numeric: 'left', label: '總車資', hide: false },
  { id: 'amount', numeric: 'left', label: '公司支付', hide: false },
  { id: 'amount', numeric: 'left', label: '員工自付', hide: false },
  { id: 'receipt_type', numeric: 'left', label: '報帳類型', hide: false },
  { id: 'review_notes', numeric: 'left', label: '備註', hide: false },
]

// let selectedTrips = []

function Trips() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const { state, dispatch } = useContext(CorpContext)

  const [tripsList, setTripsList] = useState([])
  const [group_f, setGroup] = useState('所有群組')

  const [member_list, setMemberList] = useState([])
  const [member_f, setMember] = useState('所有成員')
  const [member_id, setMemberID] = useState(0)

  const [status_f, setStatus] = useState('狀態')
  const [trip_category_f, setTripCategory] = useState(null)
  const [receipt_type_f, setReceiptType] = useState(null)
  const [date_range_start, setDateStart] = useState(null)
  const [date_range_end, setDateEnd] = useState(null)

  const [openToolbar, setOpenToolbar] = useState(false)
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedTrips, setSelectedTrips] = useState([])
  const [isSelectAll, setSelectAll] = useState(false)

  const setUpHeader = () => {
    state.config.enable_company_trips_rejection
      ? headRow.push({ id: 'rejected_status', numeric: 'left', label: '付款方', hide: false })
      : headRow.push({ id: 'company_trips_status', numeric: 'left', label: '狀態', hide: false })
  }

  useEffect(() => {
    setUpHeader()
    return () => headRow.pop()
  }, [])

  useEffect(() => {
    setMemberList(handleSort(state.all_member, 'asc', 'name'))
  }, [state.all_member])

  useEffect(() => {
    realtimeSearch(true)
  }, [date_range_end, group_f, member_f, status_f, trip_category_f, receipt_type_f])

  useEffect(() => {
    setOpenToolbar(selectedTrips.length)
  }, [selectedTrips])

  useEffect(() => {
    handleGetCustomField()
    return () => {
      realtimeSearch(false, true)
    }
  }, [])

  const handleTripsStructure = useCallback((trips) => {
    const list = []
    trips.forEach((element) => {
      //為了排序所以要變成平面
      list.push({
        trip_hash: element.trip_hash,
        trip_id: element.trip_id,
        reserve_time: element.reserve_time,
        group: element.company_users_unit.groups.name,
        group_status: element.company_users_unit.groups.deprecated
          ? '（已刪除）'
          : '',
        name: element.company_users_unit.name, //員工姓名
        plate_number: element.driver_info.plate_number, //車牌
        trip_start_time: element.trip_start_time, //上車時間
        start_address: element.start_address, //上車地點
        trip_finish_time: element.trip_finish_time, //下車時間
        end_address: element.end_address, //下車地點
        distance: element.distance ? Number(element.distance) : '-', // 行駛里程
        last_name: element.driver_info.last_name, //司機
        amount: element.amount, //總金額
        trip_category: tripCategory[element.trip_category],
        receipt_type: receiptType[element.receipt_type],
        company_trips_status: element.company_trips.status ?? element.status,
        rejected_status: element.rejected_status, // 員工自付狀態
        custom_field: element.custom_field, //備註
        transactions: element.transactions, // 公司支付與員工自付
        tripWrap: element, // for open detail
      })
    })
    return list
  }, [])

  async function handleGetCustomField() {
    const data = await getCustomField(state.company[0].id)
    if (data.status === 200) {
      dispatch({ type: 'UPDATE_CUSTOM_FIELD', custom_field: data.data })
    }
  }

  function addSearch(field, option) {
    switch (field) {
      case 'group':
        setGroup(option)
        break
      case 'member':
        setMember(option.name)
        setMemberID(option.id)
        break
      case 'status':
        setStatus(option)
        break
      case 'receipt_type':
        setReceiptType(option.id)
        break
      case 'trip_category':
        setTripCategory(option.id)
        break
      default:
        return
    }
  }

  function setDateRange(startDate, endDate) {
    if (startDate != null && endDate != null) {
      setDateStart(startDate)
      setDateEnd(endDate)
    } else if (startDate == null && endDate == null) {
      setDateStart(startDate)
      setDateEnd(endDate)
    }
  }
  function triggerClearSearch() {
    setGroup('所有群組')

    setMember('所有成員')
    setMemberID(0)

    setStatus('狀態')
    setReceiptType(null)
    setTripCategory(null)

    setDateStart(null)
    setDateEnd(null)
  }

  function query(arr) {
    if (!arr.length) return ''
    return `(${arr.join(',')})`
  }

  async function realtimeSearch(resetPage = false, cleanQuery = false) {
    const tripsOject = new Object()
    const usersOject = new Object()
    const groupObject = new Object()

    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      usersOject.id = member_id
    } else {
      delete usersOject.id
    }

    if (group_f !== '所有群組') {
      groupObject.group = group_f.id
    } else {
      delete groupObject.group
    }

    if (status_f !== '狀態') {
      // tripsOject.status = status_f.tripStatusPreType()
      tripsOject.status = status_f.id
    } else {
      delete tripsOject.status
    }
    if (date_range_start != null) {
      tripsOject.start_time = date_range_start
    } else {
      delete tripsOject.start_time
    }
    if (date_range_end != null) {
      tripsOject.end_time = date_range_end
    } else {
      delete tripsOject.end_time
    }

    trip_category_f ? tripsOject.trip_category = trip_category_f : delete tripsOject.trip_category
    receipt_type_f ? tripsOject.receipt_type = receipt_type_f : delete tripsOject.receipt_type

    const tripsQuery = []
    const usersQuery = []
    const groupsQuery = []
    for (const key in tripsOject) {
      tripsQuery.push(`${key}:"${encodeURIComponent(tripsOject[key])}"`)
    }
    for (const key in usersOject) {
      usersQuery.push(`${key}:"${encodeURIComponent(usersOject[key])}"`)
    }
    for (const key in groupObject) {
      groupsQuery.push(`${key}:"${encodeURIComponent(groupObject[key])}"`)
    }

    const data = cleanQuery
      ? await getTripFilter(query([]), query([]), query([]))
      : await getTripFilter(
          query(tripsQuery),
          query(usersQuery),
          query(groupsQuery)
        )
    if (data.status !== 200)
      return handleAlertBar('搜尋行程失敗，請稍後再試。', 'error')
    setTripsList(handleTripsStructure(data.data.data.trips))
    setLoading(false)
    resetPage && setPage(0)
    dispatch({
      type: 'UPDATE_TRIP_HISTORY',
      trips_history: data.data.data.trips,
    })
    console.log(data, 'trip realtime---------')
  }

  const handleUpdateSelectedTrips = (action, trip_hash) => {
    const newSelected =
      action === 'selected'
        ? selectedTrips.concat(trip_hash)
        : selectedTrips.filter((hash) => hash !== trip_hash)
    setSelectedTrips(newSelected)
  }

  const handleSelectAllTrips = (sortedTrips) => {
    setSelectedTrips(
      isSelectAll
        ? []
        : sortedTrips
            .filter((trip) => !selectedTrips.includes(trip.trip_hash))
            .map((trip) => trip.trip_hash)
    )
    setSelectAll(!isSelectAll)
  }

  function printRow(row, index) {
    return (
      <CheckboxTableCell
        row={row}
        index={index}
        selectedTrips={selectedTrips}
        handleUpdateSelectedTrips={handleUpdateSelectedTrips}
        handleOpenDetail={() => handleOpenDetail(row)}
      />
    )
  }

  const handleOpenDetail = (row) => {
    dispatch({
      type: 'UPDATE_TRIP_DETAIL',
      trip_detail: row.tripWrap,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  return (
    <div id="trip_container" className={classes.tripContainer}>
      <div className={generalClasses.toolbar} />
      <div id="business_trips" className={generalClasses.paper}>
        <Typography variant="h5" className={generalClasses.heading}>
          所有行程紀錄
        </Typography>
        <ReceiptDescription />
        <Grid container justify="space-between">
          <Grid item></Grid>
          <Grid item>
            <ExportTripDetail
              tripsList={tripsList}
              custom_field={state.custom_field}
            />
          </Grid>
        </Grid>
        <div className={openToolbar ? classes.visible : classes.invisible}>
          <Toolbar
            setSelectAll={setSelectAll}
            selectedTrips={selectedTrips}
            setSelectedTrips={setSelectedTrips}
            setOpenToolbar={setOpenToolbar}
            realtimeSearch={realtimeSearch}
            handleAlertBar={handleAlertBar}
          />
        </div>
        <div className={openToolbar ? classes.invisible : classes.visible}>
          <div className={generalClasses.filter}>
            <Grid container justify="space-between">
              <Grid item>
                <DateRangeButton
                  start_date={date_range_start}
                  end_date={date_range_end}
                  field={'date_range'}
                  onSearch={setDateRange}
                />
                {Auth.getPermission(state, 'ADMIN') ? (
                  <FilterButtonWithClear
                    title={group_f}
                    default_title="所有群組"
                    list_header="選擇群組"
                    options={state.groups}
                    field={'group'}
                    addSearch={addSearch}
                  />
                ) : (
                  ''
                )}
                <FilterButtonWithClear
                  title={member_f}
                  default_title="所有成員"
                  list_header="選擇成員"
                  options={member_list}
                  field={'member'}
                  addSearch={addSearch}
                />
                <FilterButtonWithClear
                  isAuto={false}
                  title={status_f}
                  default_title="狀態"
                  list_header="選擇狀態"
                  options={statusOptions}
                  field={'status'}
                  addSearch={addSearch}
                />
                <FilterButtonWithClear
                  isAuto={false}
                  title={trip_category_f}
                  default_title="行程類型"
                  list_header="行程類型"
                  options={tripCategoryOptions}
                  field={'trip_category'}
                  addSearch={addSearch}
                />
                <FilterButtonWithClear
                  isAuto={false}
                  title={receipt_type_f}
                  default_title="報帳類型"
                  list_header="報帳類型"
                  options={receiptTypeOptions}
                  field={'receipt_type'}
                  addSearch={addSearch}
                />
              </Grid>
              <Grid item>
                <BasicButton
                  color="#ffffff"
                  background="#4b4d4c"
                  hoverColor="black"
                  title="清除搜尋條件"
                  onClick={triggerClearSearch}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} />
          </div>
        ) : (
          <MSortTable
            loadmore
            list={tripsList}
            headRow={headRow}
            printRow={printRow}
            pOrderBy={'reserve_time'}
            defaultOrder={'desc'}
            page={page}
            rowsPerPage={rowsPerPage}
            checkboxStatus={isSelectAll}
            handleSelectAllClick={handleSelectAllTrips}
          ></MSortTable>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={tripsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

const Toolbar = React.memo(
  ({
    realtimeSearch,
    setOpenToolbar,
    handleAlertBar,
    setSelectAll,
    selectedTrips,
    setSelectedTrips,
  }) => {
    const generalClasses = generalStyle()

    const reviewTrip = async (status) => {
      const body = { status, trip_hash: selectedTrips }
      const data = await putTripReview(body)
      if (data.status !== 200) {
        return handleAlertBar('更新審核失敗，請稍後再試。', 'error')
      }
      handleAlertBar('更新審核成功')
      setSelectAll(false)
      setSelectedTrips([])
      setOpenToolbar(false)
      realtimeSearch(false)
    }

    return (
      <div className={generalClasses.filter}>
        <BasicButton
          color="rgba(236, 77, 77, 0.8)"
          hoverColor="black"
          background="#ffffff"
          title={'全設為' + 'REJECT'.tripStatusLocalized()}
          onClick={() => reviewTrip('REJECT')}
        />
        <BasicButton
          color="rgba(0, 203, 160, 0.9)"
          hoverColor="black"
          background="#ffffff"
          title={'全設為' + 'ACCEPTED'.tripStatusLocalized()}
          onClick={() => reviewTrip('ACCEPTED')}
        />
      </div>
    )
  }
)

export default Trips
