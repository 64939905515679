import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/styles'

const CustomAutocomplete = withStyles({
  input: {
    width: '100%',
    padding: '12px 20px',
    margin: '8px 0',
    border: '1px solid #ccc',
    borderRadius: '24px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555'
    }
  }
})(props => <Autocomplete {...props} />)

export default CustomAutocomplete
