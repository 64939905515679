import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Box,
  Fab,
  ListSubheader,
  Grid,
  Icon
} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: '10px 28px' }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
}

const useStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100px'
  },
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(0, 5),
    background: '#353535',
    borderRadius: 10,
    width: '70vw'
  },
  heading: {
    fontSize: '14px',
    flexBasis: '190px',
    flexShrink: 0,
    color: '#afafaf'
  },
  secondaryHeading: {
    fontSize: '14px',
    color: '#afafaf'
  },
  fab: {
    margin: theme.spacing(1),
    background: 'unset',
    '&:hover': {
      background: 'unset'
    },
    color: 'white',
    boxShadow: 'none',
    fontSize: '0.8rem',
    fontWeight: 400,
    height: '1.6rem',
    alignItems: 'center'
  },
  fabActive: {
    border: '1px solid white',
    background: 'unset'
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(1),
      width: '100%'
    },
    secondaryHeading: {
      display: 'none'
    }
  },
  icon: {
    color: '#d2d2d2'
  },
  edit_icon: {
    background: `url('../icon/icon-05_編輯.svg')`,
    height: '50px',
    width: '50px',
    color: 'white'
  },
  table_raw: {
    display: 'flex',
    marginBottom: '10px',
    '&:hover': {
      background: 'gray'
    },
    textDecoration: 'none'
  }
}))

function Bulletin(props) {
  const classes = useStyle()
  const theme = useTheme()
  const { bulletin } = props
  const [value, setValue] = React.useState(0)
  const qroupSystem = bulletin.company.concat(bulletin.system)

  function handleChange(newValue) {
    setValue(newValue)
  }

  function handleChangeIndex(index) {
    setValue(index)
  }

  function renderBulletinCard() {
    const card = []
    card.push()
    return card
  }

  return (
    <React.Fragment>
      <Paper
        className={classes.paper}
        style={{ maxHeight: 230, overflow: 'auto' }}
      >
        <ListSubheader
          style={{
            background: '#353535',
            height: '60px',
            verticalAlign: 'middle',
            padding: '15px 15px 10px 15px'
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <Fab
                variant="extended"
                className={`${classes.fab} ${value === 0 && classes.fabActive}`}
                value={0}
                onClick={() => handleChange(0)}
              >
                {'企業公告'}
              </Fab>
              <Fab
                variant="extended"
                className={`${classes.fab} ${value === 1 && classes.fabActive}`}
                value={1}
                onClick={() => handleChange(1)}
              >
                {'系統公告'}
              </Fab>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                component={Link}
                to={`/bulletindetail/empty/0`}
                className={classes.icon}
                // style={{ fontSize: '40px' }}
              >
                <AddCircleOutlineIcon
                  style={{ height: '25px', width: '25px' }}
                />
              </Icon>
            </Grid>
          </Grid>
        </ListSubheader>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabContainer className="corp" dir={theme.direction}>
            {bulletin.corp.map((row, i) => {
              if (bulletin.corp.length === 0) {
                return (
                  <Typography
                    key={`corporation-${row.title}`}
                    color="textSecondary"
                    className={classes.heading}
                  ></Typography>
                )
              } else {
                return (
                  <Box
                    key={`corporation-${row.title}`}
                    className={classes.table_raw}
                    component={Link}
                    to={`/bulletindetail/corp/${row.id}`}
                  >
                    <Typography className={classes.heading}>
                      {row.title.length > 20
                        ? row.title.slice(0, 20) +
                          '..   ' +
                          row.title.slice(row.title.length - 1)
                        : row.title}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      {row.content.length > 30
                        ? row.content.slice(0, 30) +
                          '..   ' +
                          row.content.slice(row.content.length - 1)
                        : row.content}
                    </Typography>
                  </Box>
                )
              }
            })}
          </TabContainer>
          <TabContainer className="system" dir={theme.direction}>
            {qroupSystem.map((row, i) => {
              if (qroupSystem.length === 0) {
                return (
                  <Typography
                    key={`system-${row.title}`}
                    color="textSecondary"
                    className={classes.heading}
                  >
                    目前尚無系統通告
                  </Typography>
                )
              } else if (i < bulletin.company.length) {
                return (
                  <Box
                    key={`system-${row.title}`}
                    className={classes.table_raw}
                    component={Link}
                    to={`/bulletindetail/company/${row.id}`}
                  >
                    <Typography className={classes.heading}>
                      {row.title.length > 20
                        ? row.title.slice(0, 20) +
                          '..   ' +
                          row.title.slice(row.title.length - 1)
                        : row.title}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      {row.content.length > 30
                        ? row.content.slice(0, 30) +
                          '..   ' +
                          row.content.slice(row.content.length - 1)
                        : row.content}
                    </Typography>
                  </Box>
                )
              } else {
                return (
                  <Box
                    key={`system-${row.title}`}
                    className={classes.table_raw}
                    component={Link}
                    to={`/bulletindetail/system/${row.id}`}
                  >
                    <Typography className={classes.heading}>
                      {row.title.length > 20
                        ? row.title.slice(0, 20) +
                          '..   ' +
                          row.title.slice(row.title.length - 1)
                        : row.title}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      {row.content.length > 30
                        ? row.content.slice(0, 30) +
                          '..   ' +
                          row.content.slice(row.content.length - 1)
                        : row.content}
                    </Typography>
                  </Box>
                )
              }
            })}
          </TabContainer>
        </SwipeableViews>
      </Paper>
      {renderBulletinCard()}
    </React.Fragment>
  )
}

export default Bulletin
