import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '25vh',
    marginBottom: 32,
    padding: 24,
  },
  container: {
    overflow: 'scroll',
    height: '100%',
  },
  title: {
    width: 500,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.22,
    color: '#2d2f2e',
    marginBottom: 15,
  },
})

const columns = (data) => [
  { id: 'id', column: 'id', minWidth: 80, value: data.id },
  { id: 'name', column: '姓名', minWidth: 120, value: data.name },
  { id: 'cc', column: '國碼', minWidth: 80, value: data.cc },
  {
    id: 'phone_number',
    column: '手機號碼',
    minWidth: 120,
    value: data.phone_number,
  },
  { id: 'email', column: 'email', minWidth: 170, value: data.email },
  {
    id: 'group_name',
    column: '群組名稱',
    minWidth: 120,
    value: data.group_name,
  },
  { id: 'role', column: '角色', minWidth: 120, value: data.role },
  { id: 'quota', column: '預算/月', minWidth: 120, value: data.quota },
  {
    id: 'review_notes',
    column: '填寫備註',
    minWidth: 120,
    value: Boolean(data.review_notes) ? '是' : '否',
  },
  {
    id: 'trip_quota',
    column: '單趟上限',
    minWidth: 120,
    value: data.trip_quota === -1 ? '無上限' : data.trip_quota,
  },
]

export default function UploadErrorTable(props) {
  const classes = useStyles()
  const { msgFile } = props

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.title}>匯入失敗的資料如下，請檢查：</div>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableBody>
            {typeof msgFile !== 'string' ? (
              msgFile.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns(row.data).map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: column.color,
                          }}
                        >
                          {column.value}
                        </TableCell>
                      )
                    })}
                    <TableCell
                      key="error_msg"
                      style={{
                        minWidth: 600,
                        color: '#7a92ad',
                      }}
                    >
                      {row.message}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <div>{msgFile}</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
