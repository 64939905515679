import React, { useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { generalStyle } from '../../styles'
import MaxWidthForTableCell from '../Table/MaxWidthForTableCell'
import SwitchButton from '../Buttons/SwitchButton'
import ActionsButton from '../Buttons/ActionsButton'

const useStyle = makeStyles(theme => ({
  switch_cell: {
    borderBottom: 'none',
    textDecoration: 'none',
    minWidth: '90px'
  },
  action_cell: {
    borderBottom: 'none',
    textDecoration: 'none',
    minWidth: '100px'
  }
}))

function MemberSettingTableCell(props) {
  const { row, implementActions, updateReviewNotes, company } = props
  const classes = useStyle()
  const [checked, setChecked] = useState(Boolean(row.review_notes))

  function handleUpdateSwitch(switchChecked) {
    setChecked(switchChecked)
  }

  function handleTripQuota() {
    return row.trip_quota !== -1 ? row.trip_quota : '無上限'
  }

  return (
    <>
      <MaxWidthForTableCell context={row.group_name} minWidth="150px" />
      <MaxWidthForTableCell
        context={row.role && row.role.roleLocalized()}
        minWidth="80px"
      />
      <MaxWidthForTableCell
        context={row.name}
        subContext={`(${row.email})`}
        minWidth="150px"
        maxWidth="200px"
        textCut
      />
      <MaxWidthForTableCell context={row.quota} minWidth="80px" />
      <MaxWidthForTableCell context={handleTripQuota()} minWidth="90px" />
      <TableCell className={classes.switch_cell} align="left" padding="none">
        <SwitchButton
          checked={checked}
          onChange={e => updateReviewNotes(e, row, handleUpdateSwitch)}
        />
      </TableCell>
      <TableCell className={classes.action_cell} align="center" padding="none">
        <ActionsButton
          memberSetting
          status={row.status}
          implementActions={implementActions}
          row={row}
        />
      </TableCell>
    </>
  )
}

export default MemberSettingTableCell
