import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Icon,
  Grid,
  Fab,
  Typography,
  Table,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import ErrorDialog from '../view/Dialog/ErrorDialog'
import { CorpContext } from '../reducers/Main'
import { putUserPassword, clearToken } from '../api'

const useStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflow: 'auto',
    width: '100%',
    minHeight: '65vh',
    padding: theme.spacing(8, 10)
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700
  },
  icon: {
    margin: theme.spacing(1),
    color: 'gray'
  },
  fab: {
    margin: theme.spacing(1),
    background: 'rgb(45, 45, 45)',
    color: 'white',
    boxShadow: 'none',
    width: '200px',
    '&:hover': {
      background: '#363335'
    },
    marginBottom: '25px'
  },
  profile_item: {
    margin: '0 30px 0 -15px'
  },
  textField: {
    '& label.Mui-focused': {
      color: 'orange'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'orange'
      }
    }
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(1),
      width: '100%'
    }
  },
  input: {
    marginBottom: '25px'
  },
  cell: {
    borderBottom: 'none',
    padding: '14px 40px 14px 0'
  }
}))

function EditProfile() {
  const classes = useStyle()

  const [me, setMe] = useState('')
  const [password, setPassword] = useState('')
  const [secondPassword, setSecondPassword] = useState('')
  const [hint, setHint] = useState('none')
  const [emptyHint, setEmptyHint] = useState('none')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const { state } = useContext(CorpContext)

  useEffect(() => {
    setMe(state.me[0])
  }, [state.me])

  function handleClickOpen(message) {
    setOpen(true)
    setMessage(message)
  }

  function handleClose() {
    setOpen(false)
  }

  async function updatePassword() {
    if (secondPassword !== password) {
      setHint('')
      return
    } else {
      setHint('none')
    }
    if (secondPassword === '' || password === '') {
      setEmptyHint('')
      return
    } else {
      setEmptyHint('none')
    }

    const body = {
      password: password,
      confirm_password: secondPassword
    }
    const data = await putUserPassword(body)
    if (data.status !== 200) return handleClickOpen(data.data.msg)
    handleClickOpen('密碼修改成功，請重新登入。')
    logout()
  }

  async function logout() {
    const data = await clearToken('token')
    if (data.status == 200) {
      window.location.href = '/'
    }
  }

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              修改個人檔案
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" spacing={6}>
          <Grid item className={classes.profile_item}>
            <Icon className={classes.icon} style={{ fontSize: '120px' }}>
              file_copy
            </Icon>
          </Grid>
          <Grid item>
            <form className={classes.container} noValidate autoComplete="off">
              <div>
                <Table>
                  {state.company.map(el => {
                    return (
                      <TableRow>
                        <TableCell className={classes.cell}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ textAlignLast: 'justify' }}
                          >
                            公司
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {el.name}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <React.Fragment>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ textAlignLast: 'justify' }}
                        >
                          姓名
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>{me.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ textAlignLast: 'justify' }}
                        >
                          email
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>{me.email}</TableCell>
                    </TableRow>
                  </React.Fragment>
                </Table>
              </div>
              <div className={classes.input}>
                <Typography variant="body2" className={classes.heading}>
                  輸入新密碼
                </Typography>
                <TextField
                  id="outlined-password-input"
                  placeholder="password"
                  className={classes.textField}
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className={classes.input}>
                <Typography variant="body2" className={classes.heading}>
                  再次確認新密碼
                </Typography>
                <TextField
                  id="outlined-second-password-input"
                  className={classes.textField}
                  placeholder="confirm password"
                  variant="outlined"
                  type="password"
                  value={secondPassword}
                  onChange={e => setSecondPassword(e.target.value)}
                />
                <p style={{ display: `${hint}`, color: '#e32e28' }}>
                  密碼輸入不一致，請再次確認。
                </p>
                <p style={{ display: `${emptyHint}`, color: '#e32e28' }}>
                  密碼欄請勿空白。
                </p>
              </div>
            </form>
          </Grid>
          <Grid item style={{ alignSelf: 'flex-end' }}>
            <Fab
              variant="extended"
              aria-label="send"
              className={classes.fab}
              onClick={() => updatePassword(password)}
            >
              確認送出
            </Fab>
          </Grid>
        </Grid>
      </div>
      <ErrorDialog open={open} handleClose={handleClose} message={message} />
    </React.Fragment>
  )
}

export default EditProfile
