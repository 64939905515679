import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  CircularProgress,
  TablePagination,
} from '@material-ui/core'
import { CorpContext } from '../reducers/Main'
import { generalStyle } from '../styles'
import { handleSort } from '../utils'
import {
  getReviewTicket,
  getCustomField,
  getReviewTicketById,
  postReviewTicket,
  postReviewTicketBatch,
} from '../api'
import Auth from '../model/Auth'
import ReviewTableCell from '../view/Table/ReviewTableCell'
import ReviewSortTable from '../view/Table/ReviewSortTable'
import DateRangeButton from '../view/Buttons/DateRangeButton'
import FilterButtonWithClear from '../view/Buttons/FilterButtonWithClear'
import BasicButton from '../view/Buttons/BasicButton'
import ExportTripDetail from '../view/Buttons/ExportTripDetail'
import ReviewDetailModal from '../view/Modal/ReviewDetailModal'

const useStyle = makeStyles((theme) => ({
  visible: {
    display: 'block',
    visibility: 'visible',
  },
  invisible: {
    display: 'none',
    visibility: 'hidden',
  },
  textCut: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'unset',
  },
  tripContainer: {
    overflow: 'auto',
    height: '100vh',
  },
  checkBox: {
    zIndex: 0,
    maxWidth: '0.5vw',
    wordWrap: 'break-word',
    '&:hover': {
      background: 'unset',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  loading: {
    color: '#797979',
  },
}))

const statusOptions = [
  { id: 'PENDING', name: 'PENDING'.tripStatusLocalized() },
  { id: 'ACCEPTED', name: 'ACCEPTED'.tripStatusLocalized() },
  { id: 'REJECT', name: 'REJECT'.tripStatusLocalized() },
]

const headRow = [
  {
    id: 'number_code',
    numeric: 'left',
    label: '表單編號',
    hide: false,
    defaultOrder: 'desc',
  },
  {
    id: 'create_timestamp',
    numeric: 'left',
    label: '申請時間',
    hide: false,
  },
  { id: 'group_id', numeric: 'left', label: '群組', hide: false },
  { id: 'applicant', numeric: 'left', label: '成員', hide: false },
  {
    id: 'trip_reserve_timestamp',
    numeric: 'left',
    label: '乘車時間',
    hide: false,
  },
  { id: null, numeric: 'left', label: '備註', hide: false },
  {
    id: null,
    numeric: 'left',
    label: '你的審核',
    hide: false,
  },
  { id: 'status', numeric: 'left', label: '狀態', hide: false },
]

function ReviewList() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const { state, dispatch } = useContext(CorpContext)
  const [reviewList, setReviewList] = useState([])
  const [status_f, setStatus] = useState('狀態')
  const [group_f, setGroup] = useState('所有群組')
  const [member_list, setMemberList] = useState([])
  const [member_f, setMember] = useState('所有成員')
  const [member_id, setMemberID] = useState(0)
  const [date_range_start, setDateStart] = useState(null)
  const [date_range_end, setDateEnd] = useState(null)
  const [orderRule, setOrderRule] = useState('desc')
  const [orderBy, setOrderBy] = useState('number_code')
  const [openToolbar, setOpenToolbar] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [managedGroupId, setManagedGroupId] = useState(false)
  const [reviewDetail, setReviewDetail] = useState({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedReview, setSelectedReview] = useState([])
  const [isSelectAll, setSelectAll] = useState(false)
  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    setMemberList(handleSort(state.all_member, 'asc', 'name'))
  }, [state.trips_history, state.all_member])

  useEffect(() => {
    const user = state.me.find((item) => item.role === 'MANAGER')
    const id = user ? user.group : 'id'
    setManagedGroupId(id)
    // user && setManagedGroupId(user.group)
  }, [state.me])

  useEffect(() => {
    const ticketId = parseInt(params.get('ticketId'))
    if (ticketId) {
      handleGetTicketById(ticketId)
    }
  }, [])

  useEffect(() => {
    if (!managedGroupId) return
    handleGetReviewTicket()
  }, [
    date_range_end,
    group_f,
    member_f,
    status_f,
    page,
    rowsPerPage,
    managedGroupId,
  ])

  useEffect(() => {
    setOpenToolbar(selectedReview.length)
  }, [selectedReview])

  async function handleGetReviewTicket(
    resetPage = false,
    cleanQuery = false,
    sort
  ) {
    let params = ''
    params += `page=${page}&`
    params += `limit=${rowsPerPage}&`
    if (managedGroupId !== 'id') {
      params += `managed_groupId=${managedGroupId}&`
    }
    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      params += `applicant=${member_id}&`
    }
    if (group_f !== '所有群組') {
      params += `group_id=${group_f.id}&`
    }
    if (status_f !== '狀態') {
      params += `status=${status_f.id}&`
    }
    if (date_range_start != null) {
      params += `trip_reserve_timestamp_start=${date_range_start}&`
    }
    if (date_range_end != null) {
      params += `trip_reserve_timestamp_end=${date_range_end}&`
    }
    if (sort) {
      params += `orderBy=${sort.orderBy}&`
      params += `orderRule=${sort.orderRule}&`
      setOrderBy(sort.orderBy)
      setOrderRule(sort.orderRule)
    } else {
      params += `orderBy=${orderBy}&`
      params += `orderRule=${orderRule}&`
    }

    const data = cleanQuery
      ? await getReviewTicket('')
      : await getReviewTicket(params)
    if (data.status !== 200)
      return handleAlertBar('搜尋審核單失敗，請稍後再試。', 'error')
    setReviewList(data.data)
    setLoading(false)
  }

  const handleGetTicketById = async (ticketId) => {
    const data = await getReviewTicketById(ticketId)
    if (data.status !== 200) return
    handleOpenModal(data.data[0])
  }

  async function handleGetCustomField() {
    const data = await getCustomField(state.company[0].id)
    if (data.status === 200) {
      dispatch({ type: 'UPDATE_CUSTOM_FIELD', custom_field: data.data })
    }
  }

  function addSearch(field, option) {
    switch (field) {
      case 'group':
        setGroup(option)
        break
      case 'member':
        setMember(option.name)
        setMemberID(option.id)
        break
      case 'status':
        setStatus(option)
        break
      default:
        return
    }
  }

  function setDateRange(startDate, endDate) {
    if (startDate != null && endDate != null) {
      setDateStart(startDate)
      setDateEnd(endDate)
    } else if (startDate == null && endDate == null) {
      setDateStart(startDate)
      setDateEnd(endDate)
    }
  }
  function triggerClearSearch() {
    setGroup('所有群組')
    setMember('所有成員')
    setMemberID(0)
    setStatus('狀態')
    setDateStart(null)
    setDateEnd(null)
  }

  const handleUpdateSelectedReview = (action, trip_hash) => {
    const newSelected =
      action === 'selected'
        ? selectedReview.concat(trip_hash)
        : selectedReview.filter((hash) => hash !== trip_hash)
    setSelectedReview(newSelected)
  }

  const handleSelectAllReview = (sortedReviews) => {
    setSelectedReview(
      isSelectAll
        ? []
        : sortedReviews
            .filter((review) => !selectedReview.includes(review.id))
            .map((review) => review.id)
    )
    setSelectAll(!isSelectAll)
  }

  const handleUpdateReviewStatus = async (
    ticketId,
    option,
    isModal = false
  ) => {
    const body = {
      review_status: option,
      company: state.company[0] && state.company[0].id,
    }
    const data = await postReviewTicket(body, ticketId)
    if (data.status !== 200) {
      return handleAlertBar(data.data.msg, 'error')
    }
    handleGetReviewTicket()
    handleAlertBar('更新審核成功')
    isModal && handleOpenModal()
  }

  function printRow(row, index) {
    return (
      <ReviewTableCell
        row={row}
        index={index}
        selectedReview={selectedReview}
        handleUpdateSelectedReview={handleUpdateSelectedReview}
        handleUpdateReviewStatus={handleUpdateReviewStatus}
        handleOpenModal={() => handleOpenModal(row)}
      />
    )
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenModal = (row = {}) => {
    setOpenModal((prevOpenModal) => !prevOpenModal)
    setReviewDetail(row)
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  return (
    <>
      <div id="trip_review_container" className={classes.tripContainer}>
        <div className={generalClasses.toolbar} />
        <div id="business_trips" className={generalClasses.paper}>
          <Typography variant="h5" className={generalClasses.heading}>
            差旅審核單
          </Typography>
          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              {/* <ExportTripDetail
                reviewList={reviewList}
                custom_field={state.custom_field}
              /> */}
            </Grid>
          </Grid>
          <div className={openToolbar ? classes.visible : classes.invisible}>
            <Toolbar
              company={state.company[0] && state.company[0].id}
              setSelectAll={setSelectAll}
              selectedReview={selectedReview}
              setSelectedReview={setSelectedReview}
              setOpenToolbar={setOpenToolbar}
              handleAlertBar={handleAlertBar}
            />
          </div>
          <div className={openToolbar ? classes.invisible : classes.visible}>
            <div className={generalClasses.filter}>
              <Grid container justify="space-between">
                <Grid item>
                  <DateRangeButton
                    startPlaceholder="申請日期"
                    customInputIcon
                    start_date={date_range_start}
                    end_date={date_range_end}
                    field={'date_range'}
                    onSearch={setDateRange}
                  />
                  {Auth.getPermission(state, 'ADMIN') ? (
                    <FilterButtonWithClear
                      title={group_f}
                      default_title="所有群組"
                      list_header="選擇群組"
                      options={state.groups}
                      field={'group'}
                      addSearch={addSearch}
                    />
                  ) : (
                    ''
                  )}
                  <FilterButtonWithClear
                    title={member_f}
                    default_title="所有成員"
                    list_header="選擇成員"
                    options={member_list}
                    field={'member'}
                    addSearch={addSearch}
                  />
                  <FilterButtonWithClear
                    isAuto={false}
                    title={status_f}
                    default_title="狀態"
                    list_header="選擇狀態"
                    options={statusOptions}
                    field={'status'}
                    addSearch={addSearch}
                  />
                </Grid>
                <Grid item>
                  <BasicButton
                    color="#ffffff"
                    background="#4b4d4c"
                    hoverColor="black"
                    title="清除搜尋條件"
                    onClick={triggerClearSearch}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          ) : (
            <ReviewSortTable
              loadmore
              list={reviewList}
              headRow={headRow}
              printRow={printRow}
              orderBy={orderBy}
              orderRule={orderRule}
              page={page}
              rowsPerPage={rowsPerPage}
              checkboxStatus={isSelectAll}
              handleSelectAllClick={handleSelectAllReview}
              handleGetReviewTicket={handleGetReviewTicket}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <ReviewDetailModal
        handleUpdateReviewStatus={handleUpdateReviewStatus}
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        reviewDetail={reviewDetail}
      />
    </>
  )
}

const Toolbar = React.memo(
  ({
    company,
    setOpenToolbar,
    handleAlertBar,
    setSelectAll,
    selectedReview,
    setSelectedReview,
  }) => {
    const generalClasses = generalStyle()

    // review api
    const reviewTrip = async (review_status) => {
      const body = { review_status, ticketIds: selectedReview, company }
      const data = await postReviewTicketBatch(body)
      console.log(data, 'review data')
      if (data.status !== 200) {
        return handleAlertBar(data.data.msg, 'error')
      }
      handleAlertBar('更新審核成功')
      setSelectAll(false)
      setSelectedReview([])
      setOpenToolbar(false)
    }

    return (
      <div className={generalClasses.filter}>
        <BasicButton
          color="rgba(236, 77, 77, 0.8)"
          hoverColor="black"
          background="#ffffff"
          title={'全設為' + 'REJECT'.tripStatusLocalized()}
          onClick={() => reviewTrip('REJECTED')}
        />
        <BasicButton
          color="rgba(0, 203, 160, 0.9)"
          hoverColor="black"
          background="#ffffff"
          title={'全設為' + 'ACCEPTED'.tripStatusLocalized()}
          onClick={() => reviewTrip('APPROVED')}
        />
      </div>
    )
  }
)

export default ReviewList
