import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell, TextField, Fab, Icon, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import MenuList from '../Menu/MenuList'
import SwitchButton from '../Buttons/SwitchButton'

const useStyle = makeStyles(theme => ({
  cell: {
    borderBottom: 'none',
    paddingRight: '10px'
  },
  fabAdd: {
    color: '#f8f8f8',
    background: '#4b4d4c',
    margin: '0 10px',
    width: '32px',
    height: '32px',
    minHeight: 'unset'
  },
  fabCancel: {
    color: '#4b4d4c',
    background: 'unset',
    marginLeft: '10px',
    width: '32px',
    height: '32px'
  }
}))

function EditGroupMemberCell(props) {
  const classes = useStyle()
  const { row, groups, editCancel, editOnClick, updateReviewNotes } = props
  const anchorRef = useRef(null)
  const roleAnchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [roleOpen, setRoleOpen] = useState(false)
  const [values, setValues] = useState({
    name: row.name,
    group: { group_name: row.group_name, id: row.group_id },
    email: row.email,
    quota: row.quota,
    trip_quota: row.trip_quota !== -1 ? row.trip_quota : '無上限'
  })
  const [groupName, setGroupName] = useState(row.group_name)
  const [role, setRole] = useState(row.role && row.role.roleLocalized())
  const [checked, setChecked] = useState(Boolean(row.review_notes))

  const roleOptions = [
    // { id: 1, name: 'ADMIN'.roleLocalized() },
    { id: 2, name: 'MANAGER'.roleLocalized() },
    { id: 3, name: 'MEMBER'.roleLocalized() }
  ]

  function handleUpdateSwitch(switchChecked) {
    setChecked(switchChecked)
  }

  const handleChange = name => event => {
    if (name === 'trip_quota' && event.target.value < 0) return
    setValues({ ...values, [name]: event.target.value })
  }

  function handleGroup(group) {
    setValues({ ...values, group })
    console.log(group, 'group')
    setGroupName(group.group_name)
  }

  function handleToggle(role) {
    if (role === 'role') {
      setRoleOpen(prevOpen => !prevOpen)
    } else {
      setOpen(prevOpen => !prevOpen)
    }
  }

  function handleRole(role) {
    setRole(role)
  }

  return (
    <React.Fragment>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="群組"
          margin="normal"
          value={groupName}
          onClick={handleToggle}
          ref={anchorRef}
        />
        <MenuList
          default_title="請選擇部門"
          options={groups}
          field="group"
          addSearch={() => {}}
          handleToggle={handleToggle}
          setOptionTitle={() => {}}
          open={open}
          setOpen={setOpen}
          anchorRef={anchorRef}
          handleGroup={handleGroup}
          handleRole={() => {}}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="角色"
          margin="normal"
          value={role}
          onClick={() => handleToggle('role')}
          ref={roleAnchorRef}
        />
        <MenuList
          default_title="請選擇角色"
          options={roleOptions}
          field={'role'}
          addSearch={() => {}}
          setOptionTitle={() => {}}
          open={roleOpen}
          setOpen={setRoleOpen}
          anchorRef={roleAnchorRef}
          handleRole={handleRole}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        {row.name} ({row.email})
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="預算"
          margin="normal"
          value={values.quota}
          onChange={handleChange('quota')}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="單趟上限"
          placeholder="無上限"
          margin="normal"
          type="number"
          value={values.trip_quota}
          onChange={handleChange('trip_quota')}
          InputLabelProps={{
            shrink: true
          }}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <SwitchButton
          checked={checked}
          onChange={e => updateReviewNotes(e, row, handleUpdateSwitch)}
        />
      </TableCell>
      <TableCell
        className={classes.cell}
        align="center"
        padding="none"
        style={{ minWidth: '110px' }}
      >
        <Fab
          aria-label="Add"
          className={classes.fabAdd}
          onClick={() => editOnClick(values, role.rolePreType())}
        >
          <Icon>done</Icon>
        </Fab>
        <IconButton
          aria-label="Cancel"
          color="#4b4d4c"
          className={classes.fabCancel}
          onClick={editCancel}
        >
          <Icon>clear</Icon>
        </IconButton>
      </TableCell>
    </React.Fragment>
  )
}

EditGroupMemberCell.propTypes = {
  row: PropTypes.array.isRequired
}

export default EditGroupMemberCell
