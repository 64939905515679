import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import RoleCard from './RoleCard'
import GroupList from './GroupList'

const userStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar
}))

function Setting() {
  const classes = userStyle()
  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <RoleCard />
        </Grid>
      </Grid>
      {/* <Grid container spacing={5}>
        <Grid item xs={12}>
          <GroupList />
        </Grid>
      </Grid> */}
    </React.Fragment>
  )
}

export default Setting
