import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Table, Typography, Grid, TableBody, TableCell, TableRow, Link } from '@material-ui/core'

import { CorpContext } from '../reducers/Main'
import ExportAmountTableButton from '../view/Buttons/ExportAmountTableButton'
import ExportTripAmountTableButton from '../view/Buttons/ExportTripAmountTableButton'
import FetchInvoiceButton from '../view/Buttons/FetchInvoiceButton'
import ReceiptModal from '../view/Modal/ReceiptModal'
import ReceiptDescription from './ReceiptDescription'
import moment from 'moment'

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 8,
    width: '100%',
  },
  inner_paper: {
    background: 'rgba(255, 190, 90, 0.1)',
    boxShadow: 'none',
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    margin: '20px 0 20px 0',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
  },
  heading_month: {
    marginBottom: '10px',
    fontWeight: 400,
    fontSize: '25px',
  },
  heading_year: {
    fontWeight: 500,
    fontSize: '12.5px',
    color: 'gray',
  },
  inner_heading: {
    marginBottom: '20px',
    color: '#1e201f',
    fontSize: '22px',
  },
  summary: {
    color: 'rgb(255, 185, 81)',
    fontWeight: 100,
    margin: '20px 0 20px 0',
  },
  detail: {
    color: '#7A92AD',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    fontWeight: 'normal',
    marginLeft: 10,
    marginBottom: 3,
    padding: '0 5px',
  },
  cell: {
    borderBottom: 'none',
    padding: '1px 35px 5px 0px',
    color: '#323232',
    fontWeight: 500,
    fontSize: '14px',
  },
  amount_status: {
    color: '#323232',
    fontWeight: 500,
    fontSize: '14px',
  },
  amount_info_item: {
    padding: '0 0 0 40px',
  },
  amount_item: {
    borderRight: '1px solid lightgray',
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(1),
      width: '100%',
    },
    amount_item: {
      borderLeft: 'none',
      borderRight: 'none',
      margin: '10px 10px 10px 10px',
      padding: '0',
    },
  },
}))

function AmountCenter() {
  const classes = useStyle()
  const { state } = useContext(CorpContext)
  const [open, setOpen] = useState(false)
  const [detail, setDetail] = useState({})

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = (info) => {
    setDetail(info)
    setOpen(true)
  }

  function getPaymentNowMonth() {
    return moment().month() + 1
  }
  function getPaymentStatusTitle(str) {
    switch (str) {
      case 'PENDING':
        return '未結帳總車資'
      case 'FINISHED':
        return '結帳金額'
      default:
        return ''
    }
  }
  function getPaymentStatus(str) {
    switch (str) {
      case 'PENDING':
        return '尚未付款'
      case 'FINISHED':
        return '已付款'
      default:
        return ''
    }
  }

  function ExportButtom(props) {
    const { item } = props
    if (getPaymentNowMonth() !== item.time.toMonth()) {
      return (
        <Grid container justify="flex-end">
          <Grid item>
            <ExportTripAmountTableButton summery={item} />
            <ExportAmountTableButton summery={item} />
            <FetchInvoiceButton summary={item} company={state.company?.[0]} />
          </Grid>
        </Grid>
      )
    } else {
      return <div className={classes.cell} />
    }
  }

  function getPlanDescription(amount, value) {
    if (value) {
      const agreement = JSON.parse(value)
      let str = `總額 ${amount} 元 `
      if (!agreement) {
        return `( ${str} )`
      }
      if (agreement.company_discount_amount && agreement.company_discount_amount !== 0) {
        str += `，優惠 ${agreement.company_discount_amount} 元`
      }
      if (agreement.company_fee_amount && agreement.company_fee_amount !== 0) {
        str += `，服務費 ${agreement.company_fee_amount} 元`
      }
      return `( ${str} )`
    } else {
      return ''
    }
  }

  function getPlanValus(agreement, type) {
    if (!agreement) {
      return '沒有適合方案'
    }
    const mAgreement = JSON.parse(agreement)
    const plan = type === 1 ? mAgreement.company_fee : mAgreement.company_discount
    if (plan) {
      let str = ''
      if (plan.plan_value === 0) {
        str += '為零'
      } else if (plan.plan_value < 1) {
        str += ` ${Math.floor(plan.plan_value * 100)}%\n`
        str += '最多 '
        str += plan.max_value === 0 ? '沒有上限' : `${plan.max_value} 元`
        str += '\n'
      } else if (plan.plan_value >= 1) {
        str += `${plan.plan_value} 元`
      } else {
        str += '未知'
      }
      return str
    }
    return 0
  }

  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography variant="h6" className={classes.heading} style={{ textAlign: 'left' }}>
          帳務中心
        </Typography>
        <Typography variant="h6" className={classes.inner_heading} style={{ textAlign: 'left' }}>
          {state.company[0].name}
        </Typography>
        <ReceiptDescription />
        {state.summary.map((item) => {
          return (
            <div key={item.time}>
              <Paper className={classes.inner_paper}>
                <Typography className={classes.heading_month}>
                  {item.time.toMonth()} <span className={classes.heading_year}>月 {item.time.toYear()} 年</span>{' '}
                </Typography>
                <Grid container>
                  <Grid xs={6} item className={classes.amount_item}>
                    <div className={classes.amount_status}>
                      {getPaymentStatusTitle(item.status)}
                      {getPlanDescription(item.deductibleAmount, item.agreement)}
                    </div>
                    <Typography variant="h1" className={classes.summary}>
                      {item.final_amount}
                    </Typography>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.cell}>收據總金額</TableCell>
                          <TableCell className={classes.cell}>
                            {item?.receipt_info?.final_amount || 0} 元
                            <Link
                              component="button"
                              onClick={handleOpen.bind(null, { ...item.receipt_info, receipt_type: 'RECEIPT', time: item.time })}
                              className={classes.detail}
                            >
                              看明細
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>發票總金額</TableCell>
                          <TableCell className={classes.cell}>
                            {item?.invoice_info?.final_amount || 0} 元
                            <Link
                              component="button"
                              onClick={handleOpen.bind(null, { ...item.invoice_info, receipt_type: 'INVOICE', time: item.time })}
                              className={classes.detail}
                            >
                              看明細
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>服務費</TableCell>
                          <TableCell className={classes.cell}>{getPlanValus(item.agreement, 1)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>優惠折扣</TableCell>
                          <TableCell className={classes.cell}>{getPlanValus(item.agreement, 2)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>繳款期限</TableCell>
                          <TableCell className={classes.cell}>
                            {item.time
                              .toEndDate(state.company[0].pending_statement_month, state.company[0].pending_statement_day)
                              .format('M 月 DD 日')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>付款狀態</TableCell>
                          <TableCell className={classes.cell}>{getPaymentStatus(item.status)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>核准 {item.review_accepted} 筆</TableCell>
                          <TableCell className={classes.cell}>{item.review_accepted_amount} 元</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>拒絕 {item.review_reject} 筆</TableCell>
                          <TableCell className={classes.cell}>{item.review_reject_amount} 元</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell}>待審 {item.review_pending} 筆</TableCell>
                          <TableCell className={classes.cell}>{item.review_pending_amount} 元</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid xs={6} item className={classes.amount_info_item}>
                    <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 500 }}>
                      各群組 公司支付乘車金額
                    </Typography>
                    <Table>
                      <TableBody>
                        {state.groups_summary.map((itemD) => {
                          if (itemD.summary.MONTH === item.time.toMonth()) {
                            return (
                              <TableRow key={itemD.name}>
                                <TableCell className={classes.cell}>
                                  {itemD.name} {itemD.deprecated ? '（已刪除）' : ''}
                                </TableCell>
                                <TableCell className={classes.cell}>{itemD.summary.deductibleAmount}</TableCell>
                              </TableRow>
                            )
                          }
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Paper>
              <ExportButtom item={item} />
            </div>
          )
        })}
      </div>
      <ReceiptModal open={open} handleClose={handleClose} info={detail} />
    </React.Fragment>
  )
}

export default AmountCenter
