import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableRow,
  TableCell,
  Checkbox,
  Menu,
  MenuItem,
  Popover,
} from '@material-ui/core'
import { generalStyle } from '../../styles'
import MaxWidthForTableCell from '../Table/MaxWidthForTableCell'
import { withStyles } from '@material-ui/styles'
import Auth from '../../model/Auth'

const useStyle = makeStyles((theme) => ({
  checkBox: {
    zIndex: 0,
    width: '25px',
    wordWrap: 'break-word',
    '&:hover': {
      background: 'unset',
    },
  },
  selected: {
    backgroundColor: '#f5f5f5 !important',
  },
  arrowIcon: {
    height: '16px',
    width: '16px',
    marginLeft: '8px',
  },
}))

const CustomCheckBox = withStyles({
  root: {
    color: '#4b4d4c',
    '&$checked': {
      color: '#7a92ad',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

function ReviewTableCell(props) {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const {
    row,
    index,
    selectedReview,
    handleUpdateSelectedReview,
    handleUpdateReviewStatus,
    handleOpenModal,
  } = props
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef(null)
  const labelId = `enhanced-table-checkbox-${index}`

  useEffect(() => {
    handleInitSelected()
  }, [row, selectedReview])

  const handleClick = (event, name, isItemSelected) => {
    isItemSelected ? handleRowDeselected(name) : handleRowSelected(name)
  }

  function handleClose(event, option) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (event.target.id !== '') {
      handleUpdateReviewStatus(row.id, option.reviewStatusTWLocalized())
    }
    setOpen(false)
  }

  const handleInitSelected = () => {
    if (selectedReview.includes(row.id)) {
      setSelected(selected.concat(row.id))
    } else {
      setSelected([])
    }
  }

  const handleRowSelected = (id) => {
    setSelected(selected.concat(id))
    handleUpdateSelectedReview('selected', id)
  }

  const handleRowDeselected = (id) => {
    setSelected(selected.filter((el) => el !== id))
    handleUpdateSelectedReview('deselected', id)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const isItemSelected = isSelected(row.id)

  function handleToggle() {
    if (row.status === 'COMPLETED') return
    setOpen((prevOpen) => !prevOpen)
  }

  function handleStatusMenu() {
    const options = []
    let actions = []

    switch (row.personal_review_history) {
      case 'PENDING':
        actions = ['核准', '拒絕']
        break
      case 'APPROVED':
        actions = ['拒絕']
        break
      case 'REJECTED':
        actions = ['核准']
        break
      default:
        break
    }

    options.push(
      actions.map((option) => (
        <MenuItem id={option} onClick={(e) => handleClose(e, option)}>
          {option}
        </MenuItem>
      ))
    )
    return options
  }

  const handleStatusText = () => {
    if (row.isInTrip && row.status !== 'COMPLETED') return '行程中'
    return row.status.reviewStatusLocalized()
  }

  return (
    <>
      <TableRow
        selected={isItemSelected}
        classes={{ selected: classes.selected }}
        hover
      >
        <TableCell
          className={generalClasses.cell}
          padding="none"
          style={{ minWidth: '36px', borderBottom: 'none' }}
        >
          <CustomCheckBox
            className={classes.checkBox}
            checked={isItemSelected}
            disableRipple
            inputProps={{
              'aria-labelledby': labelId,
            }}
            onClick={(event) => handleClick(event, row.id, isItemSelected)}
            key={row.trips}
          />
        </TableCell>
        <MaxWidthForTableCell
          context={row.number_code}
          minWidth="100px"
          maxWidth="150px"
          textCut
          handleOnClick={handleOpenModal}
        />
        <MaxWidthForTableCell
          context={moment(row.create_unix_timestamp * 1000).format(
            'YYYY/MM/DD HH:mm'
          )}
          minWidth="140px"
          handleOnClick={handleOpenModal}
        />
        <MaxWidthForTableCell
          context={`${row.group_name}`}
          minWidth="105px"
          handleOnClick={handleOpenModal}
        />
        <MaxWidthForTableCell
          context={row.applicant}
          minWidth="100px"
          maxWidth="150px"
          textCut
          handleOnClick={handleOpenModal}
        />
        <MaxWidthForTableCell
          context={moment(row.trip_reserve_unix_timestamp * 1000).format(
            'YYYY/MM/DD HH:mm'
          )}
          minWidth="140px"
          handleOnClick={handleOpenModal}
        />
        <MaxWidthForTableCell
          custom={
            (!isEmpty(row.custom_fields) && row.custom_fields[0].content) ||
            '無'
          }
          subContext={
            row.custom_fields && row.custom_fields.length > 1 && '...更多'
          }
          minWidth="130px"
          maxWidth="150px"
          textCut
          customCut
          handleOnClick={handleOpenModal}
        />
        <TableCell
          className={generalClasses.cell}
          padding="none"
          style={{
            color: row.personal_review_history.reviewStatusColor(),
            minWidth: '100px',
            borderBottom: 'none',
          }}
          ref={anchorRef}
          onClick={handleToggle}
        >
          {row.personal_review_history.reviewStatusLocalized()}
          {row.status !== 'COMPLETED' && (
            <img
              className={classes.arrowIcon}
              src="https://static.taxigo-cdn.com/icons/general/arrow-down.svg"
              alt=""
            />
          )}
        </TableCell>
        <TableCell
          className={generalClasses.cell}
          padding="none"
          style={{
            color: row.status.reviewStatusColor(),
            minWidth: '80px',
            borderBottom: 'none',
          }}
          handleOnClick={handleOpenModal}
        >
          {handleStatusText()}
        </TableCell>
      </TableRow>
      <Popover
        id="options-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
      >
        {handleStatusMenu()}
      </Popover>
    </>
  )
}

export default React.memo(ReviewTableCell)
