import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyle = makeStyles((theme) => ({
  menu_list: {
    padding: '16px 40px 16px 24px',
    maxHeight: '50vh',
  },
  menu_item: {
    padding: '8px 40px 8px 24px',
    width: '100%',
    '& span': {
      fontWeight: 600,
    },
  },
  option: {
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
  },
}))

function DropdownMenuList(props) {
  const {
    id,
    open,
    setOpen,
    anchorRef,
    options,
    handleMenuListAction,
    ...other
  } = props

  const classes = useStyle()

  function handleClose(event, id, option) {
    if (id !== '') {
      handleMenuListAction(id, option)
    }
    setOpen()
  }

  return (
    <React.Fragment>
      <Menu
        id={id}
        anchorEl={anchorRef}
        open={Boolean(open === id)}
        onClose={handleClose}
        PaperProps={{
          style: { maxHeight: '50vh', width: '300px' },
        }}
      >
        {options &&
          options.options.map((item) => (
            <MenuItem
              id={item.option_index}
              className={classes.menu_item}
              onClick={(event) => handleClose(event, options.id, item.option)}
            >
              <div className={classes.option}>{item.option}</div>
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  )
}

export default DropdownMenuList
