import axios from 'axios'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: (status) => {
    return status >= 200 && status <= 500
  },
})

API.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

// Add a response interceptor
API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('API.interceptors.response - response:')
    if (response.status !== 200) {
      const errorMessage = response.data.message || response.data.msg
      console.error(`url:${response.request.responseURL} error`, `errorMessage:${errorMessage}`, `status code:${response.status}`)
    }
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log('API.interceptors.response - error:')
    console.error(error)
    error.response && console.error(error.response.request.responseURL, error)
    return Promise.reject(error)
  },
)

export const getGeneralInfo = () =>
  API.get(
    `/company?q={
				users { groups, history, company_trips { trips {driver_info } } },
				all_users,
        groups { summary },
        summary,
        company
        }`,
  )

export const getPersonalInfo = () =>
  API.get(
    `/company?q={
        users(id:"me") {groups, history, company_trips { trips {driver_info } } },
				trips { users { groups }, driver_info },
				bulletin { corp, company, system },
				groups,
        company
        }`,
  )

export const getAllMember = (query_url) =>
  API.get(`/company?q={
        all_users${query_url}
				}`)

export const getMemberFilter = (query_url, group_id = '') =>
  API.get(`/company?q={
        users${query_url} { groups ${group_id}}
				}`)

export const getTripFilter = (trip_query, users_query, groupsQuery) =>
  API.get(`/company?q={
			trips${trip_query} { users${users_query} { groups${groupsQuery} }, driver_info }
			}`)

export const getStatisticsFilter = (query_url, group_id) =>
  API.get(`/company?q={
			users${query_url} { groups ${group_id}, history }
			}`)

export const queryInfo = (properties) => API.get(`/company?q={${properties}}`)
export const getCustomField = (id, active) => API.get(`/company/custom_field?company=${id}`) // 取得公司備註
export const getReviewTicket = (param) => API.get(`/company/review_ticket?${param}`)
export const getReviewTicketById = (ticketId) => API.get(`/company/review_ticket/${ticketId}`)

export const postCreateUser = (body) => API.post(`/company/users`, body) // 建立成員
export const postEmailVerify = (body) => API.post(`/company/users/mail_verify`, body)
export const postCreateUsers = (body) => API.post(`/company/users`, body)
export const postCompanyGroup = (body) => API.post(`/company/group`, body) // 建立群組
export const postUserPermission = (user_id, body) => API.post(`/company/user_permission/${user_id}`, body) // 建立 admin 權限
export const postUsersGroup = (group, id) => API.post(`/company/users_group/${group}/user/${id}`) // 新增成員到群組
export const postResetPassword = (body) => API.post(`/reset_password`, body) // 登入頁重設密碼
export const postResetOtp = (body) => API.post(`/request_reset_otp`, body) // 取得忘記密碼的驗證碼
export const postVerifyOtp = (body) => API.post(`/verify_otp`, body) // 檢查驗證碼
export const postSignin = (body) => API.post(`/signin/company`, body) // 登入
export const postBatchGroup = (body) => API.post(`/company/batch/group`, body) // 匯入群組
export const postBatchUser = (body) => API.post(`/company/batch/user`, body) // 匯入成員
export const postReviewTicket = (body, ticketId) => API.post(`/company/review_ticket/review/${ticketId}`, body) // 審核單筆
export const postReviewTicketBatch = (body) => API.post(`/company/review_ticket/review/batch`, body) // 審核多筆

export const putCompanyGroup = (group_id, body) => API.put(`/company/group/${group_id}`, body) // 更新群組
export const putTripReview = (body) => API.put(`/company/trips`, body)
// export const putMember = (id, body) => API.put(`/company/users/${id}`, body)
export const putUsersUnit = (id, body) => API.put(`/company/users_unit/${id}`, body) // 更新成員 only admin
export const putUsersGroup = (group, id, body) => API.put(`/company/users_group/${group}/user/${id}`, body) // 更新群組成員資訊
export const putReviewNotes = (company_id, user_id, body) => API.put(`/company/${company_id}/users/${user_id}`, body)
export const putUserPermission = (user_id) => API.put(`/company/user_permission/${user_id}`) // 更新 admin 權限
export const putUserPassword = (body) => API.put(`/users/password`, body) // 個人檔案變更密碼
export const putSortCustomField = (id, move, body) => API.put(`/company/custom_field/${id}/sort/${move}`, body) // 備註列表排序

export const deleteCompanyGroup = (group_id) => API.delete(`/company/group/${group_id}`) // 刪除群組
export const deleteUsersUnit = (user_id, body) => API.delete(`/company/users_unit/${user_id}`, { data: body }) // 刪除成員
export const deleteUserPermission = (user_id, body) => API.delete(`/company/user_permission/${user_id}`, { data: body }) // 移除 admin 權限
export const deleteUserFromGroup = (group, id) => API.delete(`/company/users_group/${group}/user/${id}`) // 移出群組
export const deleteCustomField = (body) => API.delete(`/company/custom_field`, { data: body })

export const patchCustomField = (body) => API.patch(`/company/custom_field`, body)

export const clearToken = (token_name) => API.post(`/cookie/clean/${token_name}`)

export const postSheetCompany = (body) => API.post(`/company_sheet`, body) // 表單申請建立公司

export const getTripByHash = (trip_hash) => API.get(`/company?q={trips (trip_hash:${trip_hash}) { users { groups }, users, driver_info }}`)
export const getCompanyBillRecipients = (params) => API.get('/company/bill/recipients', { params: { ...params } })
export const putCompanyBillRecipients = (body) => API.put('/company/bill/recipients', body)
export const getCompanyMemberAutoComplete = (id, value) => API.get(`/company?q={auto_complete(companyId: "${id}" , ${value ? `email_prefix: "${value}"` : ''})}`)
export const getInvoiceEmailContent = (body) => API.post('/receipt/company/invoice_notification_email', body)

export const rejectTrip = (trip_id) => API.post(`/v3/company/trips/${trip_id}/rejections`)
export const getConfig = () => API.get('/config')
