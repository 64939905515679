import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  stepItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  statusImg: {
    width: '120px',
    height: '120px',
    marginBottom: '16px',
    // borderRadius: '50%'
  },
  status: {
    width: '240px',
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center',
    '& span': {
      fontWeight: 500,
      color: '#7a92ad',
      cursor: 'pointer',
    },
  },
  processingDot: {
    position: 'absolute',
    right: -48,
    top: 56,
  },
}))

export default function StatusModal(props) {
  const classes = useStyles()
  const { step, itemStatus, uploadScript, exportFailedList } = props
  const [description, setDrescription] = useState({})
  const [desColor, setDesColor] = useState('')

  useEffect(() => {
    let color = '#d2d2d2'
    switch (step[itemStatus]) {
      case 'processing':
        setDrescription(uploadScript.description)
        color = '#7a92ad'
        break
      case 'finished':
        setDrescription(uploadScript.sec_description)
        color = '#4b4d4c'
        break
      case 'error':
        setDrescription(uploadScript.error_msg)
        color = '#ec4d4d'
        break
      case 'auth_error':
        setDrescription(uploadScript.auth_error_msg)
        color = '#ec4d4d'
        break
      default:
        setDrescription(uploadScript.description)
        break
    }
    setDesColor(color)
    return () => {}
  }, [step])

  const handleStatusClick = () => {
    if (step[itemStatus] !== 'error') return
    exportFailedList()
  }

  return (
    <div className={classes.stepItem}>
      <img
        className={classes.statusImg}
        src={`https://static.taxigo-cdn.com/icons/business/${uploadScript.img}`}
        alt=""
      />
      <div
        className={classes.status}
        style={{ color: desColor }}
        onClick={() => handleStatusClick()}
        dangerouslySetInnerHTML={{
          __html: `${description}`,
        }}
      ></div>
      {step[itemStatus] === 'finished' && !uploadScript.type && (
        <img
          className={classes.processingDot}
          src="https://static.taxigo-cdn.com/icons/business/processing-dot.svg"
          alt=""
        />
      )}
    </div>
  )
}
