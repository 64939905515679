import moment from 'moment'

const roleObj = {
  ADMIN: '管理者',
  MANAGER: '主管',
  MEMBER: '成員',
}

const roleTWObj = {
  管理者: 'ADMIN',
  主管: 'MANAGER',
  成員: 'MEMBER',
}

String.prototype.roleLocalized = function () {
  return roleObj[this] || '成員'
}

String.prototype.roleTWLocalized = function () {
  return roleTWObj[this] || 'MEMBER'
}

String.prototype.rolePreType = function () {
  const value = this
  const origin_value =
    Object.keys(roleObj).filter(function (key) {
      console.log('Localizesss' + roleObj[key])
      return roleObj[key] == value
    })[0] || 'MEMBER'
  return origin_value
}

String.prototype.roleColor = function () {
  switch (this) {
    case 'ADMIN':
      return 'rgb(94, 194, 26)'
    case 'MANAGER':
      return 'rgb(226, 187, 59)'
    case 'MEMBER':
      return ''
    default:
      return ''
  }
}

const tripStatusObj = {
  PENDING: '待審',
  REJECT: '拒絕',
  ACCEPTED: '核准',
}

String.prototype.tripStatusLocalized = function () {
  return tripStatusObj[this] || '待審'
}

String.prototype.actionLocalized = function (type) {
  switch (this) {
    case '新增':
      return 'CREATE'
    case '變更':
      return 'UPDATE'
    case '刪除':
    case '封鎖':
      return 'DELETE'
    case 'DELETE':
      return type === 'group' ? '刪除' : '封鎖'
    case 'UPDATE':
      return '變更'
    case 'CREATE':
      return '新增'
    default:
      return ''
  }
}

String.prototype.reviewNotesStatus = function () {
  switch (this) {
    case '是':
      return 1
    case '否':
      return 0
    default:
      break
  }
}

String.prototype.tripStatusPreType = function () {
  const value = this
  const origin_value =
    Object.keys(tripStatusObj).filter(function (key) {
      console.log('Localizesss' + tripStatusObj[key])
      return tripStatusObj[key] == value
    })[0] || 'PENDING'
  return origin_value
}

String.prototype.tripStatusColor = function () {
  switch (this) {
    case 'PENDING':
      return '#1e201f'
    case 'REJECT':
      return '#ec4d4d'
    case 'ACCEPTED':
      return '#00cba0'
    default:
      return ''
  }
}

String.prototype.reviewStatusLocalized = function () {
  return reviewStatusObj[this] || ''
}

String.prototype.reviewStatusTWLocalized = function () {
  return reviewStatusTWObj[this] || ''
}

String.prototype.reviewStatusColor = function () {
  return reviewStatusColorObj[this] || '#1e201f'
}

const userStatusObj = {
  PENDING: '未驗證',
  BLOCKED: '封鎖中',
  ACCEPTED: '已註冊',
}

const reviewStatusObj = {
  PENDING: '待審核',
  REJECTED: '拒絕',
  APPROVED: '核准',
  EXPIRED: '已過期',
  COMPLETED: '已搭乘',
}

const reviewStatusColorObj = {
  REJECTED: '#ec4d4d',
  APPROVED: '#00cba0',
  EXPIRED: '#bbbcbb',
}

const reviewStatusTWObj = {
  待審核: 'PENDING',
  拒絕: 'REJECTED',
  核准: 'APPROVED',
  已過期: 'EXPIRED',
  已搭乘: 'COMPLETED',
}

String.prototype.userStatusLocalized = function () {
  return userStatusObj[this] || '未驗證'
}

String.prototype.userStatusPreType = function () {
  const value = this
  const origin_value =
    Object.keys(userStatusObj).filter(function (key) {
      console.log('Localizesss' + userStatusObj[key])
      return userStatusObj[key] == value
    })[0] || 'PENDING'
  return origin_value
}

String.prototype.userStatusColor = function () {
  switch (this) {
    case 'PENDING':
      return '#fe743d'
    case 'BLOCKED':
      return '#ec4d4d'
    default:
      return ''
  }
}

Number.prototype.toYear = function () {
  return moment(this * 1000).year()
}

Number.prototype.toMonth = function () {
  return moment(this * 1000).month() + 1
}

Number.prototype.totEndMonthDay = function () {
  return moment(this * 1000)
    .endOf('month')
    .date()
}

Number.prototype.toEndDate = function (month, day) {
  return moment(this * 1000)
    .add(month, 'month')
    .date(day)
}
Number.prototype.totUnix = function () {
  return moment(this * 1000).unix() + ''
}
