/* eslint-disable no-new-object */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { CorpContext } from '../reducers/Main'
import {
  getMemberFilter,
  putUsersGroup,
  postUsersGroup,
  deleteUserFromGroup
} from '../api'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  TableRow,
  CircularProgress,
  TablePagination
} from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import IconButton from '../view/Buttons/IconButton'
import FilterButtonWithClear from '../view/Buttons/FilterButtonWithClear'
import EditGroupMemberCell from '../view/Table/EditGroupMemberCell'
import ExportUserDetail from '../view/Buttons/ExportUserDetail'
import MSortTable from '../view/Table/MSortTable'
import Toast from '../view/Pop/Toast'
import GroupMemberForm from '../view/Form/GroupMemberForm'
import MemberSettingTableCell from '../view/Table/MemberSettingTableCell'
import Auth from '../model/Auth'
import { generalStyle } from '../styles'
import { handleSort } from '../utils'

const useStyle = makeStyles(theme => ({
  memberContainer: {
    overflow: 'auto',
    height: '100vh'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  },
  loading: {
    color: '#797979'
  },
  back: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#2d2f2e',
    textDecoration: 'none',
    display: 'flex',
    marginBottom: '8px',
    '& img': {
      width: '16px'
    }
  },
  des: {
    color: '#4b4d4c',
    fontSize: '14px',
    marginBottom: '40px',
    letterSpacing: '1.2px'
  }
}))

const headRow = [
  { id: 'group_name', numeric: 'left', label: '群組', hide: false },
  { id: 'role', numeric: 'left', label: '角色', hide: false },
  { id: 'name', numeric: 'left', label: '姓名(信箱)', hide: false },
  { id: 'quota', numeric: 'left', label: '預算/月', hide: false },
  { id: 'trip_quota', numeric: 'left', label: '單趟上限', hide: false },
  { id: 'review_notes', numeric: 'left', label: '開啟備註', hide: false },
  { id: 'action', numeric: 'center', label: '個人設定', hide: false }
]

function MemberSetting() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const { state, dispatch } = useContext(CorpContext)

  const [company, setCompany] = useState(null)
  const [memberList, setMemberList] = useState([])
  const [group_f, setGroup] = useState('')
  const [member_f, setMember] = useState('所有成員')
  const [member_id, setMemberID] = useState(0)
  const [memberFilterList, setMemberFilterList] = useState([])
  const [selected, setSelected] = useState([])
  const isSelected = name => selected.indexOf(name) !== -1
  const createRef = useRef('none')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [editRow, setEditRow] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [timeoutID, setTimeoutID] = useState(undefined)

  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const params = new URLSearchParams(useLocation().search)

  // useEffect(() => {
  //   setMemberFilterList(handleSort(state.all_member, 'asc', 'name'))
  //   setCompany(state.company[0].id)
  // }, [state.all_member, state.company])

  useEffect(() => {
    setMemberFilterList(handleSort(memberList, 'asc', 'name'))
    setCompany(state.company[0].id)
  }, [memberList, state.company])

  useEffect(() => {
    realtimeSearch(true)
  }, [group_f, member_id, state.member])

  useEffect(() => {
    const paramsGroup = state.groups.find(
      item => item.id === parseInt(params.get('group'))
    )
    // console.log(paramsGroup)
    setGroup({ id: paramsGroup.id, name: paramsGroup.name })
  }, [])

  async function realtimeSearch(resetPage = false) {
    const usersOject = new Object()
    if (group_f !== '所有群組') {
      usersOject.group = group_f.id
    } else {
      delete usersOject.group
    }
    if (parseInt(member_id) !== 0 && member_id !== undefined) {
      usersOject.id = member_id
    } else {
      delete usersOject.id
    }

    const queryString = []
    for (const key in usersOject) {
      queryString.push(`${key}:"${encodeURIComponent(usersOject[key])}"`)
    }
    const group_id = usersOject.group ? `(group_id:${group_f.id})` : ''

    const data = await getMemberFilter(query(queryString), group_id)
    if (data.status !== 200) {
      setLoading(false)
      return
    }

    const users = data.data.data.users // 提醒後端空的請回 []，不要回 {}
    let filterUsers = []
    users.forEach(item => {
      if (!item.group_name) return
      filterUsers.push(item)
    })

    setMemberList(filterUsers)
    resetPage && setPage(0)
    setLoading(false)
  }

  function query(arr) {
    if (!arr.length) return ''
    return `(${arr.join(',')})`
  }

  async function removeMemberFromGroup(group, id) {
    const data = await deleteUserFromGroup(group, id)
    console.log(data)
    if (data.status !== 200) return handleAlertBar('移出群組失敗', 'error')
    handleAlertBar('移出群組成功')
    realtimeSearch()
  }

  async function handlePostUsersGroup(user) {
    const data = await postUsersGroup(user.group, user.member)
    console.log(data)
    if (data.status !== 200) return handleAlertBar('更新成員失敗', 'error')
    handleAlertBar('更新成員成功')
    realtimeSearch()
  }

  async function updateReviewNotes(e, user, updateSwitch) {
    const checked = e.target.checked
    const updateMember = cloneDeep(memberList)
    updateSwitch(checked)
    updateMember.map(row => {
      if (user.id === row.id && user.group === row.group) {
        row.review_notes = checked
      }
      return row
    })
    const body = { review_notes: checked ? 1 : 0 }
    const data = await putUsersGroup(user.group, user.id, body)
    if (data.status !== 200 || !user.group) {
      clearTimeout(timeoutID)
      setShowToast(true)
      updateSwitch(!checked)
      const currentimeoutID = setTimeout(() => {
        setShowToast(false)
      }, 3000)
      setTimeoutID(currentimeoutID)
      return
    }
    dispatch({ type: 'UPDATE_MEMBER', member: updateMember })
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type }
    })
  }

  function implementActions(action, row) {
    switch (action) {
      case '編輯':
        setEditRow(row)
        break
      case '移出群組':
        removeMemberFromGroup(row.group, row.id)
        break
      default:
        break
    }
  }

  function addSearch(field, option) {
    switch (field) {
      case 'group':
        setGroup(option)
        break
      case 'member':
        setMember(option.name)
        setMemberID(option.id)
        break
      default:
        return
    }
  }

  function triggerClearSearch() {
    // setGroup('所有群組') // 群組成員限定
    setMember('所有成員')
    setMemberID(0)
  }

  function handleTripQuota(trip_quota) {
    if (
      ['', '無上限', '0'].includes(trip_quota) ||
      parseInt(trip_quota) >= 1000000
    ) {
      return -1
    }
    return parseInt(trip_quota)
  }

  function editOnClick(values, role) {
    console.log(values)
    // return
    const { group, quota, trip_quota } = values
    if ([group, role].includes('')) {
      handleAlertBar('欄位請勿空白或未選擇。', 'error')
      return
    }
    const body = {
      new_group: parseInt(group.id),
      role: role,
      quota: quota,
      trip_quota: handleTripQuota(trip_quota) || -1
    }
    updateMember(setEditRow, editRow, body, realtimeSearch)
  }

  async function updateMember(setEditRow, editRow, body, realtimeSearch) {
    const data = await putUsersGroup(editRow.group, editRow.id, body)
    if (data.status !== 200)
      return handleAlertBar('更新成員失敗，請稍後再試。', 'error')
    setEditRow('')
    handleAlertBar('更新成員成功')
    realtimeSearch()
  }

  function editCancel() {
    setEditRow('')
  }

  function handleAddUserInGroup(values) {
    const checkArray = [group_f.id, values.member.id]
    if (checkArray.filter(item => item.length == 0).length == 2) {
      clickCreateButton()
      return
    }
    if (checkArray.includes('')) {
      handleAlertBar('欄位請勿請確認欄位皆已填妥。', 'error')
      return
    }
    const user = {
      group: group_f.id,
      member: values.member.id
    }

    handlePostUsersGroup(user)
  }

  function clickCreateButton() {
    if (createRef.current.style.display === 'none') {
      createRef.current.style.display = ''
    } else if (createRef.current.style.display === '') {
      createRef.current.style.display = 'none'
    }
  }

  function printRow(row, index) {
    const isItemSelected = isSelected(row.id)
    return (
      <TableRow
        hover
        role="checkbox"
        className={generalClasses.row}
        aria-checked={isItemSelected}
        tableIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        {renderEditRow(row, index)}
      </TableRow>
    )
  }

  function renderEditRow(row, index) {
    if (row.name === editRow.name && row.group === editRow.group) {
      return (
        <EditGroupMemberCell
          row={row}
          groups={state.groups}
          editOnClick={editOnClick}
          editCancel={editCancel}
          updateReviewNotes={updateReviewNotes}
        />
      )
    } else {
      return (
        <MemberSettingTableCell
          key={`${index}-${row.name}`}
          row={row}
          implementActions={implementActions}
          updateReviewNotes={updateReviewNotes}
          company={company}
        />
      )
    }
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <React.Fragment>
      <div id="member_container" className={classes.memberContainer}>
        <Toast context="發生錯誤，請稍後再試" status={showToast} />
        <div className={generalClasses.toolbar} />
        <div className={generalClasses.paper} style={{ paddingTop: '24px' }}>
          <Typography
            className={classes.back}
            component={Link}
            to="/groupcenter"
          >
            <img
              // className={classes.backIcon}
              src="https://static.taxigo-cdn.com/icons/business/back-s-black.svg"
            />
            回群組列表
          </Typography>
          <Typography variant="h5" className={generalClasses.heading}>
            {group_f.name}
          </Typography>
          <div className={classes.des}>
            ・可在此新增/移除群組成員，並編輯成員在此群組的個人設定
          </div>
          <Grid container justify="space-between" alignItems="baseline">
            <Grid item>
              <IconButton
                color="green"
                title="新增成員至群組"
                icon="add_circle"
                onClick={clickCreateButton}
              />
            </Grid>
            <Grid item style={{ paddingTop: '3.5px' }}>
              <ExportUserDetail memberSetting memberList={memberList} />
            </Grid>
          </Grid>
          <GroupMemberForm
            state={state}
            open={open}
            memberList={memberList}
            setOpen={setOpen}
            createRef={createRef}
            anchorRef={anchorRef}
            clickCreateButton={clickCreateButton}
            handleAddUserInGroup={handleAddUserInGroup}
          />
          <div className={generalClasses.filter}>
            <Grid container justify="space-between">
              <Grid item>
                {!Auth.getPermission(state, 'DEFAULT') && (
                  <FilterButtonWithClear
                    clearFilter={false}
                    title={group_f}
                    default_title={group_f.name}
                    list_header="選擇群組"
                    options={state.groups}
                    field="group"
                    addSearch={addSearch}
                  />
                )}
                <FilterButtonWithClear
                  title={member_f}
                  default_title="所有成員"
                  list_header="選擇成員"
                  options={memberFilterList}
                  field={'member'}
                  addSearch={addSearch}
                />
              </Grid>
              <Grid item>
                <BasicButton
                  color="#ffffff"
                  background="#4b4d4c"
                  hoverColor="black"
                  title="清除搜尋條件"
                  onClick={triggerClearSearch}
                />
              </Grid>
            </Grid>
          </div>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loading} />
            </div>
          ) : (
            <MSortTable
              loadmore
              list={memberList}
              headRow={headRow}
              printRow={printRow}
              page={page}
              rowsPerPage={rowsPerPage}
            ></MSortTable>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={memberList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MemberSetting
