import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Slide from '@material-ui/core/Slide'

function Toast(props) {
  const { context, status } = props

  const useStyle = makeStyles(theme => ({
    root: {
      position: 'absolute',
      top: '80px',
      left: '50vw',
      backgroundColor: 'rgba(45, 47, 46, 0.9)',
      color: 'white',
      fontSize: '12px',
      fontWeight: '600',
      padding: '8px 16px'
    },
    label: {
      paddingRight: 'unset',
      paddingLeft: '6px'
    },
    icon: {
      margin: 'unset'
    },
    fadeIn: {}
  }))

  const classes = useStyle()

  return (
    <Slide in={status}>
      <Chip
        className={classes.root}
        classes={{ label: classes.label, icon: classes.icon }}
        label={context}
        icon={
          <img
            src="https://static.taxigo-cdn.com/icons/general/ic-s-warning.svg"
            alt=""
          />
        }
      />
    </Slide>
  )
}

export default Toast
