import React, { useContext } from 'react'
import { CorpContext } from '../../reducers/Main'
import { withStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

export default function AlertBar() {
  const { state, dispatch } = useContext(CorpContext)

  const theme = createMuiTheme({
    overrides: {
      MuiSnackbarContent: {
        root: {
          color: state.alert_bar.type === 'error' ? '#FE743D' : 'white'
        }
      }
    }
  })

  const handleClose = () => {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: {}
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={4500}
        open={state.alert_bar.open}
        onClose={handleClose}
        message={state.alert_bar.text}
        style={{ top: '74px' }}
      />
    </ThemeProvider>
  )
}
