import React, { useContext } from 'react'
import { CorpContext } from '../reducers/Main'
import { generalStyle } from '../styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const userStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflow: 'auto',
    width: '100%',
    minHeight: '65vh',
    padding: theme.spacing(8, 20, 8, 10)
  },
  serviceCard: {
    minHeight: '135px',
    display: 'flex',
    margin: '40px 0'
  },
  iconRound: {
    width: '135px',
    height: '135px',
    borderRadius: '50%',
    backgroundColor: '#2d2f2e',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '40px',
    flexShrink: 0
  },
  icon: {
    width: '104px',
    height: '104px'
  },
  content: {
    width: '100%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#1c1c1e',
    lineHeight: 1.5,
    marginBottom: '8px'
  },
  description: {
    fontSize: '14px',
    lineHeight: 1.57,
    color: '#4b4d4c'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#4b4d4c',
    padding: '4px 6px 4px 16px',
    borderRadius: '15px',
    height: '30px',
    color: 'white',
    alignSelf: 'flex-end',
    flexShrink: 0,
    cursor: 'pointer'
  },
  rightArrow: {
    paddingLeft: '4px'
  },
  divider: {
    backgroundColor: '#979797',
    width: '100%',
    height: '1px'
  }
}))

function ContactCenter() {
  const classes = userStyle()
  const generalClasses = generalStyle()

  const { state } = useContext(CorpContext)

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={classes.paper}>
        <Typography variant="h5" className={generalClasses.heading}>
          聯絡我們
        </Typography>
        <div className={classes.serviceCard}>
          <div className={classes.iconRound}>
            <img
              className={classes.icon}
              src="https://static.taxigo-cdn.com/icons/business/contact-cs.svg"
            />
          </div>
          <div className={classes.content}>
            <div className={classes.title}>LINE TAXI 客服中心</div>
            <div className={classes.description}>
              如果有叫車服務、體驗、遺失物等問題，請前往客服中心與我們聯繫，我們將盡快給予您協助。
            </div>
          </div>
          <div
            className={classes.button}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_APP_URI}/cs/cs_center_guest?cs_id=360007935934`,
                '_blank'
              )
            }}
          >
            前往客服中心
            <img
              className={classes.rightArrow}
              src="https://static.taxigo-cdn.com/icons/business/right-arrow-white.svg"
            />
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.serviceCard}>
          <div className={classes.iconRound}>
            <img
              className={classes.icon}
              src="https://static.taxigo-cdn.com/icons/business/contact-email.svg"
            />
          </div>
          <div className={classes.content}>
            <div className={classes.title}>企業簽單 商務服務信箱</div>
            <div className={classes.description}>
              如果有企業簽單後台操作、開通、簽約等功能問題，請透過企業服務信箱與我們聯繫。
            </div>
          </div>
          <div
            className={classes.button}
            onClick={() =>
              window.open(
                'mailto:business@linego.me?subject=LINE TAXI 企業簽單',
                '_blank'
              )
            }
          >
            企業服務信箱
            <img
              className={classes.rightArrow}
              src="https://static.taxigo-cdn.com/icons/business/right-arrow-white.svg"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContactCenter
