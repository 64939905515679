import React, { useState }  from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { rejectedStatus } from '../constants'
import { Button, Snackbar } from '@material-ui/core'
import RejectTripConfirmModal from '../view/Modal/RejectTripConfirmModal'
import { rejectTrip } from '../api'
import moment from 'moment'

const RejectedSection = ({ content, trip_id }) => {
  const useStyle = makeStyles(theme => ({
    root: {
      padding: '12px 0',
      color: '#4b4d4c',
      display: 'flex'
    },
    title: {
      width: '70px',
      marginRight: '40px',
      fontWeight: 400,
      textAlignLast: 'justify'
    },
    content: {
      fontWeight: 600,
      width: 'calc(100% - 120px)'
    },
  }))

  const classes = useStyle()
  const [open, setOpen] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const history = useHistory()

  const handleRejectTrip = async () => {
    const { status, data } = await rejectTrip(trip_id)
    if (status !== 200) {
      setAlertMessage(`操作失敗: ${data?.message}`)
      setAlert(true)
      setOpen(false)
      return
    }

    setAlertMessage('操作成功')
    setAlert(true)
    history.push('/trips')
  }

  const handleClose = () => {
    setOpen(false)
  }

  const opeartorInfo = () => {
    if (!content?.status || content?.status === 'EXPIRED') return ''
    const rejected_at = content?.rejected_at ? `, ${moment(content?.rejected_at).format('YYYY/MM/DD HH:mm')}` : ''
    return content?.operator ? ` (由 ${content?.operator}${rejected_at} 操作轉為員工自付)` : ''
  }

  const showRejectedButton = () => {
    if (!trip_id) return false
    return content?.rejectable
  }

  const handlePopup = () => setOpen(true)

  return (
    <div className={classes.root}>
      <div className={classes.title}>付款方</div>
      <div className={classes.content}>
        <div>
          <div>
            {rejectedStatus[content?.status] || '企業'}
            {opeartorInfo()}
          </div>
          <div>{content?.pending_label}</div>
        </div>
        {showRejectedButton() && (
          <Button style={{ marginTop: 12 }} variant="contained" onClick={handlePopup}>
            轉為員工自付
          </Button>
        )}
      </div>
      <RejectTripConfirmModal open={open} handleClose={handleClose} handleConfirm={handleRejectTrip} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert}
        onClose={() => setAlert(false)}
        message={alertMessage}
      />
    </div>
  )
}

export default RejectedSection
