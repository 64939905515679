import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

function MaxWidthForTableCell(props) {
  const {
    context,
    subContext,
    minWidth,
    maxWidth,
    color,
    textCut,
    trip_hash,
    textAlign = 'unset',
    group,
    custom,
    customCut,
    handleOnClick,
  } = props

  const useStyle = makeStyles((theme) => ({
    cell: {
      borderBottom: 'none',
      color: color || '#4b4d4c',
      wordWrap: 'break-word',
      minWidth: minWidth || '80px',
      height: '40px',
      paddingRight: '5px',
      textDecoration: 'none',
      maxWidth: maxWidth || 'unset',
      '& span': {
        color: '#797979',
        marginLeft: '10px',
      },
      textAlign: textAlign,
    },
    textCut: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'unset',
    },
    customWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    customCut: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordWrap: 'unset',
    },
  }))

  const classes = useStyle()

  function handleLink() {
    if (trip_hash) {
      return { isLink: Link, path: `/public/tripdetail/${trip_hash}` }
    }
    if (group) {
      return { isLink: Link, path: `/membersetting?group=${group}` }
    }
    return { isLink: null, path: null }
  }

  return (
    <TableCell
      padding="none"
      className={`${classes.cell} ${textCut && classes.textCut} ${
        customCut && classes.customWrap
      }`}
      component={handleLink().isLink}
      to={handleLink().path}
      onClick={handleOnClick && handleOnClick}
    >
      {context}
      {custom && <div className={customCut && classes.customCut}>{custom}</div>}
      {subContext && <span>{subContext}</span>}
    </TableCell>
  )
}

export default MaxWidthForTableCell
