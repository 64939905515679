import React, { useState, useContext, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Grid, Icon } from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import { CorpContext } from '../reducers/Main'
import { getData } from '../router/index'
import { generalStyle } from '../styles'

const useStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 10,
    width: '70vw'
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '150px',
    flexShrink: 0,
    color: 'black',
    fontWeight: 500
  },
  content: {
    fontSize: theme.typography.pxToRem(15),
    color: 'rgb(89, 89, 89)',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  bulletinList: {
    '&hover': {
      background: 'lightgray'
    },
    margin: '20px 0 20px 0',
    display: 'flex'
  },
  input: {
    width: '100%',
    height: '25px',
    margin: '0 10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555'
    },
    fontSize: '1rem'
  },
  textArea: {
    width: '100%',
    height: '400px',
    margin: '0 10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555'
    },
    fontSize: '1rem'
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: '25px',
    color: 'gray',
    textDecoration: 'none'
  },
  linkButton: {
    textDecoration: 'none'
  }
}))

function updateNote(bulletinItem, setBulletinItem, setRedirect, dispatch) {
  if ([bulletinItem.title, bulletinItem.content].includes('')) {
    alert('公告欄位請勿空白。')
    return
  }
  fetch(
    process.env.REACT_APP_API_URI +
      `/company/bulletin/${bulletinItem.field}/${bulletinItem.rowId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        title: bulletinItem.title,
        content: bulletinItem.content
      }),
      credentials: 'include'
    }
  )
    .then(async function(res) {
      if (res.status === 200) {
        alert('更新公告成功。')
        setRedirect('/')
      } else {
        alert('更新公告失敗，請稍後再試。')
      }
      getData(
        dispatch,
        () => {},
        () => {}
      )
    })
    .catch(console.log)
}

function createNote(bulletinItem, setRedirect, dispatch) {
  console.log('send', bulletinItem.title, bulletinItem.content)
  if (bulletinItem.title === '' || bulletinItem.content === '') {
    alert('新增公告欄位請勿空白。')
    return
  }
  fetch(
    process.env.REACT_APP_API_URI + `/company/bulletin/${bulletinItem.field}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        title: bulletinItem.title,
        content: bulletinItem.content
      }),
      credentials: 'include'
    }
  )
    .then(async function(res) {
      if (res.status === 200) {
        alert('新增公告成功。')
        setRedirect('/')
      } else {
        alert('新增公告失敗，請稍後再試。')
      }
      getData(
        dispatch,
        () => {},
        () => {}
      )
    })
    .catch(console.log)
}

function deleteNote(bulletinItem, setRedirect, dispatch) {
  fetch(
    process.env.REACT_APP_API_URI +
      `/company/bulletin/${bulletinItem.field}/${bulletinItem.rowId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      credentials: 'include'
    }
  )
    .then(async function(res) {
      if (res.status === 200) {
        alert('刪除公告成功。')
        setRedirect('/')
      } else {
        alert('刪除公告失敗，請稍後再試。')
      }

      getData(
        dispatch,
        () => {},
        () => {}
      )
    })
    .catch(console.log)
}

function BulletinDetail(props) {
  const classes = useStyle()

  const { state } = useContext(CorpContext)
  const [bulletinItem, setBulletinItem] = useState({
    field: props.match.params.field,
    rowId: props.match.params.id,
    title: '',
    content: ''
  })
  const [displayAction, setDisplayAction] = useState('DISPLAY') //DISPLAY,EDIT.CREATE
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    // corp 企業管理員訊息
    // company 系統給指定企業訊息
    // system 全客戶訊息
    switch (bulletinItem.field) {
      case 'corp':
        setABulletinItem(state.bulletin.corp)
        break
      case 'company':
        setABulletinItem(state.bulletin.company)
        break
      case 'system':
        setABulletinItem(state.bulletin.system)
        break
      case 'empty':
        break
      default:
        break
    }
  }, [state.bulletin])

  useEffect(() => {
    if (props.match.params.field === 'empty') {
      setBulletinItem({ field: 'empty', rowId: '0', title: '', content: '' })
    }
  }, [props.match.params.field])

  useEffect(() => {
    setDisplayAction('DISPLAY')
    state.me.map(el => {
      if (el.role === 'ADMIN' && bulletinItem.field === 'corp') {
        setDisplayAction('EDIT')
      }
    })
    if (bulletinItem.field === 'empty') {
      setDisplayAction('CREATE')
    }
    console.log('bulletinItem' + JSON.stringify(bulletinItem))
  }, [state.me.role, bulletinItem])

  function setABulletinItem(array) {
    array.forEach(element => {
      if (element.id == bulletinItem.rowId) {
        setBulletinItem({
          ...bulletinItem,
          title: element.title,
          content: element.content
        })
      }
    })
  }

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      {displayAction === 'EDIT' ? (
        <BulletinEdit
          bulletinItem={bulletinItem}
          setBulletinItem={setBulletinItem}
          setRedirect={setRedirect}
        />
      ) : (
        ''
      )}
      {displayAction === 'DISPLAY' ? (
        <BulletinDisplay
          bulletinItem={bulletinItem}
          setBulletinItem={setBulletinItem}
          setRedirect={setRedirect}
        />
      ) : (
        ''
      )}
      {displayAction === 'CREATE' ? (
        <BulletinEmpty
          bulletinItem={bulletinItem}
          setBulletinItem={setBulletinItem}
          setRedirect={setRedirect}
        />
      ) : (
        ''
      )}
      {redirect != null ? <Redirect to={redirect} /> : ''}
    </React.Fragment>
  )
}

function BulletinEdit(props) {
  const classes = useStyle()
  const generalClasses = generalStyle()

  const { dispatch } = useContext(CorpContext)
  const { bulletinItem, setBulletinItem, setRedirect } = props
  return (
    <React.Fragment>
      <div className={generalClasses.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              企業公告編輯
            </Typography>
          </Grid>
          <Grid item>
            <Icon component={Link} to={`/`} className={classes.icon}>
              highlight_off
            </Icon>
          </Grid>
        </Grid>
        <div style={{ margin: '40px 0' }}>
          <Grid container spacing={5}>
            <Grid item>
              <Typography className={classes.title}>公告標題</Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                className={classes.input}
                value={bulletinItem.title}
                onChange={e =>
                  setBulletinItem({ ...bulletinItem, title: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item>
              <Typography className={classes.title}>公告內文</Typography>
            </Grid>
            <Grid item xs={8}>
              <textarea
                className={classes.textArea}
                value={bulletinItem.content}
                onChange={e =>
                  setBulletinItem({ ...bulletinItem, content: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6}></Grid>
            <Grid style={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
              <BasicButton
                color="gray"
                hoverColor="black"
                title="取消"
                onClick={() => setRedirect('/')}
              />
              <BasicButton
                color="rgb(239, 139, 139)"
                hoverColor="black"
                title="刪除"
                onClick={() => deleteNote(bulletinItem, setRedirect, dispatch)}
              />
              <BasicButton
                color="orange"
                hoverColor="black"
                title="確認送出"
                onClick={() =>
                  updateNote(
                    bulletinItem,
                    setBulletinItem,
                    setRedirect,
                    dispatch
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  )
}

function BulletinDisplay(props) {
  const classes = useStyle()
  const generalClasses = generalStyle()

  const { bulletinItem } = props
  return (
    <React.Fragment>
      <div className={generalClasses.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              {bulletinItem.title}
            </Typography>
          </Grid>
          <Grid item>
            <Icon component={Link} to={`/`} className={classes.icon}>
              highlight_off
            </Icon>
          </Grid>
        </Grid>
        <div style={{ margin: '40px 0' }}>
          <Typography variant="subheading" style={{ whiteSpace: 'pre-line' }}>
            {bulletinItem.content}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  )
}

function BulletinEmpty(props) {
  const classes = useStyle()
  const { dispatch } = useContext(CorpContext)
  const { bulletinItem, setBulletinItem, setRedirect } = props
  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              新增企業公告
            </Typography>
          </Grid>
          <Grid item>
            <Icon component={Link} to={`/`} className={classes.icon}>
              highlight_off
            </Icon>
          </Grid>
        </Grid>
        <div style={{ margin: '40px 0' }}>
          <Grid container spacing={5}>
            <Grid item>
              <Typography className={classes.title}>公告標題</Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                className={classes.input}
                value={bulletinItem.title}
                onChange={e =>
                  setBulletinItem({ ...bulletinItem, title: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item>
              <Typography className={classes.title}>公告內文</Typography>
            </Grid>
            <Grid item xs={8}>
              <textarea
                className={classes.textArea}
                value={bulletinItem.content}
                onChange={e =>
                  setBulletinItem({ ...bulletinItem, content: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={7}></Grid>
            <Grid style={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
              <BasicButton
                color="rgb(239, 139, 139)"
                hoverColor="black"
                title="取消"
                onClick={() => setRedirect('/')}
              />
              <BasicButton
                color="orange"
                hoverColor="black"
                title="確認送出"
                onClick={() => createNote(bulletinItem, setRedirect, dispatch)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BulletinDetail
