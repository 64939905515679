import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CorpContext } from '../reducers/Main'
import { generalStyle } from '../styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Grid,
} from '@material-ui/core'
import MaxWidthForTableCell from '../view/Table/MaxWidthForTableCell'
import ExportUserTripDetail from '../view/Buttons/ExportUserTripDetail' // 刪掉會爆掉，原因未知
import { rejectedStatus } from '../constants'
import { getCompanyPayment } from '../utils'

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
  table: {
    minWidth: 650,
  },
  paper: {
    overflow: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 10,
    width: '70vw',
  },
  row: {
    textDecoration: 'none',
    '& td:first-child': {
      paddingLeft: '16px',
    },
  },
  headRow: {
    '& th:first-child': {
      paddingLeft: '16px',
    },
  },
  cell: {
    borderBottom: 'none',
    padding: '10px 10px 10px 0',
  },
  tableHead: {
    padding: '15px 0',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
  },
  more: {
    marginTop: '20px',
    textAlign: 'right',
  },
  content: {
    marginTop: '10px',
    fontWeight: 500,
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(1),
      width: '100%',
    },
  },
}))

const headRow = [
  { id: 'reserve_time', numeric: 'left', label: '用車時間', hide: false },
  { id: 'start_address', numeric: 'left', label: '上車地點', hide: false },
  { id: 'end_address', numeric: 'left', label: '下車地點', hide: false },
  { id: 'amount', numeric: 'center', label: '總車資', hide: false },
]

function TripsCard() {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const { state, dispatch } = useContext(CorpContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pendingTrips, setPendingTrips] = useState([])

  const setUpHeader = () => {
    state.config.enable_company_trips_rejection
      ? headRow.push({ id: 'rejected_status', numeric: 'left', label: '付款方', hide: false })
      : headRow.push({ id: 'status', numeric: 'left', label: '狀態', hide: false })
  }

  useEffect(() => {
    setUpHeader()
    return () => headRow.pop()
  }, [])

  useEffect(() => {
    handleTrips()
  }, [state.me])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
  }

  const handleTrips = () => {
    let trips = []
    state.me.forEach((row) =>
      trips.push(...row.company_trips)
    )
    setPendingTrips(trips)
  }

  const handleOpenDetail = (row) => {
    dispatch({
      type: 'UPDATE_TRIP_DETAIL',
      trip_detail: row,
    })
  }

  /**
   * @param {Object} row - response.users[0].commpany_trips[0]
   * @returns {String}
   */
  const rejectedStatusLabel = (row) => {
    const payments = getCompanyPayment(row.transactions || [])
    const rejected_status = rejectedStatus[row.rejected_status] || '企業'
    const unpaidAmount = row.trips?.amount - payments.company - payments.self
    if (unpaidAmount > 0 && rejected_status === '員工') {
      return `員工待支付 ${unpaidAmount}`
    }
    return rejected_status
  }

  return (
    <React.Fragment>
      <Paper className={generalClasses.cardPaper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.heading}>
              個人行程（三個月）
            </Typography>
          </Grid>
        </Grid>
        <TableContainer>
          <Table size="small" aria-labelledby="tableTitle">
            <TableHead>
              <TableRow className={classes.headRow}>
                {headRow.map((row) => {
                  return (
                    <TableCell
                      key={row.id}
                      className={classes.tableHead}
                      align={row.numeric}
                    >
                      {row.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingTrips.length === 0 ? (
                <Typography color="textSecondary" className={classes.content}>
                  目前尚無行程
                </Typography>
              ) : (
                pendingTrips
                  .sort((a, b) =>
                    a.trips?.reserve_time > b.trips?.reserve_time ? 1 : -1
                  )
                  .reverse()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((trip) => {
                    return (
                      <TableRow
                        key={trip.trips.trip_hash}
                        hover
                        className={classes.row}
                        component={Link}
                        to={`/personal/tripdetail/${trip.trips.trip_hash}/${trip.group}`}
                        onClick={() => handleOpenDetail(trip)}
                      >
                        <MaxWidthForTableCell
                          context={moment(
                            trip.trips.reserve_time * 1000
                          ).format('YYYY/MM/DD HH:mm')}
                          minWidth="140px"
                        />
                        <MaxWidthForTableCell
                          context={trip.trips.start_address}
                          minWidth="125px"
                          maxWidth="155px"
                          textCut
                        />
                        <MaxWidthForTableCell
                          context={trip.trips.end_address || '未輸入'}
                          minWidth="125px"
                          maxWidth="155px"
                          textCut
                        />
                        <MaxWidthForTableCell
                          context={trip.trips.amount}
                          minWidth="80px"
                          textAlign="center"
                        />
                        {state.config.enable_company_trips_rejection ? (
                          <TableCell
                            className={classes.cell}
                            style={{ fontSize: trip.rejected_status === 'EXPIRED' ? '.85em' : '1em' }}
                          >
                            {rejectedStatusLabel(trip)}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={classes.cell}
                            style={{
                              color: trip.status.tripStatusColor(),
                              minWidth: '90px',
                            }}
                          >
                            {trip.status.tripStatusLocalized()}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={pendingTrips.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  )
}

export default TripsCard
