import React, { useContext, useEffect, useState } from 'react'
import Bulletin from './Bulletin'
import { makeStyles } from '@material-ui/core/styles'
import ProfileCard from './ProfileCard'
import TripsCard from './TripsCard'
import AmountCard from './AmountCard'
import { CorpContext } from '../reducers/Main'
import Auth from '../model/Auth'

const userStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  overviewContainer: {
    padding: theme.spacing(5, 10)
  },
  '@media (max-width:1200px)': {
    overviewContainer: {
      width: '100%',
      padding: theme.spacing(4, 5)
    }
  },
  overviewBlock: {
    paddingBottom: '32px'
  }
}))

function Overview(props) {
  const classes = userStyle()
  const { state } = useContext(CorpContext)

  useEffect(() => {
    return () => {}
  }, [state.groups])

  function handleRenderAmountCard() {
    return Boolean(
      Auth.getPermission(state, 'ADMIN') && state.summary.length !== 0
    )
  }

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={classes.overviewContainer}>
        <div className={classes.overviewBlock}>
          <Bulletin bulletin={state.bulletin} />
        </div>
        <div className={classes.overviewBlock}>
          <ProfileCard me={state.me} />
        </div>
        {handleRenderAmountCard() && (
          <div className={classes.overviewBlock}>
            <AmountCard />
          </div>
        )}
        <div className={classes.overviewBlock}>
          <TripsCard />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Overview
