import React, { useState }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

const useStyles = makeStyles({
  column: {
    padding: '4px 12px'
  },
});

const ReceiptModal = ({ open, handleClose, info }) => {
  const classes = useStyles()
  const receiptType = info.receipt_type === 'RECEIPT' ? '收據' : '發票'
  const title = info.time ? `${info.time.toYear()}年${info.time.toMonth()}月${receiptType}明細` : '--'

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <table>
          <thead>
            <tr>
              <th className={classes.column}>{receiptType}總金額</th>
              <th className={classes.column}>{info.final_amount || 0} 元</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.column}>{receiptType}車資</td>
              <td className={classes.column}>{info.amount || 0} 元</td>
            </tr>
            <tr>
              <td className={classes.column}>{receiptType}優惠</td>
              <td className={classes.column}>{-info.company_discount_amount || 0} 元</td>
            </tr>
            <tr>
              <td className={classes.column}>{receiptType}服務費</td>
              <td className={classes.column}>{info.company_fee_amount || 0} 元</td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReceiptModal
