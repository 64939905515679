import React, { useState, useRef, useContext, useEffect } from 'react'
import { getRestData } from '../router/index'
import { makeStyles } from '@material-ui/core/styles'
import {
  Icon,
  IconButton,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  TextField,
  Fab,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import CustomIconButton from '../view/Buttons/IconButton'
import { CorpContext } from '../reducers/Main'
import MSortTable from '../view/Table/MSortTable'
import MaxWidthForTableCell from '../view/Table/MaxWidthForTableCell'
import ExportButtonModel from '../view/Buttons/ExportButtonModel'
import UploadGroupModal from '../view/Modal/UploadGroupModal'
import Auth from '../model/Auth'
import {
  postCompanyGroup,
  deleteCompanyGroup,
  putCompanyGroup,
  queryInfo,
} from '../api'
import { onExportGroupExcel } from '../utils'

const useStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  groupListContainer: {
    padding: '0 40px',
  },
  paper: {
    overflow: 'auto',
    // margin: '80px 0 80px 40px',
    // marginLeft: '40px',
    padding: theme.spacing(5, 5),
    borderRadius: 10,
    width: '70vw',
    backgroundColor: 'rgba(224, 229, 235, 0.4)',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
    textAlign: 'start',
  },
  cell: {
    borderBottom: 'none',
    minWidth: '80px',
    paddingRight: '5px',
  },
  row: {
    textDecoration: 'none',
    '& td:first-child': {
      paddingLeft: '16px',
    },
  },
  groupListDes: {
    padding: '40px',
  },
  des: {
    color: '#4b4d4c',
    fontSize: '14px',
    marginBottom: '8px',
    letterSpacing: '1.2px',
  },
  filter: {
    background: 'lightgray',
    width: '80%',
    borderRadius: '24px',
    padding: '24px',
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '12px 20px',
    margin: '8px 0',
    border: '1px solid #ccc',
    borderRadius: '24px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555',
    },
  },
  container: {
    margin: '20px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fabAdd: {
    color: '#f8f8f8',
    background: '#4b4d4c',
    margin: '0 10px',
    width: '32px',
    height: '32px',
    minHeight: 'unset',
  },
  fabCancel: {
    color: '#4b4d4c',
    background: 'unset',
    marginLeft: '10px',
    width: '32px',
    height: '32px',
  },
  exportControl: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media (max-width:1200px)': {
    paper: {
      padding: theme.spacing(4, 5),
      width: '100%',
    },
  },
  createButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

const headRow = [
  { id: 'name', numeric: 'left', label: '群組名稱', hide: false },
  { id: 'quota', numeric: 'left', label: '群組預算/月', hide: false },
  {
    id: 'default_menber_quota',
    numeric: 'left',
    label: '成員預算/月',
    hide: false,
  },
  { id: 'active', numeric: 'left', label: '操作', hide: false },
]

function GroupList(props) {
  const classes = useStyle()
  const [group, setGroup] = useState({
    name: '',
    quota: 0,
    member_default_quota: 0,
  })
  const [selected, setSelected] = useState([])
  const [groupRow, setGroupRow] = useState('')
  const isSelected = (name) => selected.indexOf(name) !== -1
  const [open, setOpen] = React.useState(false)
  const { state, dispatch } = useContext(CorpContext)
  const [groups, setGroupList] = useState([])
  const [checked, setChecked] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const createRef = useRef('none')

  useEffect(() => {
    const list = []
    state.groups.forEach((element) => {
      const n_row = element
      n_row.group = element.name || ''
      n_row.default_menber_quota = element.quota || 0
      list.push(n_row)
    })
    setGroupList(list)
  }, [state.groups])

  function handleClickOpen(row) {
    setGroupRow(row)
    setOpen(true)
  }

  function handleClose(event) {
    setOpen(false)
    if (event.currentTarget.id === 'confirm') {
      deleteDepartment()
    } else {
      setGroupRow('')
    }
  }

  async function handleUpdateGroups() {
    const data = await queryInfo('groups')
    const { groups } = data.data.data
    dispatch({
      type: 'UPDATE_GROUPS',
      groups: groups,
    })
  }

  async function createDepartment() {
    if (group.name.length === 0) {
      clickCreateButton()
      return
    }
    const body = {
      name: group.name,
    }
    const data = await postCompanyGroup(body)
    console.log(data, 'create group')
    if (data.status !== 200) return handleAlertBar('新增群組失敗，請稍後再試。')
    setGroup({ name: '', quota: 0, member_default_quota: 0 })
    handleUpdateGroups()
    handleAlertBar('新增群組成功')
  }

  async function updateDepartment() {
    const body = {
      name: group.name,
      quota: group.quota,
      member_default_quota: group.member_default_quota,
      override_quota: checked,
    }
    const data = await putCompanyGroup(groupRow.id, body)
    console.log(data, 'update group')
    if (data.status !== 200) return handleAlertBar('更新群組失敗，請稍後再試。')
    setGroupRow('')
    setGroup({ name: '', quota: 0, member_default_quota: 0 })
    handleUpdateGroups()
    handleAlertBar('更新群組成功')
  }

  async function deleteDepartment() {
    const data = await deleteCompanyGroup(groupRow.id)
    console.log(data, 'delete group')
    if (data.status !== 200) {
      return handleAlertBar(
        '刪除群組失敗，請檢查該群組是否還有成員尚未轉移新群組。',
        'error'
      )
    }
    handleAlertBar('刪除群組成功')
    setGroupRow('')
    handleUpdateGroups()
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  function clickCreateButton() {
    if (createRef.current.style.display === 'none') {
      createRef.current.style.display = ''
    } else if (createRef.current.style.display === '') {
      createRef.current.style.display = 'none'
    }
  }

  function renderEditRow(row) {
    if (row.id === groupRow.id) {
      return (
        <React.Fragment>
          <TableCell
            className={classes.cell}
            padding="none"
            style={{ maxWidth: '80px' }}
          >
            {Auth.getPermission(state, 'ADMIN') ? (
              <TextField
                id="outlined-corp-input"
                label="群組"
                margin="normal"
                value={group.name}
                onChange={(e) => setGroup({ ...group, name: e.target.value })}
              />
            ) : (
              row.name
            )}
          </TableCell>
          <TableCell
            className={classes.cell}
            padding="none"
            style={{ maxWidth: '80px' }}
          >
            {Auth.getPermission(state, 'ADMIN') ? (
              <TextField
                id="outlined-corp-input"
                label="預算"
                margin="normal"
                value={group.quota}
                onChange={(e) => setGroup({ ...group, quota: e.target.value })}
              />
            ) : (
              row.quota
            )}
          </TableCell>

          <TableCell
            className={classes.cell}
            padding="none"
            style={{ maxWidth: '80px' }}
          >
            <TextField
              id="outlined-corp-input"
              label="成員預設預算"
              margin="normal"
              value={group.member_default_quota}
              onChange={(e) =>
                setGroup({
                  ...group,
                  member_default_quota: e.target.value,
                })
              }
            />
          </TableCell>
          <TableCell
            className={classes.cell}
            padding="none"
            style={{ minWidth: '150px' }}
          >
            <BasicButton
              color="#ffffff"
              background="#4b4d4c"
              title="確認變更"
              onClick={() => updateDepartment()}
            />
            <BasicButton
              outlineButton
              color="#4b4d4c"
              background="unset"
              title="取消編輯"
              onClick={() => cancelOnClick()}
            />
          </TableCell>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <MaxWidthForTableCell
            context={row.name}
            group={row.id}
            minWidth="80px"
          />
          <MaxWidthForTableCell
            context={row.quota}
            group={row.id}
            minWidth="80px"
          />
          <MaxWidthForTableCell
            context={row.member_default_quota}
            group={row.id}
            minWidth="80px"
          />
          <TableCell
            className={classes.cell}
            align="left"
            padding="none"
            style={{ minWidth: '150px' }}
          >
            <BasicButton
              color="#ffffff"
              background="#4b4d4c"
              title="編輯"
              onClick={() => editOnClick(row)}
            />
            {Auth.getPermission(state, 'ADMIN') && (
              <BasicButton
                outlineButton
                color="#4b4d4c"
                background="unset"
                title="移除群組"
                onClick={() => handleClickOpen(row)}
              />
            )}
          </TableCell>
        </React.Fragment>
      )
    }
  }

  function editOnClick(row) {
    setGroupRow(row)
    setGroup({
      name: row.name,
      quota: row.quota,
      member_default_quota: row.member_default_quota,
    })
  }

  function cancelOnClick() {
    setGroupRow('')
    setGroup({ name: '', quota: 0, member_default_quota: 0 })
  }

  function handleOpenModal() {
    setOpenModal((prevOpen) => !prevOpen)
  }

  function printRow(row, index) {
    const isItemSelected = isSelected(row.id)
    return (
      <TableRow
        hover
        role="checkbox"
        className={classes.row}
        aria-checked={isItemSelected}
        tableIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        {renderEditRow(row)}
      </TableRow>
    )
  }

  return (
    <>
      <div id="group-list-container" className={classes.groupListContainer}>
        <div className={classes.toolbar} />
        <div className={classes.groupListDes}>
          <Typography variant="h5" className={classes.heading}>
            群組列表 ({groups && groups.length}個群組)
          </Typography>
          <div className={classes.des}>
            {Auth.getPermission(state, 'ADMIN')
              ? '・管理員可在此頁新增/刪除群組，並編輯群組/成員的每月預算'
              : '・群組主管可在此頁編輯群組/成員的每月預算'}
          </div>
          <div className={classes.des}>
            ・點擊個別群組，可進一步查看該群組所有成員設定
          </div>
        </div>
        <div className={classes.paper}>
          {Auth.getPermission(state, 'ADMIN') && (
            <div className={classes.exportControl}>
              <CustomIconButton
                color="green"
                title="新增群組"
                icon="add_circle"
                onClick={clickCreateButton}
              />
              <div>
                <ExportButtonModel
                  icon="icon-06_匯入.svg"
                  title="匯入"
                  type="left"
                  onClick={handleOpenModal}
                />
                <ExportButtonModel
                  icon="icon-07_匯出.svg"
                  title="匯出"
                  type="right"
                  onClick={() => onExportGroupExcel(groups)}
                />
              </div>
            </div>
          )}
          <div
            className={classes.filter}
            ref={createRef}
            style={{ display: 'none' }}
          >
            <form
              className={classes.filterContent}
              noValidate
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs>
                  <input
                    className={classes.input}
                    type="text"
                    id="department_name"
                    placeholder="群組名稱"
                    value={group.name}
                    onChange={(e) =>
                      setGroup({ ...group, name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={3} className={classes.createButtonWrap}>
                  <Fab
                    aria-label="Add"
                    size="small"
                    className={classes.fabAdd}
                    onClick={createDepartment}
                  >
                    <Icon>done</Icon>
                  </Fab>
                  <IconButton
                    aria-label="Cancel"
                    color="#4b4d4c"
                    className={classes.fabCancel}
                    onClick={clickCreateButton}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </div>
          <div style={{ padding: '0 16px' }}>
            <div className={classes.container}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked)
                    }}
                  />
                }
                label="將新設定套用至群組所有成員"
              />
            </div>
            <MSortTable
              list={groups}
              headRow={headRow}
              printRow={printRow}
              pOrderBy="group"
            ></MSortTable>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            確認要刪除「{groupRow.name}」群組
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              該群組成員會同時被移出群組。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel" onClick={handleClose} color="primary">
              取消
            </Button>
            <Button
              id="confirm"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              確認
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <UploadGroupModal
        state={state}
        open={openModal}
        handleOpenModal={handleOpenModal}
        handleUpdateGroups={handleUpdateGroups}
      />
    </>
  )
}

export default GroupList
