import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styledBy = (property, mapping) => props => mapping[props[property]]

const useStyles = makeStyles({
  root: {
    background: props => `${props.background}`,
    '&:hover': {
      background: props => `${props.background}`
    },
    color: props => `${props.color}`,
    border: 0,
    borderRadius: 15,
    height: 24,
    padding: '4px 16px',
    margin: 8,
    fontSize: '14px',
    fontWeight: 500
  },
  outlineButton: {
    borderRadius: '12px',
    border: 'solid 1px #4b4d4c',
    background: 'white',
    color: '#4b4d4c'
  }
})

function BasicButton(props) {
  const { background, outlineButton, color, ...other } = props
  const classes = useStyles(props)
  return (
    <Button
      className={`${outlineButton && classes.outlineButton}`}
      classes={{ root: classes.root }}
      {...other}
    >
      {props.title}
    </Button>
  )
}

BasicButton.propTypes = {
  background: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default BasicButton
