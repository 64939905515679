import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TableCell, TableRow } from '@material-ui/core'
import { putUsersUnit } from '../api'
import BasicButton from '../view/Buttons/BasicButton'
import MSortTable from '../view/Table/MSortTable'
import { CorpContext } from '../reducers/Main'
import { getData } from '../router/index'
import { generalStyle } from '../styles'
import moment from 'moment'

const userStyle = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paper: {
    overflow: 'auto',
    padding: theme.spacing(5, 10),
    borderRadius: 10,
    width: '70vw',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: 700,
    textAlign: 'start',
  },
  cell: {
    borderBottom: 'none',
    minWidth: '150px',
    padding: 0,
  },
  hide: {
    display: 'none',
  },
  content: {
    marginTop: '10px',
    fontWeight: 500,
    textAlign: 'center',
  },
  filter: {
    background: 'lightgray',
    width: '100%',
    borderRadius: '24px',
  },
  '@media (max-width:960px)': {
    paper: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
}))

const headRow = [
  { id: 'name', numeric: 'left', label: '姓名', hide: false },
  { id: 'email', numeric: 'left', label: '信箱', hide: false },
  { id: 'updated_at', numeric: 'left', label: '封鎖時間', hide: false },
  { id: 'action', numeric: 'left', label: '操作', hide: false },
]

function getBlockList(dispatch) {
  fetch(
    process.env.REACT_APP_API_URI + `/company?q={users(status: "blocked")}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
    .then(async function (res) {
      const data = await res.json()
      dispatch({ type: 'UPDATE_BLOCK_LIST', block_list: data.data.users })
    })
    .catch((err) => console.log(err))
}

function BlockList() {
  const classes = userStyle()
  const generalClasses = generalStyle()

  const { state, dispatch } = useContext(CorpContext)

  const [block_list, setBlockList] = useState([])

  useEffect(() => {
    getBlockList(dispatch)
    return () => {}
  }, [dispatch])

  useEffect(() => {
    const list = []
    state.block_list.forEach((element) => {
      list.push({
        id: element.id,
        name: element.name,
        email: element.email,
        updated_at: moment(element.updated_at).unix(),
      })
    })
    setBlockList(list)
    return () => {}
  }, [state.block_list])

  async function unblockMember(id) {
    const body = { status: 'PENDING' }
    const data = await putUsersUnit(id, body)
    const { message } = data.data
    if (data.status !== 200)
      return handleAlertBar(message, 'error')

    handleAlertBar('更新成員成功')
    getBlockList(dispatch)
  }

  function handleAlertBar(text, type) {
    dispatch({
      type: 'TOGGLE_ALERT_BAR',
      alert_bar: { open: true, text, type },
    })
  }

  function printRow(row, index) {
    return (
      <TableRow
        hover
        role="checkbox"
        tableIndex={-1}
        key={index}
        className={generalClasses.row}
      >
        <TableCell className={classes.cell}>{row.name}</TableCell>
        <TableCell className={classes.cell}>{row.email}</TableCell>
        <TableCell className={classes.cell}>
          {moment(row.updated_at * 1000).format('YYYY/MM/DD HH:mm:ss')}
        </TableCell>
        <TableCell className={classes.cell}>
          <BasicButton
            color="#ffffff"
            background="#4b4d4c"
            hoverColor="black"
            title="解除封鎖"
            onClick={() => unblockMember(row.id)}
          />
        </TableCell>
      </TableRow>
    )
  }

  //Sort end ------------
  function renderBlockList() {
    if (block_list.length === 0) {
      return (
        <Typography color="textSecondary" className={classes.content}>
          目前無封鎖名單
        </Typography>
      )
    } else {
      return (
        <MSortTable
          list={block_list}
          headRow={headRow}
          printRow={printRow}
          pOrderBy={'created_min'}
        ></MSortTable>
      )
    }
  }

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <div className={generalClasses.paper}>
        <Typography variant="h5" className={classes.heading}>
          封鎖名單 (成員數 {state.block_list.length} 人)
        </Typography>
        {renderBlockList()}
      </div>
    </React.Fragment>
  )
}

export default BlockList
