import React, { useState, useContext, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Table, TableHead, TableBody, TableCell, TableRow, Icon, Button, Typography, Box, Grid } from '@material-ui/core'
import amount_header_img from '../../img/代收轉付收據_v2.png'
import tax_mg from '../../img/tax_v3.png'

import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { CorpContext } from '../../reducers/Main'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  right_button: {
    borderRadius: '5px 20px 20px 5px',
    margin: '8px 5px',
    boxShadow: 'none',
    background: 'rgb(89, 89, 89)',
    color: 'white',
  },
  text: {
    display: 'inline-block',
    marginRight: '10px',
  },
  display_view: {
    position: 'absolute',
    opacity: '0.0',
    padding: '1px 35px 5px 0px',
  },
  white_background: {
    background: 'rgb(255, 255, 255)',
  },
  paper: {
    overflowX: 'auto',
    padding: theme.spacing(5, 8),
    borderRadius: 10,
    width: '70vw',
  },
  header: {
    textAlign: 'center',
    width: '700px',
    padding: '0px 0px 0px 40px',
  },
  tableHeader: {
    position: 'absolute',
    opacity: '0.0',
  },
  gridTop: {
    width: '700px',
    padding: '0px 0px 0px 0px',
  },
  tableBottom: {
    width: '700px',
  },
  splitBlock: {
    padding: '20px 0px 0px 40px',
  },
  tableTextSize: {
    fontSize: 14,
    color: 'rgb(0, 0, 0)',
    padding: '10px 0px 10px 0px',
  },
  noteTextSize: {
    width: '700px',
    fontSize: 14,
    color: 'rgb(0, 0, 0)',
  },
  tax_image: {
    width: '150px',
    height: '120px',
    padding: '30dp 30px 30px 0px',
  },
  print_icon: {
    color: 'black',
  },
}))
function ExportAmountTable(props) {
  const classes = useStyles()
  const { summery } = props
  const [download, setDownload] = useState(false)
  const { state } = useContext(CorpContext)

  const [company, setCompany] = useState(null)

  useEffect(() => {
    if (state.company === null || state.company.length < 1) {
      setCompany(null)
    } else {
      setCompany(state.company[0])
    }
    return () => {}
  }, [state.company])

  async function onExportAmountExcel() {
    if (company === null) {
      return
    }
    setDownload(true)
    setTimeout(() => {
      html2canvas(document.getElementById('exportTable')).then((canvas) => {
        console.log('trips_history' + JSON.stringify('google'))
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const pdf = new jsPDF('', 'pt', 'a4')
        pdf.addImage(pageData, 'JPEG', 25, 20, 550, 720)
        pdf.save(summery.time.toMonth() + ' 月代收轉付收據.pdf')
        setDownload(false)
      })
    }, 400)
  }

  return (
    <React.Fragment>
      <Button className={classes.right_button} variant="contained" style={{ background: 'rgb(255, 185, 81)' }} onClick={() => onExportAmountExcel()}>
        <Typography className={classes.text} style={{ color: 'black' }}>
          列印 {summery.time.toMonth()} 月總收據帳單
        </Typography>
        <Icon className={classes.print_icon} fontSize="small">
          save_alt
        </Icon>
      </Button>
      {download ? <AmountExcel summery={summery} company={company} download={download} /> : ''}
    </React.Fragment>
  )
}

function AmountExcel(props) {
  const classes = useStyles()

  const { summery, company } = props
  const { state } = useContext(CorpContext)

  function intToChinese(str) {
    str = str + ''
    const len = str.length - 1
    const idxs = ['', '拾', '佰', '仟', '萬', '拾', '佰', '仟', '億', '拾', '佰', '仟', '萬', '拾', '佰', '仟', '億']
    const num = ['零', '壹', '貳', '叁', '肆', '伍', '陸', '柒', '捌', '玖']
    return str.replace(/([1-9]|0+)/g, function ($, $1, idx, full) {
      let pos = 0
      if ($1[0] !== '0') {
        pos = len - idx
        if (idx === 0 && $1[0] === 1 && idxs[len - idx] === '十') {
          return idxs[len - idx]
        }
        return num[$1[0]] + idxs[len - idx]
      } else {
        const left = len - idx
        const right = len - idx + $1.length
        if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
          pos = left - (left % 4)
        }
        if (pos) {
          return idxs[pos] + num[$1[0]]
        } else if (idx + $1.length >= len) {
          return ''
        } else {
          return num[$1[0]]
        }
      }
    })
  }
  function getPlanValus(agreement, type) {
    const plan = type === 1 ? agreement.company_fee : agreement.company_discount
    if (plan) {
      let str = ''
      if (plan.plan_value === 0) {
        str += '為零'
      } else if (plan.plan_value < 1) {
        str += ` ${Math.floor(plan.plan_value * 100)}%\n`
        str += '最多 '
        str += plan.max_value === 0 ? '沒有上限' : `${plan.max_value} 元`
        str += '\n'
      } else if (plan.plan_value >= 1) {
        str += `${plan.plan_value} 元`
      } else {
        str += '未知'
      }
      return str
    }
    return 0
  }
  function pricePlan(agreement) {
    const view = []
    if (!agreement) {
      return ''
    }

    if (agreement.company_fee) {
      view.push(
        <TableRow>
          <TableCell className={classes.tableTextSize}>本期服務費</TableCell>
          <TableCell className={classes.tableTextSize}>1</TableCell>
          <TableCell className={classes.tableTextSize}>{-agreement.company_fee_amount}</TableCell>
          <TableCell className={classes.tableTextSize}>{-agreement.company_fee_amount}</TableCell>
          <TableCell className={classes.tableTextSize}>{getPlanValus(agreement, 1)}</TableCell>
        </TableRow>,
      )
    }

    if (agreement.company_discount) {
      view.push(
        <TableRow>
          <TableCell className={classes.tableTextSize}>本期折扣</TableCell>
          <TableCell className={classes.tableTextSize}>1</TableCell>
          <TableCell className={classes.tableTextSize}>{+agreement.company_discount_amount}</TableCell>
          <TableCell className={classes.tableTextSize}>{+agreement.company_discount_amount}</TableCell>
          <TableCell className={classes.tableTextSize}>{getPlanValus(agreement, 2)}</TableCell>
        </TableRow>,
      )
    }
    return view
  }

  function toAmountMonth() {
    return moment(summery.time * 1000).add(1, 'month')
  }

  return (
    <div className={classes.display_view}>
      <div id="exportTable" style={{ padding: '10px', backgroundColor: '#fff' }}>
        <img src={amount_header_img} alt="" />
        <Box className={classes.splitBlock}></Box>
        <Typography variant="h6" className={classes.header}>
          觔斗雲聯網科技股份有限公司
        </Typography>
        <Typography variant="h6" className={classes.header}>
          代收轉付收據
        </Typography>
        <Typography variant="h6" className={classes.header}>
          {toAmountMonth().year() + ' 年 ' + (toAmountMonth().month() + 1) + ' 月 ' + ' 5 日'}
        </Typography>
        <Box className={classes.splitBlock}></Box>

        <Grid container className={classes.gridTop}>
          <Grid item xs>
            <Typography>客戶名稱：{company.name} </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography>銷帳編號：{company.id + '-' + summery.time.totUnix()}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.gridTop}>
          <Grid item xs>
            <Typography>統一編號：{company.tax_num}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography>客戶簡稱：{company.nick_name}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.gridTop}>
          <Grid item xs>
            <Typography>
              聯絡電話：
              {company.mobile_num ? company.mobile_num : company.telephone_num}{' '}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography>
              付款截止日：
              {summery.time.toEndDate(state.company[0].pending_statement_month, state.company[0].pending_statement_day).format('YYYY / MM / DD')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.gridTop}>
          <Grid item xs>
            <Typography>公司 E-mail：{company.email} </Typography>
          </Grid>
        </Grid>
        <Box className={classes.splitBlock}></Box>
        <Box className={classes.splitBlock}></Box>
        <Table className={classes.tableBottom}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTextSize}>收款項目</TableCell>
              <TableCell className={classes.tableTextSize}>數量</TableCell>
              <TableCell className={classes.tableTextSize}>單價</TableCell>
              <TableCell className={classes.tableTextSize}>總計</TableCell>
              <TableCell className={classes.tableTextSize}>備註</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.tableTextSize}>計程車車資代收代付</TableCell>
              <TableCell className={classes.tableTextSize}>1</TableCell>
              <TableCell className={classes.tableTextSize}>{summery.receipt_info?.amount || 0}</TableCell>
              <TableCell className={classes.tableTextSize}>{summery.receipt_info?.amount || 0}</TableCell>
              <TableCell className={classes.tableTextSize}>
                {moment(summery.time * 1000).format('YYYY-MM-DD')} ~{' '}
                {moment(summery.time * 1000)
                  .endOf('month')
                  .format('YYYY-MM-DD')}
              </TableCell>
            </TableRow>
            {pricePlan(summery.receipt_info)}
            <TableRow>
              <TableCell className={classes.tableTextSize}>===以下空白===</TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <div className={classes.tableTextSize}> </div>
              </TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell align="center" className={classes.tableTextSize} rowSpan={3}>
                <img className={classes.tax_image} src={tax_mg} alt="" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableTextSize}>帳款總計</TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell colSpan={2} align="right" className={classes.tableTextSize}>
                {summery.receipt_info?.final_amount || 0}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableTextSize}>合計新台幣</TableCell>
              <TableCell className={classes.tableTextSize}></TableCell>
              <TableCell colSpan={2} align="right" className={classes.tableTextSize}>
                {intToChinese(summery.receipt_info?.final_amount || 0) + ' 元整'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography className={classes.tableTextSize}>匯款資訊： </Typography>
        <ul className={classes.noteTextSize} style={{ listStyleType: 'disc', marginLeft: '1em' }}>
          <li>
            匯款銀行：花旗銀行 營業部 021-0018 匯款帳號：{company.virtual_account ? company.virtual_account : '76600000000006'}
            戶名：觔斗雲聯網科技股份有限公司。
          </li>
        </ul>
        <Typography className={classes.tableTextSize}>備註： </Typography>
        <Typography className={classes.noteTextSize}>1. 付款截止日 16:00 未入帳，次日將停止簽單叫車系統。</Typography>
        <Typography className={classes.noteTextSize}>2. 請勿內扣匯款手續費，若帳款金額不符無法銷帳，系統將視同未付款，請您協助注意。</Typography>
        <Typography className={classes.noteTextSize}>3. 本收據金額不含服務費，服務費另開立發票。</Typography>
        <Typography className={classes.noteTextSize}>4. 為方便會計作業，尾數請勿折讓。</Typography>
        <Typography className={classes.noteTextSize}>
          5. 依統一發票使用辦法第四條第三款及營利事業所得稅查核準則第七十四條第三款第二目規定，計程車車資免開統一發票，收據僅供客戶記帳證明之用。
        </Typography>
        <Typography className={classes.noteTextSize}>6. 帳款如有問題，請加入企業簽單 LINE 官方帳號，搜尋 @linegobiz</Typography>
      </div>
    </div>
  )
}

export default ExportAmountTable
