import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Fab, Icon, Popper } from '@material-ui/core'
import MenuList from '../Menu/MenuList'
import FilterAutoComplete from '../Input/FilterAutoComplete'

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    borderRadius: 15,
    height: 30,
    padding: '20px',
    margin: 8,
  },
  fab: {
    margin: theme.spacing(1),
    background: 'white',
    '&:hover': {
      background: 'white',
    },
    color: 'black',
    boxShadow: 'none',
    height: '1.5rem',
    alignItems: 'center',
  },
  span_hint: {
    fontWeight: 400,
    fontSize: '12.5px',
    color: '#4b4d4c',
  },
  span: {
    fontWeight: 400,
    fontSize: '12.5px',
    color: 'black',
  },
  icon_visible: {
    paddingLeft: theme.spacing(0.5),
    color: '#4b4d4c',
    visibility: 'visible',
  },
  icon_invisible: {
    marginLeft: theme.spacing(0.5),
    color: '#4b4d4c',
    visibility: 'hidden',
    display: 'none',
  },
  popper: {
    zIndex: 100,
    // height: '300px',
    width: '300px',
    border: '1px solid rgba(27,31,35,.15)',
    backgroundColor: '#fff',
    padding: '5px 10px',
  },
  filterPaper: {
    maxHeight: '300px',
    boxShadow: 'none',
    margin: 0,
  },
  popperDisablePortal: {
    position: 'relative',
  },
}))

function FilterButtonWithClear(props) {
  const {
    title,
    default_title,
    options,
    field,
    addSearch,
    list_header,
    isAuto = true,
    clearFilter = true,
    ...other
  } = props
  const classes = useStyles(props)

  const [optionTitle, setOptionTitle] = useState(default_title)
  const [open, setOpen] = useState(false)
  const [isDefaultValue, setIsDefaultValue] = useState(false)
  const anchorRef = React.useRef(null)

  useEffect(() => {
    !clearFilter && setOptionTitle(default_title)
  }, [default_title])

  useEffect(() => {
    setIsDefaultValue(optionTitle === default_title)
    return () => {}
  }, [optionTitle])

  function openList(reason) {
    if (reason === 'toggleInput') return
    setOpen((prevOpen) => !prevOpen)
  }

  function clearDefaultValue() {
    setOptionTitle(default_title)
    addSearch(field, default_title, 0)
  }

  function handleAutoValue(v) {
    if (!v) return
    setOptionTitle(v.name)
    addSearch(field, v)
  }

  const id = open ? 'filter-Popper' : undefined

  return (
    <React.Fragment>
      <Fab
        variant="extended"
        className={classes.fab}
        {...other}
        aria-label="More"
        aria-owns={open ? 'options-menu' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        disableRipple="true"
      >
        <span
          className={isDefaultValue ? classes.span_hint : classes.span}
          onClick={openList}
        >
          {' '}
          {optionTitle}{' '}
        </span>
        {isDefaultValue ? (
          <Icon
            className={classes.icon_visible}
            fontSize="small"
            onClick={openList}
          >
            keyboard_arrow_down
          </Icon>
        ) : clearFilter ? (
          <Icon
            className={classes.icon_visible}
            fontSize="small"
            onClick={clearDefaultValue}
          >
            clear
          </Icon>
        ) : (
          <Icon
            className={classes.icon_visible}
            fontSize="small"
            onClick={openList}
          >
            keyboard_arrow_down
          </Icon>
        )}
      </Fab>
      {isAuto ? (
        <Popper
          id={id}
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
        >
          <FilterAutoComplete
            id="filter-autocomplete"
            classes={{
              paper: classes.filterPaper,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            disablePortal
            open
            options={options}
            onClose={(e, reason) => openList(reason)}
            getOptionLabel={(option) => {
              if (option.email) {
                return `${option.name} (${option.email})`
              }
              return option.name
            }}
            // onInputChange={(e, v) => handleGroup(v)}
            onChange={(e, v) => handleAutoValue(v)}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  type="text"
                  id="filter-auto-input"
                  autoFocus
                  placeholder={list_header}
                  value={optionTitle}
                  {...params.inputProps}
                />
              </div>
            )}
          />
        </Popper>
      ) : (
        <MenuList
          default_title={default_title}
          list_header={list_header}
          options={options}
          field={field}
          addSearch={addSearch}
          handleToggle={openList}
          setOptionTitle={setOptionTitle}
          open={open}
          setOpen={setOpen}
          anchorRef={anchorRef}
          handleRole={() => {}}
        />
      )}
    </React.Fragment>
  )
}

FilterButtonWithClear.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.string.isRequired,
}

export default FilterButtonWithClear
