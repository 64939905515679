class Auth {
  createList(id, path, sub = []) {
    return { id, path, sub }
  }
  isMamber(state) {
    return state.me.find((data) => data.permission.includes('DEFAULT'))
  }

  isAdmin(state) {
    return state.me.find((data) => data.permission.includes('ADMIN'))
  }

  isManager(state) {
    return state.me.find((data) => data.permission.includes('MANAGER'))
  }

  getPermission(state, permission) {
    return state.me.find(
      (data) =>
        data.permission.includes(permission) ||
        (data.role && data.role.includes(permission))
    )
  }

  getPermissionWithGroup(state, group) {
    return state.me.find((data) => {
      return (
        data.permission.includes('ADMIN') ||
        (data.group === group && data.role && data.role.includes('MANAGER'))
      )
    })
  }

  getMamberList() {
    return [
      this.createList('總覽', '/'),
      this.createList('行程紀錄', '/trips'),
      this.createList('聯絡我們', '/contectCenter'),
    ]
  }

  getManagerList() {
    return [
      this.createList('總覽', '/'),
      this.createList('行程紀錄', '/trips'),
      this.createList('差旅審核單', '/reviewlist'),
      this.createList('成員管理', '', [
        this.createList('群組列表', '/groupcenter'),
      ]),
      this.createList('統計表', '/statistics'),
      this.createList('設定', '', [
        this.createList('行程備註設定', '/tripNoteSetting'),
        this.createList('權限說明', '/setting'),
      ]),
      this.createList('聯絡我們', '/contectCenter'),
    ]
  }

  getAdminList() {
    return [
      this.createList('總覽', '/'),
      this.createList('帳務中心', '/amountcenter/accounting'),
      this.createList('行程紀錄', '/trips'),
      this.createList('差旅審核單', '/reviewlist'),
      this.createList('成員管理', '', [
        this.createList('群組列表', '/groupcenter'),
        this.createList('成員名單', '/member'),
        this.createList('封鎖名單', '/blocklist'),
      ]),
      this.createList('統計表', '/statistics'),
      this.createList('設定', '', [
        this.createList('行程備註設定', '/tripNoteSetting'),
        this.createList('權限說明', '/setting'),
      ]),
      this.createList('聯絡我們', '/contectCenter'),
    ]
  }

  getList(state) {
    if (this.getPermission(state, 'ADMIN')) {
      return this.getAdminList()
    } else if (this.getPermission(state, 'MANAGER')) {
      return this.getManagerList()
    }
    return this.getMamberList()
  }
}

export default new Auth()
