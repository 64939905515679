import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import { Icon, Button, Typography } from '@material-ui/core'
import { ReactComponent as DownloadIcon } from '../../icon/icon_download.svg'
import ExportButtonModel from './ExportButtonModel'

const useStyles = makeStyles(theme => ({
  center_button: {
    borderRadius: '20px 20px 20px 20px',
    margin: '8px 5px',
    boxShadow: 'none',
    background: '#797979',
    color: 'white'
  },

  uploadText: {
    display: 'inline-block'
  }
}))

function DownloadAddUserSample() {
  const classes = useStyles()

  async function onExportExcel() {
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'LINE TAXI Business'
    workbook.lastModifiedBy = 'LINE TAXI Business'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const sheet_p1 = workbook.addWorksheet('member_sheets')

    sheet_p1.getColumn(1).width = 20
    sheet_p1.getColumn(2).width = 20
    sheet_p1.getColumn(3).width = 10
    sheet_p1.getColumn(4).width = 20
    sheet_p1.getColumn(5).width = 45

    sheet_p1.mergeCells('A1:A4')
    sheet_p1.getCell('A1').value = '群組名稱'
    sheet_p1.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }
    sheet_p1.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('B1:B4')
    sheet_p1.getCell('B1').value = '姓名*'
    sheet_p1.getCell('B1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }
    sheet_p1.getCell('B1').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('C1:E1')
    sheet_p1.getCell('C1').value = '身份驗證方式*'
    sheet_p1.getCell('C1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }
    sheet_p1.getCell('C1').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('C2:E2')
    sheet_p1.getCell('C2').value =
      '1. 手機號碼或信箱，選填其中一項；建議都填入，方可採用多元驗證'
    sheet_p1.getCell('C2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }
    sheet_p1.getCell('C2').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.mergeCells('C3:E3')
    sheet_p1.getCell('C3').value = '2. 國碼未填，預設為 +886'
    sheet_p1.getCell('C3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }
    sheet_p1.getCell('C3').alignment = {
      vertical: 'middle',
      horizontal: 'left'
    }

    sheet_p1.getCell('C4').value = '國碼*'
    sheet_p1.getCell('C4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }

    sheet_p1.getCell('D4').value = '手機號碼*'
    sheet_p1.getCell('D4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }

    sheet_p1.getCell('E4').value = '信箱*'
    sheet_p1.getCell('E4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE8E8E8' }
    }

    // sheet_p1.addRow(['群組名稱', '姓名*', '信箱'])
    sheet_p1.addRow([
      'Marketing',
      '小明1',
      '+886',
      '0999000001',
      'lili@linetaxi.com.tw'
    ])
    sheet_p1.addRow([
      'Marketing',
      '小明2',
      '+886',
      '0999000002',
      'lili2@linetaxi.com.tw'
    ])
    sheet_p1.addRow([
      'Marketing',
      '小明3',
      '+886',
      '0999000003',
      'lili3@linetaxi.com.tw'
    ])

    const buf = await workbook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buf]), '新增成員格式.xlsx')
  }
  return (
    <ExportButtonModel
      icon="icon_download.svg"
      title="下載格式"
      onClick={onExportExcel}
    />
  )
}

export default DownloadAddUserSample
