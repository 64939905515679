import React from 'react'
import moment from 'moment'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import ExportButtonModel from './ExportButtonModel'
import { getCompanyPayment } from '../../utils'

function ExportTripDetail(props) {
  const { tripsList, custom_field } = props

  async function onExportExcel() {
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'LINE TAXI Business'
    workbook.lastModifiedBy = 'LINE TAXI Business'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const sheet_p1 = workbook.addWorksheet('member_sheets')
    const rowTitle = [
      'NO.',
      '用車時間',
      '行程代碼',
      '群組',
      '行程類型',
      '姓名',
      '計程車車號',
      '上車時間',
      '上車地點',
      '下車時間',
      '下車地點',
      '行駛里程',
      '司機',
      '總金額',
      '公司支付',
      '員工自付',
      '報帳類型',
      '審核狀態'
    ]
    let sortCustomField = []
    let temp = {}
    tripsList.forEach(item => {
      let currentNote = {}
      item.custom_field &&
        item.custom_field.forEach(el => {
          currentNote[el.id] = el.content
        })
      custom_field.forEach((item, index) => {
        if (currentNote[item.id] && !temp[item.id]) {
          temp[item.id] = item.title
          sortCustomField.push(item)
          rowTitle.push(item.title)
        }
      })
    })
    sheet_p1.getRow(1).values = rowTitle

    let count = 0
    tripsList
      .sort((a, b) => (a.trip_id > b.trip_id ? 1 : -1))
      .reverse()
      .forEach(item => {
        count++
        const { company: companyPayment, self } = getCompanyPayment(item.transactions || [])
        const business_notes = {}
        item.custom_field &&
          item.custom_field.forEach(el => {
            business_notes[el.id] = el.content
          })
        const rowContent = [
          count,
          moment(item.reserve_time * 1000).format('YYYY/MM/DD HH:mm'), //用車時間
          item.trip_hash, //行程代碼
          item.group, //群組
          item.trip_category, //行程類型
          item.name, //員工姓名
          item.plate_number, //車牌
          moment(item.trip_start_time * 1000).format('YYYY/MM/DD HH:mm'), //上車時間
          item.start_address, //上車地點
          moment(item.trip_finish_time * 1000).format('YYYY/MM/DD HH:mm'), //下車時間
          item.end_address || '未輸入',
          item.distance, // 行駛里程
          item.last_name + 'ＯＯ',
          item.amount, //總金額
          companyPayment,
          self,
          item.receipt_type, //報帳類型
          item.company_trips_status.tripStatusLocalized() //審核狀態
        ]

        sortCustomField.forEach(item => {
          if (business_notes[item.id]) {
            rowContent.push(business_notes[item.id])
          } else {
            rowContent.push('')
          }
        })
        sheet_p1.addRow(rowContent)
      })

    const buf = await workbook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buf]), '行程紀錄.xlsx')
  }

  return (
    <ExportButtonModel
      icon="icon-07_匯出.svg"
      title="匯出"
      type="right"
      onClick={onExportExcel}
    />
  )
}

export default ExportTripDetail
