import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell, TextField, Fab, Icon, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyle = makeStyles(theme => ({
  cell: {
    borderBottom: 'none',
    paddingRight: '10px'
  },
  fabAdd: {
    color: '#f8f8f8',
    background: '#4b4d4c',
    margin: '0 10px',
    width: '32px',
    height: '32px',
    minHeight: 'unset'
  },
  fabCancel: {
    color: '#4b4d4c',
    background: 'unset',
    marginLeft: '10px',
    width: '32px',
    height: '32px'
  }
}))

function EditTableCell(props) {
  const classes = useStyle()
  const { row, editCancel, editOnClick } = props
  const [values, setValues] = useState({
    name: row.name,
    email: row.email,
    phone_number: row.phone_number
  })

  const handleChange = name => event => {
    if (name === 'trip_quota' && event.target.value < 0) return
    setValues({ ...values, [name]: event.target.value })
  }

  function handlePermission(row) {
    if (row.permission.includes('ADMIN')) {
      return '管理員'
    }
    return ''
  }

  return (
    <React.Fragment>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="姓名"
          margin="normal"
          value={values.name}
          onChange={handleChange('name')}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="手機號碼"
          margin="normal"
          value={values.phone_number}
          onChange={handleChange('phone_number')}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        <TextField
          id="outlined-corp-input"
          label="信箱"
          margin="normal"
          fullWidth
          value={values.email}
          onChange={handleChange('email')}
        />
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        {handlePermission(row)}
      </TableCell>
      <TableCell className={classes.cell} padding="none">
        {row.status.userStatusLocalized()}
      </TableCell>
      <TableCell
        className={classes.cell}
        align="center"
        padding="none"
        style={{ minWidth: '110px' }}
      >
        <Fab
          aria-label="Add"
          className={classes.fabAdd}
          onClick={() => editOnClick(values)}
        >
          <Icon>done</Icon>
        </Fab>
        <IconButton
          aria-label="Cancel"
          color="#4b4d4c"
          className={classes.fabCancel}
          onClick={editCancel}
        >
          <Icon>clear</Icon>
        </IconButton>
      </TableCell>
    </React.Fragment>
  )
}

EditTableCell.propTypes = {
  row: PropTypes.array.isRequired
}

export default EditTableCell
