import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Grid, Icon } from '@material-ui/core'
import BasicButton from '../view/Buttons/BasicButton'
import { CorpContext } from '../reducers/Main'
import { getData } from '../router/index'

const useStyle = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	paper: {
		overflowX: 'auto',
		padding: theme.spacing(5, 8),
		borderRadius: 10,
		width: '70vw',
	},
	heading: {
		marginBottom: '10px',
		fontWeight: 700
	},
	title: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '150px',
		flexShrink: 0,
		color: 'black',
		fontWeight: 500,
	},
	content: {
		fontSize: theme.typography.pxToRem(15),
		color: 'rgb(89, 89, 89)',
		width: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	bulletinList: {
		'&hover': {
			background: 'lightgray'
		},
		margin: '20px 0 20px 0',
		display: 'flex'
	},
	input: {
		width: '100%',
		height: '25px',
		margin: '0 10px',
		border: '1px solid #ccc',
		borderRadius: '5px',
		outline: 'none',
		'-webkit-transition': '0.5s',
		transition: '0.5s',
		'&:focus': {
			border: '1px solid #555'
		},
		fontSize: '1rem'
	},
	textArea: {
		width: '100%',
		height: '150px',
		margin: '0 10px',
		border: '1px solid #ccc',
		borderRadius: '5px',
		outline: 'none',
		'-webkit-transition': '0.5s',
		transition: '0.5s',
		'&:focus': {
			border: '1px solid #555'
		},
		fontSize: '1rem'
	},
	icon: {
		margin: theme.spacing(1),
		fontSize: '25px',
		color: 'gray',
		textDecoration: 'none'
	}
}))

function updateNote(title, content, rowId, setTitle, setContent, setRowId, dispatch) {
	if ([title, content].includes('')) {
		alert('新增公告欄位請勿空白。')
		return
	}
	fetch(process.env.REACT_APP_API_URI+`/company/bulletin/corp/${rowId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		body: JSON.stringify({
			title: title,
			content: content
		}),
		credentials: 'include'
	}).then(async function (res) {
		if (res.status === 200) {
			alert('更新公告成功。')
		} else {
			alert('更新公告失敗，請稍後再試。')
		}
		setTitle('')
		setContent('')
		setRowId(null)
		getData(dispatch, () => { }, () => { })
	}).catch(console.log)
}

function createNote(title, content, dispatch) {
	console.log('send', title, content)
	if (title === '' || content === '') {
		alert('新增公告欄位請勿空白。')
		return
	}
	fetch(process.env.REACT_APP_API_URI+`/company/bulletin/corp`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		body: JSON.stringify({
			title: title,
			content: content
		}),
		credentials: 'include'
	}).then(async function (res) {
		if (res.status === 200) {
			alert('新增公告成功。')
		} else {
			alert('新增公告失敗，請稍後再試。')
		}
		getData(dispatch, () => { }, () => { })
	}).catch(console.log)
}

function deleteNote(id, dispatch) {
	fetch(process.env.REACT_APP_API_URI+`/company/bulletin/corp/${id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		credentials: 'include'
	}).then(async function (res) {
		if (res.status === 200) {
			alert('刪除公告成功。')
		} else {
			alert('刪除公告失敗，請稍後再試。')
		}
		getData(dispatch, () => { }, () => { })
	}).catch(console.log)
}

function EditBulletin() {

	const classes = useStyle()

	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [rowId, setRowId] = useState(null)

	const { state, dispatch } = useContext(CorpContext)

	function edit(row) {
		console.log(row.id)
		setTitle(row.title)
		setContent(row.content)
		setRowId(row.id)
	}

	return (
		<React.Fragment>
			<div className={classes.toolbar} />
			<Paper className={classes.paper}>
				<Grid container justify="space-between">
					<Grid item><Typography variant="h6" className={classes.heading}>企業公告編輯</Typography></Grid>
					<Grid item><Icon component={Link} to={`/`} className={classes.icon}>highlight_off</Icon></Grid>
				</Grid>

				<div style={{ maxHeight: 250, overflow: 'auto' }}>
					{state.bulletin.corp.map((row, i) => (
						<div className={classes.bulletinList} key={i} >
							<Grid container justify="space-between">
								<Grid item xs={8}>
									<Typography className={classes.title} key={row.id}>{row.title}</Typography>
									<Typography className={classes.content} key={row.id}>{row.content}</Typography>
								</Grid>
								<Grid item>
									<BasicButton color='rgb(89, 89, 89)' hoverColor="black" title="編輯" onClick={() => edit(row)} />
									<BasicButton color="rgb(239, 139, 139)" hoverColor="black" title="刪除" onClick={() => deleteNote(row.id, dispatch)} />
								</Grid>
							</Grid>
						</div>
					))}
				</div>
				<div style={{ margin: '40px 0' }}>
					<Grid container spacing={5}>
						<Grid item>
							<Typography className={classes.title} >公告標題</Typography>
						</Grid>
						<Grid item xs={8}>
							<input className={classes.input} value={title} onChange={e => setTitle(e.target.value)} />
						</Grid>
					</Grid>
					<Grid container spacing={5}>
						<Grid item>
							<Typography className={classes.title} >公告內文</Typography>
						</Grid>
						<Grid item xs={8}>
							<textarea className={classes.textArea} value={content} onChange={e => setContent(e.target.value)} />
						</Grid>
						<Grid item style={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
							<BasicButton color="orange" hoverColor="black" title="確認送出" onClick={rowId ? () => updateNote(title, content, rowId, setTitle, setContent, setRowId, dispatch) : () => createNote(title, content, dispatch)} />
						</Grid>
					</Grid>
				</div>
			</Paper>
		</React.Fragment >
	)
}

export default EditBulletin