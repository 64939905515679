import React from 'react'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import ExportButtonModel from './ExportButtonModel'

function ExportUserDetail(props) {
  const { memberList, memberSetting } = props

  async function onExportExcel() {
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'LINE TAXI Business'
    workbook.lastModifiedBy = 'LINE TAXI Business'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const sheet_p1 = workbook.addWorksheet('member_sheets')

    const fileName = memberSetting ? '群組成員資訊.xlsx' : '所有成員資訊.xlsx'

    const rowTitle = memberSetting
      ? ['群組名稱', '員工姓名', '角色', '信箱', '預算']
      : ['姓名', '手機號碼', '信箱', '狀態']

    const rowContent = item =>
      memberSetting
        ? [
            item.group_name,
            item.name,
            item.role.roleLocalized(),
            item.email,
            item.quota
          ]
        : [
            item.name,
            item.phone_number,
            item.email,
            item.status.userStatusLocalized()
          ]

    sheet_p1.addRow(rowTitle)
    memberList.forEach(item => {
      sheet_p1.addRow(rowContent(item))
    })

    const buf = await workbook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buf]), fileName)
  }

  return (
    <ExportButtonModel
      icon="icon-07_匯出.svg"
      title="匯出"
      type="right"
      onClick={onExportExcel}
    />
  )
}

export default ExportUserDetail
