import React, { useState, useEffect } from 'react'
import { TableBody, Table, TableContainer } from '@material-ui/core'
import PropTypes from 'prop-types'
import CorpTableHead from '../Table/CorpTableHead'

function ReviewSortTable({
  list,
  headRow,
  printRow,
  orderBy,
  orderRule,
  offset,
  loadmore,
  page,
  rowsPerPage,
  checkboxStatus,
  handleSelectAllClick,
  handleGetReviewTicket,
  ...other
}) {
  function handleRequestSort(event, property) {
    if (handleGetReviewTicket) {
      const isDesc = orderBy === property && orderRule === 'desc'
      let newOrderRule = ''
      if (orderBy === property) {
        newOrderRule = orderRule === 'desc' ? 'asc' : 'desc'
      } else {
        newOrderRule = 'desc'
      }
      const sort = {
        orderBy: property,
        orderRule: newOrderRule,
      }
      handleGetReviewTicket(false, false, sort)
    }
  }

  return (
    <TableContainer>
      <Table
        style={{ minWidth: '650' }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <CorpTableHead
          order={orderRule}
          orderBy={orderBy}
          onSelectAllClick={() => {
            if (!handleSelectAllClick) return
            handleSelectAllClick(list)
          }}
          onRequestSort={handleRequestSort}
          headRow={headRow}
          checkBox={checkboxStatus !== undefined}
          checkboxStatus={checkboxStatus}
        />
        <TableBody>{list.map((row, index) => printRow(row, index))}</TableBody>
      </Table>
    </TableContainer>
  )
}

ReviewSortTable.propTypes = {
  list: PropTypes.array.isRequired,
  headRow: PropTypes.array.isRequired,
  printRow: PropTypes.func.isRequired,
}

export default React.memo(ReviewSortTable)
