import React, { useState }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  column: {
    padding: '4px 12px'
  },
});

const RejectTripConfirmModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">確定要將此筆行程轉為員工自付?</DialogTitle>
      <DialogContent>
        <Typography variant="caption" style={{ marginTop: 12 }}>
          <span>提醒您：</span>
          <ul style={{ listStyleType: 'disc', marginLeft: 20, marginTop: 4 }}>
            <li>
              確認送出後，系統將發送行程未付款通知給該行程之乘客
            </li>
            <li>
              若乘客未在行程的次月 4 日 23:00 前完成付款，此筆行程將會併入至本期帳單結算，且無法針對此行程再次操作轉為員工自付。
            </li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: 12 }}>
        <Button variant="contained" onClick={handleClose} disableElevation>
          取消
        </Button>
        <Button style={{ marginLeft: 40 }} variant="contained" onClick={handleConfirm} color="primary" disableElevation>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectTripConfirmModal
