import React, { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import StatusModal from './StatusModal'
import ModalImportButton from '../Buttons/ModalImportButton'
import UploadGroupErrorTable from './UploadGroupErrorTable'
import { uploadScript } from '../../constants'
import { UPLOAD_STEP } from '../../constants/action-types'
import {
  onExportBlankGroupSampleExcel,
  onExportGroupSampleExcel,
} from '../../utils'
import { postBatchGroup } from '../../api'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#e8e8e8',
    padding: '48px',
    borderRadius: '8px',
    width: '880px',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: 1.23,
    color: '#1e201f',
    marginBottom: '24px',
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    '& button:disabled': {
      backgroundColor: '#d2d2d2',
    },
  },
  fileInput: {
    height: '38px',
    width: 'calc(100% - 104px)',
    backgroundColor: 'white',
    borderRadius: '19px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileName: {
    padding: '0 16px',
    lineHeight: '38px',
    border: 'unset',
    borderRadius: '17px',
    backgroundColor: 'white',
    width: '50%',
  },
  selectFile: {
    padding: '0 16px',
    lineHeight: '38px',
    textAlign: 'center',
    borderRadius: '19px',
    backgroundColor: '#4b4d4c',
    color: 'white',
    borderWidth: 0,
    cursor: 'pointer',
  },
  upload: {
    height: '38px',
    lineHeight: '38px',
    textAlign: 'center',
    color: 'white',
    padding: '0 16px',
    borderRadius: '19px',
    backgroundColor: '#00c299',
    borderWidth: 0,
    cursor: 'pointer',
  },
  step: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '48px',
  },
  closeControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  close: {
    padding: '0 14px',
    lineHeight: '34px',
    borderRadius: '8px',
    border: 'solid 1px #4b4d4c',
    fontWeight: 500,
    fontSize: '16px',
    color: '#4b4d4c',
    cursor: 'pointer',
  },
  download: {
    padding: '0 14px',
    lineHeight: '37px',
    borderRadius: '8px',
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: '#4b4d4c',
    color: 'white',
    cursor: 'pointer',
    marginRight: 24,
  },
  uploadDescription: {
    fontSize: '16px',
    marginBottom: '16px',
    '& span': {
      fontWeight: 500,
      color: '#7a92ad',
      cursor: 'pointer',
    },
    '& div': {
      paddingLeft: '16px',
      lineHeight: '28px',
    },
  },
}))

const stepWrap = [
  UPLOAD_STEP.UPLOAD,
  UPLOAD_STEP.CHECKING_DATA,
  UPLOAD_STEP.FINISHED_UPLOAD,
]

export default function UploadGroupModal(props) {
  const { handleOpenModal, open, handleUpdateGroups, state } = props
  const classes = useStyles()
  const [step, setStep] = useState({})
  const [groupFile, setGroupFile] = useState(null)
  const [msgFile, setMsgFile] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [fileName, setFileName] = useState('')
  const fileUploaderRef = useRef()

  useEffect(() => {
    return () => {}
  }, [step])

  function handleStepStatus(itemStep) {
    switch (itemStep) {
      case UPLOAD_STEP.UPLOAD:
        if (step[itemStep]) {
          return uploadScript.upload
        }
        return uploadScript.upload_disabled
      case UPLOAD_STEP.CHECKING_DATA:
        if (step[itemStep]) {
          return uploadScript.checking
        }
        return uploadScript.checking_disabled
      case UPLOAD_STEP.FINISHED_UPLOAD:
        if (step[itemStep]) {
          return uploadScript.finished
        }
        return uploadScript.finished_disabled
      default:
        break
    }
  }

  function updateItemStatus(status, detail) {
    setStep({ ...step, [status]: detail })
  }

  function handleClickUpload() {
    fileUploaderRef.current.click()
  }

  const columnKeys = [
    'id',
    'name',
    'quota',
    'member_default_quota',
    'member_default_trip_quota',
    'member_default_review_notes',
    'action',
  ]

  function handleFinishState(data) {
    if (data.status === 401) return 'auth_error'
    if (data.data.msg || !isEmpty(data.data.failed_data)) return 'error'
    return 'finished'
  }

  async function uploadFile() {
    if (!groupFile) return
    updateItemStatus(UPLOAD_STEP.UPLOAD, 'processing')
    setButtonDisabled(true)
    const data = await postBatchGroup(groupFile)
    console.log(data, 'upload-------')
    handleUpdateGroups()
    const { msg, failed_data } = data.data
    setStep({
      ...step,
      [UPLOAD_STEP.UPLOAD]: 'finished',
      [UPLOAD_STEP.CHECKING_DATA]: 'processing',
    })
    setTimeout(() => {
      setStep((prevStep) => {
        return {
          ...prevStep,
          [UPLOAD_STEP.CHECKING_DATA]: 'finished',
          [UPLOAD_STEP.FINISHED_UPLOAD]: handleFinishState(data),
        }
      })
      if (data.status !== 200) {
        msg && setMsgFile(msg)
        return
      }
      if (!isEmpty(failed_data)) {
        setMsgFile(failed_data)
      }
    }, 5000)
  }

  function handleClose() {
    setStep({})
    setMsgFile(null)
    setGroupFile(null)
    setFileName('')
    setButtonDisabled(false)
    handleOpenModal()
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalTitle}>匯入群組列表</div>
          <div className={classes.uploadDescription}>
            1.{' '}
            <span onClick={() => onExportBlankGroupSampleExcel()}>
              下載群組空白格式.xlsx
            </span>
            （首次匯入群組建議使用）
          </div>
          <div className={classes.uploadDescription}>
            2.{' '}
            <span onClick={() => onExportGroupSampleExcel(state.groups)}>
              下載含群組資料檔案格式.xlsx
            </span>
            <div>a. 已有群組資料建議使用</div>
            <div>b. 可以完成批量處理成員資料，包括新增、變更、移除</div>
          </div>
          <div className={classes.control}>
            <div className={classes.fileInput}>
              <input
                className={classes.fileName}
                placeholder="點擊「選擇檔案」，選擇要上傳的檔案"
                value={fileName}
                disabled
              />
              <ModalImportButton
                type="group"
                columnKeys={columnKeys}
                fileUploaderRef={fileUploaderRef}
                buttonDisabled={buttonDisabled}
                setFileName={setFileName}
                setFile={setGroupFile}
                handleClickUpload={handleClickUpload}
              />
            </div>
            <button
              className={classes.upload}
              onClick={uploadFile}
              disabled={buttonDisabled}
            >
              開始上傳
            </button>
          </div>
          <div className={classes.step}>
            {stepWrap.map((item) => (
              <StatusModal
                key={item}
                step={step}
                itemStatus={item}
                uploadScript={handleStepStatus(item)}
                exportFailedList={() =>
                  onExportGroupSampleExcel(msgFile, '上傳失敗群組資訊')
                }
              />
            ))}
          </div>
          {msgFile && <UploadGroupErrorTable msgFile={msgFile} />}
          <div className={classes.closeControl}>
            {msgFile && typeof msgFile !== 'string' && (
              <div
                className={classes.download}
                onClick={() =>
                  onExportGroupSampleExcel(msgFile, '上傳失敗群組資訊')
                }
              >
                下載檔案
              </div>
            )}
            <div className={classes.close} onClick={handleClose}>
              關閉
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
