import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import { Icon, Button, Typography } from '@material-ui/core'
import { ReactComponent as ExportIcon } from '../../icon/icon-07_匯出.svg'
import localized from '../../model/Localize'
import moment from 'moment'

const useStyles = makeStyles(theme => ({

	right_button: {
		borderRadius: '5px 20px 20px 5px',
		margin: '8px 5px',
		boxShadow: 'none',
		background: 'rgb(89, 89, 89)',
		color: 'white',
	},

	icon: {
		marginRight: '5px'
	},

	uploadText: {
		display: 'inline-block'
	},

}))

function ExportUserTripDetail(props) {

	const classes = useStyles()
	const { meDate } = props

	async function onExportExcel() {
		const workbook = new ExcelJS.Workbook()
		workbook.creator = 'LINE TAXI Business'
		workbook.lastModifiedBy = 'LINE TAXI Business'
		workbook.created = new Date()
		workbook.modified = new Date()
		workbook.lastPrinted = new Date()

		const sheet_p1 = workbook.addWorksheet('member_sheets')

		sheet_p1.addRow([
			'NO.',
			'用車時間',
			'行程代碼',
			'部門',
			'員工編號',
			'姓名',
			'計程車車號',
			'上車時間',
			'上車地點',
			'下車時間',
			'下車地點',
			'司機',
			'總金額',
			'審核狀態',
			'備註'
		])
		// console.log('meDate' + JSON.stringify(meDate)
		let count = 0
		let user_name = ''
		meDate[0].company_trips.sort((a, b) => (a.trip > b.trip) ? 1 : -1).reverse().forEach(item => {
			count++
			user_name = meDate[0].name
			sheet_p1.addRow([
				count,
				moment(item.trips.reserve_time * 1000).format('YYYY/MM/DD HH:mm'),//用車時間
				item.trip,//行程代碼
				meDate[0].department.name,//部門
				meDate[0].id,//員工編號
				meDate[0].name,//員工姓名
				item.trips.driver_info.plate_number,//車牌
				moment(item.trip_start_time * 1000).format('YYYY/MM/DD HH:mm'),//上車時間
				item.trips.start_address,//上車地點
				moment(item.trip_finish_time * 1000).format('YYYY/MM/DD HH:mm'),//下車時間
				item.trips.end_address || '未輸入',
				item.trips.driver_info.last_name + 'ＯＯ',
				item.trips.amount,//總金額
				item.status.tripStatusLocalized(),//審核狀態
				item.review_notes,
			])
		})

		const buf = await workbook.xlsx.writeBuffer()
		FileSaver.saveAs(new Blob([buf]), user_name + '個人行程紀錄.xlsx')
	}

	return (

		<React.Fragment>
			<Button className={classes.right_button} variant="contained" color="default" onClick={onExportExcel}>
				<Icon className={classes.icon} style={{ fontSize: '25px' }}>
					<ExportIcon style={{ height: '25px', width: '25px' }} />
				</Icon>
				<Typography className={classes.uploadText}>匯出</Typography>
			</Button>
		</React.Fragment>
	)
}


export default ExportUserTripDetail
