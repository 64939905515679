import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Link } from '@material-ui/core'

function DetailTableCell(props) {
  const {
    title,
    narrowTitle,
    content,
    textField,
    link,
    notes,
    setNotes,
    trip_link
  } = props

  const useStyle = makeStyles(theme => ({
    root: {
      padding: '12px 0',
      color: '#4b4d4c',
      display: 'flex'
    },
    title: {
      width: '70px',
      marginRight: '40px',
      fontWeight: 400,
      textAlignLast: 'justify'
    },
    narrowTitle: {
      width: '190px',
      marginRight: '40px',
      fontWeight: 400
    },
    content: {
      fontWeight: 600,
      width: 'calc(100% - 120px)'
    },
    textField: {
      width: '192px',
      height: '96px',
      '& label.Mui-focused': {
        color: 'orange'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'orange'
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'orange'
        }
      }
    },
    link: {
      color: '#0080fe',
      fontWeight: 600,
      textDecoration: 'none'
    }
  }))

  const classes = useStyle()

  return (
    <div className={classes.root}>
      {title && <div className={classes.title}>{title}</div>}
      {narrowTitle && <div className={classes.narrowTitle}>{narrowTitle}</div>}
      {content && <div className={classes.content}>{content}</div>}
      {link && (
        <Link href={trip_link} target="_blank" className={classes.link}>
          {link}
        </Link>
      )}
      {textField && (
        <TextField
          id="outlined-multiline-static"
          placeholder="事由"
          multiline
          fullWidth
          rows="3"
          defaultValue={notes}
          value={notes}
          className={classes.textField}
          variant="outlined"
          onChange={setNotes}
        />
      )}
    </div>
  )
}

export default DetailTableCell
