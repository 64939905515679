import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import '../css/register.css'

const useStyles = makeStyles(theme => ({
    back: {},
}))

function RegisterConfirm() {
    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <React.Fragment>
            <div className="register">
                <div className="main-wrap">
                    <section className="wrap text-centered">
                        <div className="center-block">
                            <h2><span className="highlight">請至 Email 收信，完成簽約手續並回傳</span></h2>
                            <h3>感謝您申請企業簽單，我們已寄發企業會員服務條款至您的信箱，<br className="c-to-m"></br>回傳合約後，將於三個工作天內完成審核，並為您開通管理後台。</h3>
                            <p>如需瞭解更多，可以聯絡我們 <a href="mailto:business@linego.me">business@linego.me</a>。</p>
                        </div>
                    </section>
                    <div className="bg-gradient"></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegisterConfirm
