import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Icon, Fab, IconButton } from '@material-ui/core'
import CustomAutocomplete from '../Input/CustomAutocomplete'
import { generalStyle } from '../../styles'

const useStyle = makeStyles(theme => ({
  input: {
    width: '100%',
    padding: '12px 20px',
    margin: '8px 0',
    border: '1px solid #ccc',
    borderRadius: '24px',
    outline: 'none',
    '-webkit-transition': '0.5s',
    transition: '0.5s',
    '&:focus': {
      border: '1px solid #555'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    marginBottom: '20px'
  },
  fab: {
    color: '#f8f8f8',
    background: '#4b4d4c'
  },
  button_block: {
    paddingTop: '3.5px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function GroupMemberForm(props) {
  const { state, createRef, clickCreateButton, handleAddUserInGroup } = props
  const classes = useStyle()
  const generalClasses = generalStyle()

  const [values, setValues] = useState({
    member: { name: '', id: '' },
    group: { id: '', name: '' }
  })

  function handleChange(name, member) {
    if (!member) return
    setValues({ ...values, member })
  }

  return (
    <div className={generalClasses.filter}>
      <form
        className={classes.container}
        ref={createRef}
        style={{ display: 'none' }}
        noValidate
        autoComplete="off"
      >
        <Grid item container spacing={2} xs={9}>
          <Grid
            item
            container
            direction="column"
            justify="space-around"
            spacing={1}
            xs={6}
          >
            <Grid item>
              <CustomAutocomplete
                id="name-autocomplete"
                options={state.all_member}
                getOptionLabel={option => `${option.name} (${option.email})`}
                onChange={(e, v) => handleChange('name', v)}
                renderInput={params => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      id="name"
                      placeholder="姓名"
                      value={values.member.name}
                      {...params.inputProps}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-around"
          spacing={1}
          xs={2}
        >
          <Grid item className={classes.button_block}>
            <Fab
              aria-label="Add"
              size="small"
              className={classes.fab}
              onClick={() => handleAddUserInGroup(values)}
            >
              <Icon>done</Icon>
            </Fab>
            <IconButton
              aria-label="Cancel"
              color="#4b4d4c"
              className={classes.fabCancel}
              onClick={clickCreateButton}
            >
              <Icon>clear</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default GroupMemberForm
