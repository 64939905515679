import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import moment from 'moment'

async function onExportGroupSampleExcel(groups, fileName) {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'LINE TAXI Business'
  workbook.lastModifiedBy = 'LINE TAXI Business'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet_p1 = workbook.addWorksheet('groups_sheets')

  sheet_p1.getColumn(1).width = 10
  sheet_p1.getColumn(2).width = 20
  sheet_p1.getColumn(3).width = 20
  sheet_p1.getColumn(4).width = 20
  sheet_p1.getColumn(5).width = 30
  sheet_p1.getColumn(6).width = 20
  sheet_p1.getColumn(7).width = 20

  sheet_p1.mergeCells('A1:G1')
  sheet_p1.getCell('A1').value = `
		使用說明：\n
1. 有 @ 的欄位，為系統資料，無需編輯。\n
2. 有 * 的欄位，為必填資訊。\n
3. 有 # 的欄位，為下拉選項，請參考範例填寫。\n
4.「#動作*」欄位說明：\n
	A. 新增：將此群組資料新增至企業後台。\n
	B. 變更：群組已存在後台，變更群組資料。\n
	C. 刪除：刪除該群組。\n
	D. 無：無需執行任何動作。\n
	`
  sheet_p1.getCell('A1').alignment = { wrapText: true, vertical: 'top' }
  sheet_p1.getCell('A1').border = {
    bottom: { style: 'thin', color: { argb: 'FF2b2b2b' } },
  }
  sheet_p1.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE8E8E8' },
  }
  sheet_p1.getRow(1).height = 190

  const rowTitle = [
    '@ID',
    '群組名稱*',
    '群組預算/月*',
    '成員預算/月*',
    '單趟上限*（數字/無上限）',
    '#填寫備註*',
    '#動作*',
  ]
  sheet_p1.addRow(rowTitle)

  sheet_p1.getRow(2).alignment = {
    vertical: 'middle',
    horizontal: 'center',
  }

  const rowContent = (data) => {
    const item = data.data || data
    return [
      item.id,
      item.name,
      item.quota,
      item.member_default_quota,
      item.member_default_trip_quota === -1
        ? '無上限'
        : item.member_default_trip_quota,
      Boolean(item.member_default_review_notes) ? '是' : '否',
      item.action ? item.action.actionLocalized('group') : '無',
      data.message || '',
    ]
  }

  groups.forEach((item) => {
    sheet_p1.addRow(rowContent(item))
  })

  sheet_p1.getColumn(6).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"是,否"'],
    }
  })
  sheet_p1.getColumn(7).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"新增,變更,刪除,無"'],
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  const fileTitle = fileName
    ? `${fileName}${moment().format('YYYY_MM_DD')}.xlsx`
    : `群組資料_${moment().format('YYYY_MM_DD')}.xlsx`

  FileSaver.saveAs(new Blob([buf]), fileTitle)
}

export default onExportGroupSampleExcel
