import React, {useState, useEffect} from 'react'
import {postSheetCompany} from "../api";
import '../css/register.css'

function RegisterForm() {
    const [company_name, setCompanyName] = useState('')
    const [user_name, setUserName] = useState('')
    const [telephone_num, setTelephoneNum] = useState('')
    const [email, setEmail] = useState('')
    const [tax_num, setTaxNum] = useState('')
    const [people_size, setPeopleSize] = useState('1-10')
    const [account_name, setAccountName] = useState('')


    async function createCompany() {
        if (!validateForm()) {
            return
        }
        const body = {
            company_name,
            user_name,
            telephone_num,
            email,
            tax_num,
            people_size,
            account_name
        }
        const data = await postSheetCompany(body)
        if (data.status !== 200) return alert(data.data.error.msg)
        window.location.href = data.data.redirect
    }

    function validateForm() {
        let x = document.forms["myForm"]["company-name"].value;
        if (x === "") {
            alert("公司名稱為必填");
            return false;
        }
        x = document.forms["myForm"]["contact"].value;
        if (x === "") {
            alert("聯絡人為必填");
            return false;
        }
        x = document.forms["myForm"]["phone-number"].value;
        if (x === "" || x.size < 4) {
            alert("連絡電話為必填");
            return false;
        }

        x = document.forms["myForm"]["email"].value;
        if (x === "" || !x.includes("@")) {
            alert("電子信箱為必填或格式錯誤");
            return false;
        }

        x = document.forms["myForm"]["tax-id-no"].value;
        if (x === "" || x.size < 4) {
            alert("統一編號為必填或格式錯誤");
            return false;
        }

        x = document.forms["myForm"]["account-name"].value;
        if (x === "") {
            alert("設定公司後台帳號為必填");
            return false;
        }
        x = document.forms["myForm"]["account-name"].value;
        let result = x.match(/([0-9]|[A-Z]|[a-z])/g)
        if (result == null || result.length !== x.length) {
            alert("設定公司後台帳號為 6~12 字元，限大小寫英文字母");
            return false;
        }
		x = document.forms["myForm"]["account-name"].value;
		if (x.length < 6) {
			alert("設定公司後台帳號字數至少需要 6 碼");
			return false;
		}

		x = document.forms["myForm"]["account-name"].value;
		if (x.length > 12) {
			alert("設定公司後台帳號字數至多只能 12 碼");
			return false;
		}
        return true;
    }

    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <React.Fragment>
            <div class="register">
                <div class="main-wrap">
                    <section class="wrap">
                        <h1>Line Taxi <br/> for business</h1>
                        <h3>企業差旅的絕佳幫手</h3>
                        <div className="form-part">
                            <form id="myForm">
                                <ul>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="company-name">
                                                <p className="title">公司名稱</p>
                                            </label>
                                            <input type="text"
                                                   id="company-name"
                                                   name="company-name"
                                                   value={company_name}
                                                   onChange={e => setCompanyName(e.target.value)}
                                                   placeholder=""
                                                   required/>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="contact">
                                                <p className="title">聯絡人</p>
                                            </label>
                                            <input type="text" id="contact" name="contact"
                                                   placeholder=""
                                                   value={user_name}
                                                   onChange={e => setUserName(e.target.value)}
                                                   required/>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="phone-number">
                                                <p className="title">連絡電話</p>
                                            </label>
                                            <input type="tel"
                                                   id="phone-number"
                                                   name="phone-number"
                                                   placeholder=""
                                                   value={telephone_num}
                                                   onChange={e => setTelephoneNum(e.target.value)}
                                                   required/>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="email">
                                                <p className="title">電子信箱</p>
                                            </label>
                                            <input type="email"
                                                   id="email" name="email"
                                                   value={email}
                                                   onChange={e => setEmail(e.target.value)}
                                                   placeholder=""
                                                   required/>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="tax-id-no">
                                                <p className="title">統一編號</p>
                                            </label>
                                            <input type="text" id="tax-id-no"
                                                   name="tax-id-no" pattern="[0-9]{8}"
                                                   value={tax_num}
                                                   onChange={e => setTaxNum(e.target.value)}
                                                   placeholder="" required/>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="company-size">
                                                <p className="title">公司規模</p>
                                            </label>
                                            <select id="company-size"
                                                    name="company-size"
                                                    value={people_size}
                                                    onChange={e => setPeopleSize(e.target.value)}
                                                    required>
                                                <option value="1-10">1-10人</option>
                                                <option value="11-20">11-20人</option>
                                                <option value="21-50">21-50人</option>
                                                <option value="51-80">51-80人</option>
                                                <option value="80-up">80人以上</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li className="form-block">
                                        <div className="for-input">
                                            <label htmlFor="account-name">
                                                <p className="title">設定公司後台帳號</p>
                                            </label>
                                            <input type="text" id="account-name"
                                                   name="account-name"
                                                   value={account_name}
                                                   onChange={e => setAccountName(e.target.value)}
                                                   placeholder="請輸入 6~12 字元，限大小寫英文字母、數字" required/>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_part">
                                    <p>送出即同意 LINE TAXI <a
                                        href="https://linetaxi.com.tw/%e4%bc%81%e6%a5%ad%e7%94%a8%e6%88%b6%e6%9c%8d%e5%8b%99%e5%90%88%e7%b4%84/"
                                        target="_blank">企業用戶服務合約</a>、乘客 <a
                                        href="https://linetaxi.com.tw/privacy-policy/"
                                        target="_blank">隱私權聲明</a>與<a
                                        href="https://linetaxi.com.tw/%e4%bd%bf%e7%94%a8%e8%80%85%e6%a2%9d%e6%ac%be/"
                                        target="_blank">使用者條款</a>。</p>
                                    <button type="button" className="button" onClick={() => createCompany()}>
                                        <p className="title">送出申請</p>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>

            </div>
        </React.Fragment>
    )
}

export default RegisterForm
