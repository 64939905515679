import React, { useState, useContext, useEffect } from 'react'
import { Route, Link, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { CorpContext } from '../../reducers/Main'
import ReceiptSection from './ReceiptSection'
import AmountCenter from '../AmountCenter'

const TabWrapper = styled.div`
  padding: 32px 60px 8px 60px;
  margin-top: 64px;
  display: flex;
`
const TabTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #1e201f;
  margin-right: 40px;
  padding-bottom: 6px;
  cursor: pointer;
  ${(props) => (props.$active ? `border-bottom: 2px solid #0080fe;` : ``)}
`

const TAB_PATH = ['accounting', 'receipt']

const PageAmountCenter = React.memo(() => {
  const { company } = useContext(CorpContext)?.state
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(location.pathname.includes(TAB_PATH[0]) ? TAB_PATH[0] : TAB_PATH[1])
  const { path, url } = useRouteMatch()

  useEffect(() => {
    const currentTab = location.pathname.includes(TAB_PATH[0]) ? TAB_PATH[0] : TAB_PATH[1]
    setActiveTab(currentTab)
  }, [location.pathname])

  return (
    <>
      <TabWrapper>
        <Link to={`${url}/${TAB_PATH[0]}`}>
          <TabTitle $active={activeTab === TAB_PATH[0]}>帳務中心</TabTitle>
        </Link>
        <Link to={`${url}/${TAB_PATH[1]}`}>
          <TabTitle $active={activeTab === TAB_PATH[1]}>收據/發票設定</TabTitle>
        </Link>
      </TabWrapper>
      <Switch>
        <Route
          path={`${path}/:amountId`}
          component={({ match }) => {
            return match.params?.amountId === TAB_PATH[0] ? <AmountCenter /> : <ReceiptSection company={company} />
          }}
        />
      </Switch>
    </>
  )
})

export default PageAmountCenter
