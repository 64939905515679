import React, { useState, useEffect, useContext } from 'react'
import { CorpContext } from '../../reducers/Main'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, Checkbox } from '@material-ui/core'
import { generalStyle } from '../../styles'
import MaxWidthForTableCell from '../Table/MaxWidthForTableCell'
import { withStyles } from '@material-ui/styles'
import { getCompanyPayment } from '../../utils'
import { rejectedStatus } from '../../constants'
import Auth from '../../model/Auth'

const useStyle = makeStyles((theme) => ({
  checkBox: {
    zIndex: 0,
    width: '25px',
    wordWrap: 'break-word',
    '&:hover': {
      background: 'unset',
    },
  },
  selected: {
    backgroundColor: '#f5f5f5 !important',
  },
}))

const CustomCheckBox = withStyles({
  root: {
    color: '#4b4d4c',
    '&$checked': {
      color: '#7a92ad',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

function CheckboxTableCell({
  row,
  index,
  selectedTrips,
  handleUpdateSelectedTrips,
  handleOpenDetail,
}) {
  const classes = useStyle()
  const generalClasses = generalStyle()
  const labelId = `enhanced-table-checkbox-${index}`

  const { state } = useContext(CorpContext)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    handleInitSelected()
  }, [row, selectedTrips])

  const handleClick = (event, name, isItemSelected) => {
    isItemSelected ? handleRowDeselected(name) : handleRowSelected(name)
  }

  const handleInitSelected = () => {
    if (selectedTrips.includes(row.trip_hash)) {
      setSelected(selected.concat(row.trip_hash))
    } else {
      setSelected([])
    }
  }

  const handleRowSelected = (trip_hash) => {
    setSelected(selected.concat(trip_hash))
    handleUpdateSelectedTrips('selected', trip_hash)
  }

  const handleRowDeselected = (trip_hash) => {
    setSelected(selected.filter((hash) => hash !== trip_hash))
    handleUpdateSelectedTrips('deselected', trip_hash)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const isItemSelected = isSelected(row.trip_hash)

  const handleTripTransactions = (row) => {
    return getCompanyPayment(row.transactions || [])
  }

  const rejectedStatusLabel = () => {
    const payments = getCompanyPayment(row.transactions || [])
    const rejected_status = rejectedStatus[row.rejected_status] || '企業'
    const unpaidAmount = row.amount - payments.company - payments.self
    if (unpaidAmount > 0 && rejected_status === '員工') {
      return `員工待支付 ${unpaidAmount}`
    }
    return rejected_status
  }

  return (
    <TableRow
      selected={isItemSelected}
      classes={{ selected: classes.selected }}
      hover
    >
      <TableCell
        className={generalClasses.cell}
        padding="none"
        style={{ minWidth: '36px', borderBottom: 'none' }}
      >
        <CustomCheckBox
          className={classes.checkBox}
          checked={isItemSelected}
          disableRipple
          inputProps={{
            'aria-labelledby': labelId,
          }}
          onClick={(event) => handleClick(event, row.trip_hash, isItemSelected)}
          key={row.trips}
        />
      </TableCell>
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        context={moment(row.reserve_time * 1000).format('YYYY/MM/DD HH:mm')}
        minWidth="175px"
        handleOnClick={handleOpenDetail}
      />
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        context={`${row.group} ${row.group_status}`}
        minWidth="105px"
        handleOnClick={handleOpenDetail}
      />
      {/* <MaxWidthForTableCell trip_hash={row.trip_hash} context={row.id} /> */}
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        context={row.trip_category}
        minWidth="100px"
        maxWidth="150px"
        textCut
        handleOnClick={handleOpenDetail}
      />
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        context={row.name}
        minWidth="100px"
        maxWidth="150px"
        textCut
        handleOnClick={handleOpenDetail}
      />
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        context={row.amount}
        handleOnClick={handleOpenDetail}
      />
      <MaxWidthForTableCell
        minWidth="100px"
        maxWidth="150px"
        trip_hash={row.trip_hash}
        context={handleTripTransactions(row).company}
        handleOnClick={handleOpenDetail}
      />
      <MaxWidthForTableCell
        minWidth="100px"
        maxWidth="150px"
        trip_hash={row.trip_hash}
        context={handleTripTransactions(row).self}
        handleOnClick={handleOpenDetail}
      />
      <TableCell
        className={generalClasses.cell}
        padding="none"
        component={Link}
        to={`/public/tripdetail/${row.trip_hash}`}
        onClick={handleOpenDetail}
      >
        {row.receipt_type}
      </TableCell>
      <MaxWidthForTableCell
        trip_hash={row.trip_hash}
        custom={(row.custom_field && row.custom_field[0].content) || '無'}
        subContext={
          row.custom_field && row.custom_field.length > 1 && '...更多'
        }
        maxWidth="150px"
        textCut
        customCut
        handleOnClick={handleOpenDetail}
      />
      {state.config.enable_company_trips_rejection ? (
        <TableCell
          className={generalClasses.cell}
          padding="none"
          component={Link}
          style={{ fontSize: row.rejected_status === 'EXPIRED' ? '.85em' : '1em' }}
          to={`/public/tripdetail/${row.trip_hash}`}
          onClick={handleOpenDetail}
        >
          {rejectedStatusLabel()}
        </TableCell>
      ) : (
        <TableCell
          className={generalClasses.cell}
          padding="none"
          style={{
            color: row.company_trips_status.tripStatusColor(),
            minWidth: '80px',
            borderBottom: 'none',
          }}
          component={Link}
          to={`/public/tripdetail/${row.trip_hash}`}
          onClick={handleOpenDetail}
        >
          {row.company_trips_status.tripStatusLocalized()}
        </TableCell>
      )}
    </TableRow>
  )
}

export default React.memo(CheckboxTableCell)
