import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import 'react-dates/initialize'
import '../../css/react_dates_overrides.css'

import Icon from '@material-ui/core/Icon'
import { DateRangePicker } from 'react-dates'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    color: '#4b4d4c',
  },
  inputIcon: {
    fontWeight: 400,
    fontSize: '12.5px',
    color: '#4b4d4c',
    padding: 'unset',
    margin: 'unset',
  },
}))
function DateRangeButton(props) {
  const {
    onSearch,
    start_date,
    end_date,
    startPlaceholder = '開始日期',
    endPlaceholder = '結束日期',
    customInputIcon = false,
  } = props
  const classes = useStyles()
  const [startDate, setStartDate] = useState(start_date)
  const [endDate, setEndDate] = useState(end_date)
  const [focusedInput, setfocusedInput] = useState(null)

  useEffect(() => {
    if (start_date === null && end_date === null) {
      onSearch(null, null)
      setStartDate(null)
      setEndDate(null)
    }
    return () => {}
  }, [start_date, end_date])

  return (
    <React.Fragment>
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        weekDayFormat="dd"
        monthFormat="YYYY[年]MM[月]"
        startDatePlaceholderText={startPlaceholder}
        endDatePlaceholderText={endPlaceholder}
        displayFormat="YYYY/MM/DD"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
          if (
            startDate === null ||
            startDate !== undefined ||
            endDate === null ||
            endDate === undefined
          ) {
            onSearch(null, null)
          }
          if (startDate !== null && startDate !== undefined) {
            onSearch(moment(startDate).startOf('day').unix(), null)
          }
          if (endDate !== null && endDate !== undefined) {
            onSearch(null, moment(endDate).endOf('day').unix())
          }
          if (
            startDate !== null &&
            startDate !== undefined &&
            endDate !== null &&
            endDate !== undefined
          ) {
            onSearch(
              moment(startDate).startOf('day').unix(),
              moment(endDate).endOf('day').unix()
            )
          }
        }}
        focusedInput={focusedInput}
        onFocusChange={(m) => setfocusedInput(m)}
        showClearDates
        small="true"
        isOutsideRange={() => false}
        customCloseIcon={
          <Icon className={classes.icon} fontSize="small">
            clear
          </Icon>
        }
        autoFocus
      />
    </React.Fragment>
  )
}

DateRangeButton.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
}

export default DateRangeButton
