import React, { useState, useEffect } from 'react'
import { TableBody, Table, TableContainer } from '@material-ui/core'
import PropTypes from 'prop-types'
import CorpTableHead from '../Table/CorpTableHead'

function MSortTable({
  list,
  headRow,
  printRow,
  pOrderBy,
  defaultOrder,
  offset,
  loadmore,
  page,
  rowsPerPage,
  checkboxStatus,
  handleSelectAllClick,
  ...other
}) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  useEffect(() => {
    setOrder(defaultOrder || 'asc')
    setOrderBy(pOrderBy || 'name')
  }, [pOrderBy, defaultOrder])

  //Sort start ------------
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }
  // function desc(a, b, orderBy) {
  //   const a_value = parseInt(a[orderBy], 10)
  //     ? parseInt(a[orderBy], 10)
  //     : a[orderBy]
  //   const b_value = parseInt(b[orderBy], 10)
  //     ? parseInt(b[orderBy], 10)
  //     : b[orderBy]

  //   if (b_value < a_value) {
  //     return -1
  //   }
  //   if (b_value > a_value) {
  //     return 1
  //   }
  //   return 0
  // }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getSorting(order, orderBy) {
    return order === 'asc'
      ? (a, b) => newSort(a, b, orderBy)
      : (a, b) => -newSort(a, b, orderBy)
    // return order === 'desc'
    //   ? (a, b) => desc(a, b, orderBy)
    //   : (a, b) => -desc(a, b, orderBy)
  }

  function newSort(a, b) {
    let reg = /[a-zA-Z0-9]/
    if (reg.test(a[orderBy]) || reg.test(b[orderBy])) {
      if (a[orderBy] > b[orderBy]) {
        return 1
      } else if (a[orderBy] < b[orderBy]) {
        return -1
      } else {
        return 0
      }
    } else {
      return a[orderBy].length !== 0 && a[orderBy].localeCompare(b[orderBy])
    }
  }

  return (
    <TableContainer>
      <Table
        style={{ minWidth: '650' }}
        aria-labelledby="tableTitle"
        size="small"
      >
        <CorpTableHead
          order={order}
          orderBy={orderBy}
          onSelectAllClick={() => {
            if (!handleSelectAllClick) return
            const listInRange = stableSort(
              list,
              getSorting(order, orderBy)
            ).filter(
              (item, index) =>
                index < page * rowsPerPage + rowsPerPage &&
                index >= page * rowsPerPage
            )
            handleSelectAllClick(listInRange)
          }}
          onRequestSort={handleRequestSort}
          headRow={headRow}
          checkBox={checkboxStatus !== undefined}
          checkboxStatus={checkboxStatus}
        />
        <TableBody>
          {stableSort(list, getSorting(order, orderBy)).map((row, index) => {
            if (loadmore) {
              return (
                index < page * rowsPerPage + rowsPerPage &&
                index >= page * rowsPerPage &&
                printRow(row, index)
              )
            }
            return printRow(row, index)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MSortTable.propTypes = {
  list: PropTypes.array.isRequired,
  headRow: PropTypes.array.isRequired,
  printRow: PropTypes.func.isRequired,
}

export default React.memo(MSortTable)
