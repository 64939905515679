import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

async function onExportBlankGroupSampleExcel() {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'LINE TAXI Business'
  workbook.lastModifiedBy = 'LINE TAXI Business'
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()

  const sheet_p1 = workbook.addWorksheet('blank_groups_sheets')

  sheet_p1.getColumn(1).width = 40
  sheet_p1.getColumn(2).width = 20
  sheet_p1.getColumn(3).width = 20
  sheet_p1.getColumn(4).width = 25
  sheet_p1.getColumn(5).width = 20
  sheet_p1.getColumn(6).width = 20

  sheet_p1.mergeCells('A1:F1')
  sheet_p1.getCell('A1').value = `
	使用說明：\n
1. 有 * 的欄位，為必填資訊。\n
2. 有 # 的欄位，為下拉選項，請參考範例填寫。\n
3.「#動作*」欄位說明：\n
	A. 新增：將此群組資料新增至企業後台（空白格式僅能用於新增，如需變更、刪除群組請下載含群組資料檔案格式.xlsx）。\n
	`
  sheet_p1.getCell('A1').alignment = { wrapText: true, vertical: 'top' }
  sheet_p1.getCell('A1').border = {
    bottom: { style: 'thin', color: { argb: 'FF2b2b2b' } },
  }
  sheet_p1.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE8E8E8' },
  }
  sheet_p1.getRow(1).height = 130

  const rowTitle = [
    '群組名稱*',
    '群組預算/月*',
    '成員預算/月*',
    '單趟上限*（數字/無上限）',
    '#填寫備註*',
    '#動作*',
  ]
  sheet_p1.addRow(rowTitle)

  sheet_p1.getRow(2).alignment = {
    vertical: 'middle',
    horizontal: 'center',
  }

  sheet_p1.addRow(['範例：行銷部', '100000', '5000', '200', '是', '新增'])
  sheet_p1.addRow(['範例：Product Team', '20000', '2000', '200', '否', '新增'])
  sheet_p1.addRow(['範例：CS 客服部', '10000', '3500', '無上限', '是', '新增'])
  sheet_p1.addRow(['範例：營運_OPS', '40000', '40000', '無上限', '否', '新增'])

  sheet_p1.getColumn(5).eachCell({ includeEmpty: true }, (cell, index) => {
    if (index < 3) return
    cell.dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"是,否"'],
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  const fileTitle = '群組空白格式.xlsx'

  FileSaver.saveAs(new Blob([buf]), fileTitle)
}

export default onExportBlankGroupSampleExcel
