import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    borderRadius: 15,
    height: 30,
    padding: '20px',
    margin: 8
  },
  fab: {
    background: '#4b4d4c',
    '&:hover': {
      background: '#4b4d4c'
    },
    color: 'white',
    boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, 0.5)',
    fontWeight: 400,
    height: '24px',
    alignItems: 'center',
    padding: '2px 18px'
  },
  icon: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(1),
    color: 'white'
  }
}))

function ActionsButton(props) {
  const {
    memberSetting,
    status,
    type,
    implementActions,
    row,

    ...other
  } = props
  const classes = useStyles(props)

  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef(null)

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function handleClose(event, name) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (event.target.id !== '') {
      implementActions(event.target.id, row)
    }
    setOpen(false)
  }

  function handleStatus() {
    const options = []
    let actions = []
    const permission = row.permission.includes('ADMIN')
      ? '取消管理員身份'
      : '設為管理員'

    switch (status) {
      case 'PENDING':
        actions = ['發送驗證信', '編輯', '封鎖', permission]
        break
      case 'ACCEPTED':
        actions = ['編輯', '封鎖', permission]
        break
      case 'BLOCKED':
        actions = ['解除封鎖']
        break
      default:
        break
    }

    options.push(
      actions.map(option => (
        <MenuItem id={option} onClick={e => handleClose(e)}>
          {option}
        </MenuItem>
      ))
    )
    return options
  }

  function handleSettingActions() {
    const options = []
    let actions = ['編輯', '移出群組']

    options.push(
      actions.map(option => (
        <MenuItem id={option} onClick={e => handleClose(e)}>
          {option}
        </MenuItem>
      ))
    )
    return options
  }

  return (
    <React.Fragment>
      <Fab
        variant="extended"
        className={classes.fab}
        {...other}
        aria-label="More"
        onClick={handleToggle}
        aria-owns={open ? 'options-menu' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
      >
        操作
      </Fab>
      <Menu
        id="options-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
      >
        {memberSetting ? handleSettingActions() : handleStatus()}
      </Menu>
    </React.Fragment>
  )
}

ActionsButton.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.string.isRequired
}

export default ActionsButton
