import React, { useReducer } from 'react'

const initialState = {
  regiseter: false,
  me: [],
  member: [],
  all_member: [],
  trips_history: [],
  departments: [],
  block_list: [],
  bulletin: {
    corp: [],
    company: [],
    system: [],
  },
  company: [],
  summary: [],
  groups_summary: [],
  alert_bar: { open: false, text: '', type: '' },
  custom_field: [],
  isLogout: false,
  trip_detail: {},
}

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ME':
      return { ...state, me: action.me }
    case 'UPDATE_MEMBER':
      return { ...state, member: action.member }
    case 'UPDATE_ALL_MEMBER':
      return { ...state, all_member: action.all_member }
    case 'UPDATE_TRIP_HISTORY':
      return { ...state, trips_history: action.trips_history }
    case 'UPDATE_GROUPS':
      return { ...state, groups: action.groups }
    case 'UPDATE_BLOCK_LIST':
      return { ...state, block_list: action.block_list }
    case 'UPDATE_BULLETIN':
      return { ...state, bulletin: action.bulletin }
    case 'UPDATE_SUMMARY':
      return { ...state, summary: action.summary }
    case 'UPDATE_COMPANY':
      return { ...state, company: action.company }
    case 'UPDATE_GROUPS_SUMMARY':
      return { ...state, groups_summary: action.groups_summary }
    case 'UPDATE_URL_HAS_TOKEN':
      return { ...state, regiseter: true }
    case 'TOGGLE_ALERT_BAR':
      return { ...state, alert_bar: action.alert_bar }
    case 'UPDATE_CUSTOM_FIELD':
      return { ...state, custom_field: action.custom_field }
    case 'UPDATE_LOGOUT':
      return { ...state, isLogout: action.isLogout }
    case 'UPDATE_TRIP_DETAIL':
      return { ...state, trip_detail: action.trip_detail }
    case 'UPDATE_CONFIG':
      return { ...state, config: action.config }
    default:
      return { ...state }
  }
}

const CorpContext = React.createContext(initialState)

function CorpProvider(props) {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  return (
    <CorpContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CorpContext.Provider>
  )
}

export { CorpContext, CorpProvider }
