import React from 'react'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash'

const Root = styled.div`
  min-width: 400px;
  max-height: 320px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(30, 32, 31, 0.3), 0 4px 12px 0 rgba(30, 32, 31, 0.3);
  padding: 16px;
  position: absolute;
  top: ${(props) => props.$offset + 12 + 'px'};
  overflow: scroll;
  left: 0;
`
const Member = styled.div`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #4b4d4c;
  ${(props) =>
    !props.$none &&
    `
    cursor: pointer;
      &:hover,
      &:active {
        background-color: #f2f4f7;
      }
  `}
`

const SearchMember = React.memo(({ setInputOnFocus, addMember, setText, inputContentRef, members }) => {
  const handleOnClick = (item) => {
    setInputOnFocus(false)
    addMember(item)
    setText('')
  }

  const inputHight = inputContentRef.current?.offsetHeight

  return (
    <Root $offset={inputHight}>
      {isEmpty(members) && <Member $none>無搜尋結果</Member>}
      {members.map((item) => (
        <Member key={item.id} id="input-wrapper" onClick={() => handleOnClick(item)}>{`${item.name}(${item.email})`}</Member>
      ))}
    </Root>
  )
})

export default SearchMember
