import React, { useEffect, useState, useContext } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { getGeneralInfo, getPersonalInfo, getCustomField, getConfig } from '../api'
import { CorpContext } from '../reducers/Main'
import CssBaseline from '@material-ui/core/CssBaseline'
import Main from '../components/Main'
import Profile from '../components/Profile'
import Trips from '../components/Trips'
import ReviewList from '../components/ReviewList'
import Member from '../components/Member'
import MemberSetting from '../components/MemberSetting'
import GroupList from '../components/GroupList'
import BlockList from '../components/BlockList'
import Overview from '../components/Overview'
import Setting from '../components/Setting'
import Statistics from '../components/Statistics'
import AmountCenter from '../components/PageAmountCenter'
import Login from '../components/Login'
import TripDetail from '../components/TripDetail'
import EditProfile from '../components/EditProfile'
import EditBulletin from '../components/EditBulletin'
import BulletinDetail from '../components/BulletinDetail'
import ContactCenter from '../components/ContactCenter'
import TripNoteSetting from '../components/TripNoteSetting'
import RegisterForm from '../components/RegisterForm'
import RegisterConfirm from '../components/RegisterConfirm'
import RegisterSend from '../components/RegisterSend'

async function fetchConfig(dispatch) {
  const { data, status } = await getConfig()
  if (status !== 200) return
  dispatch({ type: 'UPDATE_CONFIG', config: { enable_company_trips_rejection: data.enable_company_trips_rejection === 'true' } })
}

async function getData(dispatch, setIsLogin = () => {}, setLoading = () => {}) {
  const data = await getGeneralInfo()
  console.log('general data', data)
  if (data.status !== 200 || data == undefined || data.data == undefined) {
  } else {
    const { users, all_users, summary, company, groups } = data.data.data
    dispatch({ type: 'UPDATE_MEMBER', member: users })
    dispatch({ type: 'UPDATE_ALL_MEMBER', all_member: all_users })
    dispatch({ type: 'UPDATE_SUMMARY', summary: summary })
    dispatch({ type: 'UPDATE_COMPANY', company: company })
    dispatch({
      type: 'UPDATE_GROUPS_SUMMARY',
      groups_summary: groups,
    })
  }

  // setLoading(false)
}

async function getRestData(
  dispatch,
  setIsLogin = () => {},
  setLoading = () => {}
) {
  const data = await getPersonalInfo()
  console.log('personal data', data)
  if (data.status !== 200 || data == undefined || data.data == undefined) {
    setIsLogin(false)
  } else {
    const { users, trips, groups, bulletin, company } = data.data.data
    setIsLogin(true)
    dispatch({ type: 'UPDATE_ME', me: users })
    dispatch({
      type: 'UPDATE_GROUPS',
      groups: groups,
    })
    dispatch({
      type: 'UPDATE_TRIP_HISTORY',
      trips_history: trips,
    })
    dispatch({ type: 'UPDATE_BULLETIN', bulletin: bulletin })
    dispatch({ type: 'UPDATE_COMPANY', company: company })
    handleGetCustomField(dispatch, company)
  }

  setLoading(false)
}

async function handleGetCustomField(dispatch, company) {
  const data = await getCustomField(company[0].id)
  if (data.status === 200) {
    dispatch({ type: 'UPDATE_CUSTOM_FIELD', custom_field: data.data })
  }
}

export { getData, getRestData, handleGetCustomField, fetchConfig }

export default () => {
  const [loading, setLoading] = useState(true)
  const [isLogin, setIsLogin] = useState(null)
  const { dispatch } = useContext(CorpContext)
  const query = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (query.get('token')) {
      document.cookie =
        `token=${query.get('token')};domain=` +
        (process.env.REACT_APP_API_URI.includes('localhost')
          ? 'localhost'
          : '.taxigo.com.tw')
      dispatch({ type: 'UPDATE_URL_HAS_TOKEN', regiseter: true })
    }
    getData(dispatch, setIsLogin, setLoading)
    getRestData(dispatch, setIsLogin, setLoading)
    fetchConfig(dispatch)
    return () => {}
  }, [])

  function renderRoute() {
    if (loading === true) {
      return (
        <Route
          path="/"
          render={() => (
            <React.Fragment>
              <CssBaseline />
              <div
                className="loading"
                style={{
                  width: '100vw',
                  height: '100vh',
                  zIndex: 1000,
                  top: 0,
                  position: 'fixed',
                  background: 'rgb(237, 237, 237)',
                }}
              ></div>
            </React.Fragment>
          )}
        />
      )
    } else {
      if (isLogin) {
        return (
          <Main>
            <Route
              path="/"
              render={() => (
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() =>
                      query.get('token') ? (
                        <Redirect to="/profile/editprofile" />
                      ) : (
                        <Overview />
                      )
                    }
                  />
                  <Route exact path="/profile" component={Profile} />
                  <Route path="/trips" component={Trips} />
                  <Route path="/reviewlist" component={ReviewList} />
                  <Route path="/member" component={Member} />
                  <Route path="/membersetting" component={MemberSetting} />
                  <Route path="/groupcenter" component={GroupList} />
                  <Route path="/statistics" component={Statistics} />
                  <Route path="/blocklist" component={BlockList} />
                  <Route path="/setting" component={Setting} />
                  <Route path="/tripNoteSetting" component={TripNoteSetting} />
                  <Route path="/profile/editprofile" component={EditProfile} />
                  <Route path="/editbulletin" component={EditBulletin} />
                  <Route path="/amountcenter" component={AmountCenter} />
                  <Route path="/contectCenter" component={ContactCenter} />
                  <Route
                    path="/:field/tripdetail/:trip_hash/:group"
                    component={TripDetail}
                  />
                  <Route
                    path="/:field/tripdetail/:trip_hash"
                    component={TripDetail}
                  />
                  <Route
                    path="/bulletinDetail/:field/:id"
                    component={BulletinDetail}
                  />
                  <Route path="*" render={() => <Redirect to="/" />} />
                </Switch>
              )}
            />
          </Main>
        )
      } else {
        return (
          <Switch>
            <Route path="/register_form" component={RegisterForm} />
            <Route path="/register_confirmed" component={RegisterConfirm} />
            <Route path="/register_send" component={RegisterSend} />
            <Route path="/login" component={Login} />
            <Route path="*" render={() => <Redirect to="/login" />} />
          </Switch>
        )
      }
    }
  }

  return <BrowserRouter>{renderRoute()}</BrowserRouter>
}
